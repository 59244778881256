import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useMobileMenuStyles = createUseStyles(
  ({ palette, spacing, type }: Theme) => ({
    MobileMenuAnon: {
      '& .sm-mobile-menu-anon__anchor': {
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },

      '& .sm-mobile-menu-anon__link': {
        textDecoration: 'none',
        outline: 0,
        color: palette.text.dark,
      },

      '& .sm-mobile-menu-anon__cta': {
        width: `calc(100% - ${spacing[8]}px)`,
        margin: [spacing[5], spacing[6]],
        outline: 0,
      },
    },
  })
);

export default useMobileMenuStyles;
