import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SPARouteMetricsProvider } from '@sm/metrics';
import { useLocation } from 'react-router-dom';

import { StaticContext } from '../..';

const SPAPageContent = ({
  children,
  pageId,
  pageMetricsAttributes,
  legacyWeb,
}) => {
  const { pathname } = useLocation();
  const { user, environment, GDPR } = useContext(StaticContext);

  const { countryCode: country } = environment;

  return (
    <SPARouteMetricsProvider
      pageId={pageId}
      legacyWeb={legacyWeb}
      pathname={pathname}
      attributes={pageMetricsAttributes}
      GDPR={GDPR}
      user={user}
      country={country}
    >
      {children}
    </SPARouteMetricsProvider>
  );
};

SPAPageContent.propTypes = {
  legacyWeb: PropTypes.string,
  pageId: PropTypes.string.isRequired,
  pageMetricsAttributes: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
};

SPAPageContent.defaultProps = {
  legacyWeb: undefined,
  pageMetricsAttributes: null,
};

export default SPAPageContent;
