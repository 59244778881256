import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, t } from '@sm/intl';
import { Box } from '@wds/box';
import { Toast } from '@wds/toast';
import useRecaptcha from './hooks/useRecaptcha.js';
import { StaticContext } from '../..';
import { WebAssetsThemeWrapper } from '../../theme';

const ERRORS = defineMessages({
  RECAPTCHA: {
    id: 'Recaptcha.ErrorWithRecaptcha',
    defaultMessage:
      'Sorry, there was an error while getting the reCAPTCHA token.',
    description:
      '[Type: Button][Vis: High] - Error on getting reCAPTCHA token from Google',
  },
});

const Recaptcha = ({
  greToken,
  showError,
  setShowError,
  siteKey,
  countryCode,
}) => {
  const {
    environment: { countryCode: envCountryCode },
    recaptchaV3,
  } = useContext(StaticContext);

  useEffect(() => {
    const script = document.createElement('script');

    const country = countryCode || envCountryCode;
    const url = country === 'CN' ? recaptchaV3.urlChina : recaptchaV3.url;

    script.src = `${url}?render=${siteKey || recaptchaV3.siteKey}`;
    script.async = true;
    script.setAttribute('data-testid', 'Recaptcha_tag');

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [envCountryCode, recaptchaV3, countryCode, siteKey]);

  // wrapper with zIndex is required to make sure the recaptcha is on top of the footer
  return (
    <WebAssetsThemeWrapper>
      <Box style={{ zIndex: 1000 }}>
        <input type="hidden" name="gre_token" value={greToken} />
        <Toast
          show={showError}
          autoClose
          dismissible
          onClose={() => setShowError(false)}
          color="warning"
        >
          <Toast.Title>{t(ERRORS.RECAPTCHA)}</Toast.Title>
        </Toast>
      </Box>
    </WebAssetsThemeWrapper>
  );
};

Recaptcha.propTypes = {
  greToken: PropTypes.string.isRequired,
  showError: PropTypes.bool.isRequired,
  setShowError: PropTypes.func.isRequired,
  siteKey: PropTypes.string,
  countryCode: PropTypes.string,
};

Recaptcha.defaultProps = { siteKey: null, countryCode: null };

export { Recaptcha, useRecaptcha };
