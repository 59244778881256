import React, { ComponentType, ReactNode, useContext } from 'react';
import { VariantKey } from '@sm/header';
import { ErrorControllerProps } from '../ErrorController';

import {
  SMFooter,
  SMHeader,
  MobileBanner,
  SessionTimeoutModal,
  CookieBanner,
  StaticContext,
  ErrorController,
} from '../..';

export type BasePageBodyProps<TErrorCtrlData = unknown> = {
  color?: string;
  includeHeader?: boolean;
  includeFooter?: boolean;
  includeMobileInstallBanner?: boolean;
  errorCtrl?: ErrorControllerProps<TErrorCtrlData>;
  showBillingAddress?: boolean;
  headerVariant?: 'default' | 'limited' | 'slim';
  footerVariant?: 'default' | 'limited' | 'slim' | 'center';
  actionFlow?: string;
  showCreateSurveyButton?: boolean;
  sticky?: boolean;
  isMRX?: boolean;
  excludeMenuItems?: string[];
  showLoginButton?: boolean;
  showSignupButton?: boolean;
  displayLICTA?: boolean;
  showDrawer?: boolean;
  Logo?: ComponentType;
  children: ReactNode;
  wrenchVariant?: VariantKey;
  makeFooterLinksStatic?: boolean;
};

const BasePageBody = ({
  color = 'primary',
  headerVariant = 'default',
  footerVariant = 'default',
  errorCtrl,
  showBillingAddress = false,
  includeHeader = true,
  includeFooter = true,
  includeMobileInstallBanner = true,
  children,
  actionFlow = 'header',
  showCreateSurveyButton = true,
  sticky = true,
  isMRX = false,
  excludeMenuItems = [],
  showLoginButton = true,
  showSignupButton = true,
  displayLICTA = true,
  showDrawer = true,
  Logo,
  wrenchVariant,
  makeFooterLinksStatic = false,
}: BasePageBodyProps) => {
  const {
    user,
    url,
    environment,
    locale,
    'client-config': clientConfig,
    GDPR,
  } = useContext(StaticContext);

  const {
    languageCode: language,
    countryCode: country,
    subdomain,
    domain,
    tld,
  } = environment;

  return (
    <>
      {includeHeader ? (
        <SMHeader
          isAnonHeaderSticky={sticky}
          isUserAuthenticated={user.isAuthenticated}
          color={color}
          variant={headerVariant}
          lang={language}
          isEUDC={locale.isEUDC}
          actionFlow={actionFlow}
          user={user}
          showCreateSurveyButton={showCreateSurveyButton}
          isMRX={isMRX}
          excludeMenuItems={excludeMenuItems}
          showLoginButton={showLoginButton}
          showSignupButton={showSignupButton}
          displayLICTA={displayLICTA}
          showDrawer={showDrawer}
          Logo={Logo}
          wrenchVariant={wrenchVariant}
        />
      ) : null}
      {errorCtrl ? (
        <ErrorController {...errorCtrl}>{children}</ErrorController>
      ) : (
        <>{children}</>
      )}
      {includeFooter ? (
        <SMFooter
          subdomain={subdomain}
          domain={domain}
          lang={language}
          country={country}
          isUserAuthenticated={user.isAuthenticated}
          showBillingAddress={showBillingAddress}
          variant={footerVariant}
          isEUDC={locale.isEUDC}
          tld={tld}
          url={url}
          onetrustBannerEnabled={GDPR.onetrustBannerEnabled}
          fidesBannerEnabled={GDPR.fidesBannerEnabled}
          makeFooterLinksStatic={makeFooterLinksStatic}
        />
      ) : null}
      {user.isAuthenticated ? (
        <>
          <SessionTimeoutModal user={user} />
          {includeMobileInstallBanner && (
            <MobileBanner
              downloadLink={clientConfig.mobileBannerDownloadLink}
              deepLinkData={undefined}
            />
          )}
        </>
      ) : null}
      {!GDPR.onetrustBannerEnabled && !GDPR.fidesBannerEnabled ? (
        <CookieBanner GDPR={GDPR} />
      ) : null}
    </>
  );
};

export default BasePageBody;
