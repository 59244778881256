import React from 'react';
import classNames from 'classnames';
import { Typography } from '@wds/typography';
import { Box } from '@wds/box';
import { withWebAssetsTheme } from '../../theme';
import useStyles from './useStyles';

export type ClientErrorProps = {
  openingMessage: string;
  errorTitle: string;
  titularElementTag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'div';
  fullscreen?: boolean;
  children?: React.ReactNode;
  className?: string;
  'data-testid'?: string;
};

// Exported as a named-component for use without ThemeProvider
export const ClientError = ({
  openingMessage,
  errorTitle,
  titularElementTag = 'p',
  fullscreen = true,
  children,
  className,
  'data-testid': dataTestId = 'ClientError',
}: ClientErrorProps) => {
  const { wrapper, innerWrapper, title, outerBox, content } = useStyles({
    fullscreen,
  });
  return (
    <Box className={wrapper}>
      <div
        className={classNames(innerWrapper, className)}
        data-testid={dataTestId}
      >
        <Typography variant="pageTitle" align="center">
          {openingMessage}
        </Typography>
        <Box p={6} mt={6} className={outerBox}>
          <Typography
            component={titularElementTag}
            variant="sectionTitle"
            align="center"
          >
            <span className={title}>{errorTitle}</span>
          </Typography>
          <Typography
            component="div"
            variant="body"
            align="center"
            color="darkMuted"
          >
            <div className={content}>{children}</div>
          </Typography>
        </Box>
      </div>
    </Box>
  );
};

export default withWebAssetsTheme(ClientError);
