import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import SMHeaderContext from '../../../SMHeaderContext';
import { NavBarAnonContext } from '../NavBarAnonContext';

import useSubMenuStyles from './Submenu/useSubMenuStyles';

export const SubMenu = ({ submenuKey, title, children }) => {
  const { lang } = useContext(SMHeaderContext);
  const { submenuOpened } = useContext(NavBarAnonContext);

  const { SubMenuContainer, SubMenuTitle } = useSubMenuStyles();

  const submenuClassName = `${SubMenuContainer} ${submenuKey.toLowerCase()} lang-${lang} ${
    lang !== 'en' ? 'locale' : ''
  } ${submenuOpened === submenuKey ? 'submenu-open' : ''}`;

  return (
    <div className={submenuClassName}>
      {lang !== 'en' && (
        <Box className={SubMenuTitle}>
          <Typography align="center" weight="medium" variant="sectionTitle">
            {title}
          </Typography>
        </Box>
      )}
      {children}
    </div>
  );
};

export default SubMenu;

SubMenu.propTypes = {
  title: PropTypes.string.isRequired,
  submenuKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
