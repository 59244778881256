import { createUseStyles } from 'react-jss';
import type { Theme } from '@wds/styles';

export type StyleProps = {
  fullscreen: boolean;
};

const useStyles = createUseStyles(
  ({ palette, border, radius, breakpoints }: Theme) => ({
    wrapper: ({ fullscreen }: StyleProps) =>
      !fullscreen
        ? {}
        : {
            display: 'flex',
            justifyContent: 'center',
            padding: '150px 0',
          },
    innerWrapper: {
      maxWidth: breakpoints.sm,
    },
    title: {
      '& *': {
        lineHeight: '1.3',
      },
    },
    outerBox: {
      // converting border to string as workaround to JSS bug (unable to pass in styles while using a border array)
      border: `${border.main[0]}px ${border.main[1]} ${border.main[2]}`,
      borderRadius: radius.small,
      backgroundColor: palette.background.light,
    },
    content: {
      '& p': {
        lineHeight: '1.4',
        margin: 0,
      },
    },
  })
);

export default useStyles;
