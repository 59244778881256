import { createMemoStyles, Theme } from '@wds/styles';

const MASTHEAD_HEIGHT = '37px';

const useStyles = createMemoStyles(
  ({ breakpoints, spacing, palette }: Theme) => ({
    captiveWindowPage: {
      alignItems: 'center',
      backgroundColor: palette.background,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      minHeight: '100vh',
    },
    captiveWindowSection: {
      [`@media (min-width: ${breakpoints.sm}px)`]: {
        marginTop: 'auto',
      },
    },
    smLogoHeader: {
      display: 'flex',
      marginTop: spacing[3],
      paddingTop: spacing[4],
      paddingBottom: spacing[6] + spacing[4],
      height: MASTHEAD_HEIGHT,
      [`@media (max-width: ${breakpoints.sm}px)`]: {
        justifyContent: 'center',
      },
    },
    smLogoHeaderCenter: {
      justifyContent: 'center',
    },
    smLogoLogo: {
      height: MASTHEAD_HEIGHT,
    },
  })
);

export default useStyles;
