import React from 'react';

const BBBLogo = () => (
  <svg
    className="sm-truste-seals__svg"
    version="1.1"
    id="Layer_1"
    x="0px"
    y="0px"
    width="123px"
    height="47px"
    viewBox="0 0 439 170"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      stroke="#0E7C98"
      strokeWidth="3"
      strokeMiterlimit="0"
      d="M430.5,134.429c0,11.09-8.99,20.08-20.08,20.08H29.474c-11.09,0-20.08-8.99-20.08-20.08V29.572c0-11.09,8.99-20.08,20.08-20.08
	H410.42c11.09,0,20.08,8.99,20.08,20.08V134.429z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#FFFFFF"
      d="M157,9.492H29.474c-11.09,0-20.08,8.99-20.08,20.08v104.857
	c0,11.09,8.99,20.08,20.08,20.08H157V9.492z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M79.375,54.579l-6.272,8.636
	c-3.144,4.33-2.171,12.002,2.123,15.102l11.628,8.395c1.995,1.44,2.152,2.868,1.206,4.391l1.688,1.255l5.886-8.154
	c3.638-5.038,3.321-11.896-2.074-15.825l-11.724-8.54c-1.405-1.023-1.549-2.451-0.772-4.052L79.375,54.579L79.375,54.579z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M89.437,16.395l-9.764,13.503
	c-4.579,6.333-2.914,17.148,3.342,21.739l16.052,11.781c3.254,2.388,3.678,8.47,1.31,11.589l1.361,1.021l11.071-15.578
	c4.973-6.995,4.684-17.331-3.123-23l-17.17-12.467c-2.403-1.745-3.604-4.711-1.602-7.543L89.437,16.395L89.437,16.395z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M63.705,101.08h12.939l2.306,7.845c7.048,0,14.097,0,21.145,0
	l2.307-7.845h12.938l-1.696-5.77c-16.08,0-32.162,0-48.243,0L63.705,101.08L63.705,101.08z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#006697"
      d="M130.525,141.327c0-0.119-0.016-0.225-0.048-0.316
	c-0.031-0.092-0.083-0.176-0.157-0.252s-0.172-0.147-0.295-0.211c-0.123-0.064-0.274-0.129-0.457-0.192
	c-0.167-0.059-0.301-0.112-0.404-0.161c-0.102-0.049-0.182-0.099-0.238-0.148c-0.056-0.051-0.093-0.104-0.112-0.156
	c-0.019-0.054-0.028-0.114-0.028-0.182c0-0.063,0.013-0.124,0.035-0.181c0.024-0.057,0.062-0.106,0.113-0.149
	c0.052-0.042,0.118-0.077,0.2-0.103c0.082-0.026,0.18-0.039,0.293-0.039c0.166,0,0.311,0.024,0.432,0.075s0.241,0.124,0.36,0.223
	l0.236-0.279c-0.139-0.107-0.285-0.191-0.441-0.253c-0.155-0.061-0.344-0.092-0.563-0.092c-0.154,0-0.294,0.019-0.422,0.055
	c-0.128,0.037-0.235,0.092-0.326,0.163c-0.089,0.072-0.159,0.159-0.209,0.26s-0.075,0.215-0.075,0.344
	c0,0.116,0.017,0.219,0.049,0.307c0.033,0.089,0.087,0.17,0.16,0.241c0.074,0.072,0.172,0.139,0.293,0.201
	c0.121,0.063,0.271,0.125,0.45,0.187c0.15,0.052,0.276,0.104,0.377,0.153c0.101,0.051,0.182,0.103,0.243,0.154
	c0.061,0.052,0.104,0.108,0.129,0.169c0.025,0.062,0.038,0.129,0.038,0.202c0,0.171-0.063,0.306-0.19,0.403
	c-0.128,0.098-0.302,0.147-0.521,0.147c-0.169,0-0.327-0.036-0.471-0.106c-0.146-0.07-0.28-0.163-0.406-0.279l-0.245,0.266
	c0.132,0.129,0.293,0.235,0.483,0.319c0.189,0.084,0.398,0.126,0.625,0.126c0.173,0,0.326-0.023,0.462-0.069
	c0.135-0.045,0.25-0.108,0.344-0.188c0.095-0.08,0.166-0.175,0.215-0.284C130.501,141.57,130.525,141.453,130.525,141.327
	L130.525,141.327z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#006697"
      d="M133.948,142.171v-3.21h-0.4l-0.788,1.922
		c-0.019,0.045-0.039,0.098-0.063,0.155c-0.023,0.059-0.046,0.116-0.068,0.175c-0.021,0.058-0.042,0.113-0.062,0.165
		c-0.019,0.052-0.032,0.095-0.042,0.128c-0.01-0.033-0.024-0.075-0.042-0.126c-0.02-0.051-0.04-0.104-0.062-0.162
		c-0.022-0.059-0.046-0.116-0.068-0.175c-0.024-0.059-0.045-0.111-0.063-0.16l-0.778-1.922h-0.41v3.21h0.354v-2.174v-0.344
		c0.01,0.03,0.021,0.068,0.035,0.112c0.014,0.045,0.03,0.09,0.048,0.138c0.017,0.047,0.035,0.097,0.054,0.146
		c0.019,0.051,0.038,0.096,0.057,0.136l0.867,2.068l0.882-2.068c0.019-0.043,0.038-0.089,0.057-0.138s0.038-0.098,0.057-0.145
		c0.019-0.048,0.035-0.093,0.049-0.138c0.015-0.044,0.026-0.082,0.036-0.112v0.344v2.174H133.948L133.948,142.171z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M74.828,133.271c-0.551-3.73-2.054-4.624-5.061-5.949
		c2.273-1.33,3.67-3.286,3.67-5.967c0-5.359-4.391-7.543-9.18-7.543H50.878v28.449h13.254
		C69.793,142.261,75.792,139.786,74.828,133.271z M56.93,119.25h6.952c4.343,0,4.5,6.068,0.273,6.068H56.93V119.25z M64.683,136.885
		H56.93v-6.49h7.753C70.384,130.395,70.037,136.885,64.683,136.885z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M101.393,133.271c-0.552-3.73-2.056-4.624-5.061-5.949
		c2.272-1.33,3.669-3.286,3.669-5.967c0-5.359-4.39-7.543-9.18-7.543H77.442v28.449h13.254
		C96.356,142.261,102.355,139.786,101.393,133.271z M83.494,119.25h6.952c4.344,0,4.5,6.068,0.273,6.068h-7.225V119.25z
		M91.246,136.885h-7.752v-6.49h7.752C96.947,130.395,96.601,136.885,91.246,136.885z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#0E7C98"
      d="M127.956,133.271c-0.552-3.73-2.055-4.624-5.061-5.949
		c2.273-1.33,3.669-3.286,3.669-5.967c0-5.359-4.39-7.543-9.179-7.543h-13.38v28.449h13.255
		C122.92,142.261,128.92,139.786,127.956,133.271z M110.058,119.25h6.951c4.344,0,4.5,6.068,0.273,6.068h-7.225V119.25z
		M117.81,136.885h-7.752v-6.49h7.752C123.512,130.395,123.164,136.885,117.81,136.885z"
    />
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M222.977,65.311c-1.617-1.861-4.162-3.57-6.563-4.225
			c-1.044-0.285-2.206-0.427-3.485-0.427c-1.8,0-3.445,0.328-4.933,0.984c-1.487,0.657-2.767,1.573-3.837,2.75
			c-1.069,1.176-1.906,2.588-2.505,4.235c-0.601,1.646-0.9,3.46-0.9,5.442c0,2.056,0.287,3.907,0.861,5.553
			c0.574,1.647,1.389,3.053,2.447,4.218c1.058,1.163,2.316,2.062,3.778,2.693c1.461,0.631,3.092,0.947,4.893,0.947
			c3.317,0,9.063-1.815,10.7-5.049l-3.897-3.455c-1.108,1.764-4.568,3.339-6.646,3.339c-1.018,0-1.932-0.209-2.74-0.631
			c-0.809-0.421-1.494-1.003-2.055-1.746c-0.562-0.743-0.985-1.616-1.272-2.619c-0.287-1.002-0.431-2.086-0.431-3.25
			c0-1.213,0.144-2.322,0.431-3.325c0.288-1.003,0.705-1.87,1.252-2.601c0.548-0.73,1.227-1.3,2.036-1.708
			c0.809-0.409,1.722-0.613,2.74-0.613c2.058,0,4.966,1.364,6.326,3.153L222.977,65.311L222.977,65.311z"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="298.014,87.073 298.014,81.982 283.882,81.982
			283.882,75.704 295.255,75.704 295.255,70.653 283.882,70.653 283.882,66.157 297.428,66.157 297.428,61.068 278.4,61.068
			278.4,87.073 298.014,87.073 	"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M323.266,68.033c-0.692-1.647-1.646-2.99-2.86-4.031
			c-1.213-1.04-2.646-1.789-4.305-2.247c-1.657-0.458-3.467-0.687-5.424-0.687h-8.575v26.005h8.186c1.748,0,3.464-0.249,5.147-0.743
			c1.684-0.496,3.183-1.271,4.502-2.323c1.317-1.052,2.376-2.402,3.17-4.049c0.796-1.646,1.194-3.609,1.194-5.888
			C324.301,71.692,323.955,69.681,323.266,68.033z M318.154,77.32c-0.339,0.979-0.849,1.813-1.525,2.507
			c-0.679,0.693-1.549,1.232-2.605,1.616c-1.056,0.384-2.303,0.576-3.736,0.576h-2.701V66.121h2.896c2.896,0,4.983,0.662,6.264,1.987
			c1.277,1.325,1.918,3.313,1.918,5.962C318.664,75.259,318.495,76.342,318.154,77.32z"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="335.086,87.073 335.086,61.068 329.604,61.068
			329.604,87.073 335.086,87.073"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="360.629,66.195 360.629,61.068 339.408,61.068
			339.408,66.195 347.278,66.195 347.278,87.073 352.759,87.073 352.759,66.195 360.629,66.195"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M409.032,68.033c-0.69-1.647-1.643-2.99-2.858-4.031
			c-1.212-1.04-2.648-1.789-4.304-2.247c-1.658-0.458-3.467-0.687-5.424-0.687h-8.575v26.005h8.183c1.748,0,3.466-0.249,5.149-0.743
			c1.685-0.496,3.184-1.271,4.503-2.323c1.316-1.052,2.373-2.402,3.17-4.049c0.796-1.646,1.194-3.609,1.194-5.888
			C410.07,71.692,409.724,69.681,409.032,68.033z M403.923,77.32c-0.338,0.979-0.848,1.813-1.524,2.507
			c-0.68,0.693-1.549,1.232-2.605,1.616s-2.303,0.576-3.739,0.576h-2.702V66.121h2.899c2.896,0,4.983,0.662,6.265,1.987
			c1.277,1.325,1.918,3.313,1.918,5.962C404.434,75.259,404.264,76.342,403.923,77.32z"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="383.765,87.073 383.765,81.982 369.631,81.982
			369.631,75.704 381.003,75.704 381.003,70.653 369.631,70.653 369.631,66.157 383.177,66.157 383.177,61.068 364.149,61.068
			364.149,87.073 383.765,87.073"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="337.904,120.65 337.904,115.561 323.772,115.561
			323.772,109.282 335.146,109.282 335.146,104.229 323.772,104.229 323.772,99.735 337.318,99.735 337.318,94.646 318.289,94.646
			318.289,120.65 337.904,120.65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M248.283,65.311c-1.617-1.861-4.162-3.57-6.563-4.225
			c-1.044-0.285-2.206-0.427-3.484-0.427c-1.8,0-3.445,0.328-4.933,0.984c-1.488,0.657-2.767,1.573-3.836,2.75
			c-1.07,1.176-1.906,2.588-2.506,4.235c-0.601,1.646-0.9,3.46-0.9,5.442c0,2.056,0.287,3.907,0.861,5.553
			c0.575,1.647,1.39,3.053,2.448,4.218c1.057,1.163,2.316,2.062,3.778,2.693c1.461,0.631,3.092,0.947,4.894,0.947
			c3.317,0,9.063-1.815,10.699-5.049l-3.897-3.455c-1.108,1.764-4.569,3.339-6.645,3.339c-1.019,0-1.932-0.209-2.741-0.631
			c-0.809-0.421-1.494-1.003-2.056-1.746c-0.561-0.743-0.985-1.616-1.272-2.619c-0.288-1.002-0.431-2.086-0.431-3.25
			c0-1.213,0.143-2.322,0.431-3.325c0.287-1.003,0.705-1.87,1.252-2.601c0.548-0.73,1.227-1.3,2.036-1.708
			c0.81-0.409,1.723-0.613,2.741-0.613c2.057,0,4.966,1.364,6.326,3.153L248.283,65.311L248.283,65.311z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M224.812,108.688c-0.711-0.94-1.602-1.646-2.67-2.117
			c0.89-0.521,1.583-1.213,2.079-2.081c0.496-0.866,0.745-1.856,0.745-2.972c0-2.229-0.763-3.931-2.289-5.107
			c-1.526-1.178-3.585-1.764-6.179-1.764h-12.244v26.004h12.129c1.424,0,2.721-0.179,3.89-0.538c1.17-0.36,2.168-0.893,2.995-1.598
			c0.826-0.707,1.468-1.585,1.926-2.638c0.458-1.053,0.687-2.26,0.687-3.623C225.88,110.817,225.524,109.629,224.812,108.688z
			M209.478,99.437h6.79c1.169,0,2.008,0.211,2.517,0.633c0.509,0.42,0.763,1.04,0.763,1.857c0,0.718-0.222,1.306-0.667,1.765
			c-0.445,0.459-1.227,0.688-2.346,0.688h-7.057V99.437z M219.453,114.799c-0.623,0.607-1.583,0.91-2.88,0.91h-7.094v-6.724h7.094
			c2.543,0,3.814,1.114,3.814,3.343C220.387,113.369,220.076,114.191,219.453,114.799z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M251.07,109.172V94.646h-5.339v14.6
			c0,2.104-0.49,3.751-1.468,4.94c-0.979,1.188-2.321,1.782-4.024,1.782s-3.039-0.605-4.005-1.82c-0.966-1.213-1.45-2.872-1.45-4.977
			V94.646h-5.34v14.6c0,1.906,0.26,3.59,0.782,5.051c0.521,1.461,1.252,2.693,2.193,3.697c0.94,1.003,2.079,1.764,3.414,2.285
			c1.335,0.52,2.803,0.78,4.406,0.78c3.407,0,6.064-1.034,7.972-3.103S251.07,112.961,251.07,109.172L251.07,109.172z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M275.613,112.774c0-1.411-0.267-2.576-0.801-3.492
			s-1.227-1.671-2.079-2.266c-0.852-0.595-1.806-1.06-2.86-1.394c-1.056-0.334-2.117-0.626-3.186-0.873
			c-1.067-0.247-1.945-0.471-2.632-0.669c-0.686-0.198-1.232-0.408-1.64-0.631c-0.406-0.223-0.693-0.478-0.858-0.763
			c-0.165-0.283-0.248-0.636-0.248-1.058c0-0.299,0.064-0.582,0.19-0.854c0.127-0.272,0.344-0.52,0.648-0.744
			c0.306-0.222,0.699-0.401,1.183-0.538c0.483-0.136,1.081-0.204,1.793-0.204c1.221,0,2.319,0.229,3.3,0.687
			c0.979,0.458,1.9,1.122,2.765,1.988l3.853-3.641c-1.271-1.288-2.688-2.292-4.252-3.009c-1.565-0.719-3.389-1.078-5.475-1.078
			c-1.322,0-2.562,0.174-3.719,0.52c-1.157,0.348-2.167,0.85-3.032,1.506c-0.864,0.656-1.545,1.455-2.041,2.396
			c-0.495,0.94-0.743,2.006-0.743,3.194c0,1.288,0.203,2.365,0.61,3.232c0.406,0.866,0.979,1.584,1.716,2.154
			c0.738,0.57,1.621,1.04,2.651,1.412c1.03,0.37,2.155,0.693,3.375,0.966c1.247,0.271,2.264,0.531,3.052,0.78
			c0.789,0.247,1.393,0.508,1.812,0.779c0.42,0.273,0.706,0.563,0.858,0.873c0.153,0.31,0.229,0.675,0.229,1.096
			c0,0.991-0.376,1.71-1.126,2.155c-0.749,0.446-1.926,0.668-3.528,0.668c-1.297,0-2.523-0.229-3.681-0.687
			c-1.156-0.458-2.206-1.22-3.146-2.285l-3.853,3.345c1.017,1.461,2.441,2.612,4.272,3.454c1.83,0.843,3.865,1.264,6.103,1.264
			c1.831,0,3.401-0.199,4.711-0.595c1.309-0.396,2.396-0.961,3.262-1.69c0.863-0.73,1.499-1.604,1.906-2.619
			C275.41,115.14,275.613,114.014,275.613,112.774L275.613,112.774z"
      />
      <polygon
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        points="285.279,120.65 285.279,94.646 279.94,94.646
			279.94,120.65 285.279,120.65"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M312.455,120.65V94.646h-5.266v10.994
			c0,0.373,0.008,0.831,0.019,1.376c0.013,0.545,0.021,1.103,0.021,1.671c0,0.545,0.006,1.06,0.019,1.542
			c0.013,0.484,0.021,0.849,0.021,1.096c-0.18-0.347-0.452-0.798-0.822-1.356c-0.367-0.557-0.718-1.082-1.049-1.578l-9.457-13.744
			h-5.111v26.004h5.263v-11.442c0-0.372-0.006-0.83-0.019-1.374c-0.013-0.545-0.021-1.09-0.021-1.635
			c0-0.569-0.006-1.097-0.019-1.579c-0.013-0.483-0.019-0.849-0.019-1.096c0.177,0.346,0.45,0.799,0.82,1.355
			c0.369,0.557,0.718,1.084,1.049,1.579l9.803,14.191H312.455L312.455,120.65z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M359.71,112.774c0-1.411-0.268-2.576-0.802-3.492
			c-0.533-0.916-1.229-1.671-2.079-2.266s-1.806-1.06-2.86-1.394c-1.057-0.334-2.118-0.626-3.186-0.873
			c-1.066-0.247-1.943-0.471-2.631-0.669c-0.687-0.198-1.233-0.408-1.642-0.631c-0.406-0.223-0.692-0.478-0.856-0.763
			c-0.167-0.283-0.25-0.636-0.25-1.058c0-0.299,0.065-0.582,0.192-0.854c0.128-0.272,0.344-0.52,0.648-0.744
			c0.305-0.222,0.697-0.401,1.182-0.538c0.483-0.136,1.08-0.204,1.793-0.204c1.221,0,2.321,0.229,3.3,0.687s1.899,1.122,2.764,1.988
			l3.854-3.641c-1.272-1.288-2.691-2.292-4.255-3.009c-1.562-0.719-3.386-1.078-5.474-1.078c-1.321,0-2.561,0.174-3.719,0.52
			c-1.155,0.348-2.168,0.85-3.031,1.506c-0.864,0.656-1.546,1.455-2.041,2.396c-0.497,0.94-0.744,2.006-0.744,3.194
			c0,1.288,0.203,2.365,0.611,3.232c0.406,0.866,0.979,1.584,1.716,2.154c0.738,0.57,1.621,1.04,2.651,1.412
			c1.03,0.37,2.155,0.693,3.375,0.966c1.247,0.271,2.265,0.531,3.051,0.78c0.788,0.247,1.392,0.508,1.813,0.779
			c0.419,0.273,0.705,0.563,0.856,0.873c0.153,0.31,0.229,0.675,0.229,1.096c0,0.991-0.374,1.71-1.124,2.155
			c-0.749,0.446-1.926,0.668-3.528,0.668c-1.297,0-2.522-0.229-3.681-0.687c-1.157-0.458-2.206-1.22-3.146-2.285l-3.854,3.345
			c1.018,1.461,2.44,2.612,4.273,3.454c1.829,0.843,3.864,1.264,6.103,1.264c1.829,0,3.401-0.199,4.71-0.595
			c1.31-0.396,2.397-0.961,3.261-1.69c0.864-0.73,1.502-1.604,1.908-2.619C359.505,115.14,359.71,114.014,359.71,112.774
			L359.71,112.774z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M382.65,112.774c0-1.411-0.268-2.576-0.801-3.492
			c-0.534-0.916-1.229-1.671-2.08-2.266c-0.851-0.595-1.806-1.06-2.859-1.394c-1.057-0.334-2.119-0.626-3.186-0.873
			c-1.067-0.247-1.944-0.471-2.632-0.669c-0.687-0.198-1.233-0.408-1.64-0.631c-0.408-0.223-0.694-0.478-0.858-0.763
			c-0.166-0.283-0.25-0.636-0.25-1.058c0-0.299,0.065-0.582,0.192-0.854c0.128-0.272,0.344-0.52,0.648-0.744
			c0.305-0.222,0.7-0.401,1.182-0.538c0.484-0.136,1.08-0.204,1.793-0.204c1.221,0,2.321,0.229,3.3,0.687s1.9,1.122,2.767,1.988
			l3.852-3.641c-1.272-1.288-2.688-2.292-4.252-3.009c-1.564-0.719-3.389-1.078-5.476-1.078c-1.322,0-2.562,0.174-3.72,0.52
			c-1.155,0.348-2.167,0.85-3.031,1.506s-1.544,1.455-2.041,2.396c-0.497,0.94-0.744,2.006-0.744,3.194
			c0,1.288,0.203,2.365,0.612,3.232c0.405,0.866,0.979,1.584,1.715,2.154c0.739,0.57,1.621,1.04,2.651,1.412
			c1.031,0.37,2.155,0.693,3.376,0.966c1.246,0.271,2.264,0.531,3.05,0.78c0.788,0.247,1.393,0.508,1.813,0.779
			c0.419,0.273,0.706,0.563,0.859,0.873c0.15,0.31,0.227,0.675,0.227,1.096c0,0.991-0.375,1.71-1.125,2.155
			c-0.749,0.446-1.926,0.668-3.528,0.668c-1.296,0-2.524-0.229-3.68-0.687c-1.158-0.458-2.207-1.22-3.146-2.285l-3.854,3.345
			c1.018,1.461,2.441,2.612,4.273,3.454c1.832,0.843,3.864,1.264,6.103,1.264c1.832,0,3.401-0.199,4.711-0.595
			c1.309-0.396,2.396-0.961,3.261-1.69c0.866-0.73,1.501-1.604,1.907-2.619C382.447,115.14,382.65,114.014,382.65,112.774
			L382.65,112.774z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M189.421,61.068h-5.324l-10.493,26.005h5.873l2.153-5.796h10.023
			l2.153,5.796h6.029L189.421,61.068z M183.51,76.336l1.761-4.718c0.288-0.768,0.555-1.511,0.803-2.229s0.437-1.325,0.568-1.82
			c0.13,0.496,0.319,1.102,0.567,1.82s0.515,1.461,0.803,2.229l1.762,4.718H183.51z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#FFFFFF"
        d="M268.899,77.191c1.748-0.644,3.696-1.815,4.57-3.066
			c0.875-1.25,1.313-2.855,1.313-4.811c0-1.387-0.249-2.595-0.744-3.623c-0.496-1.028-1.175-1.888-2.036-2.582
			c-0.861-0.694-1.866-1.208-3.015-1.542s-2.362-0.501-3.641-0.501h-12.764v26.005h5.481V77.71l4.884,0.224l5.729,9.139h6.303
			L268.899,77.191z M265.229,72.808h-7.164v-6.687h7.164c1.227,0,2.187,0.253,2.878,0.761c0.691,0.507,1.037,1.355,1.037,2.544
			C269.144,71.679,267.839,72.808,265.229,72.808z"
      />
    </g>
  </svg>
);

export default BBBLogo;
