import React from 'react';
import { createURL } from '@sm/utils';
import Menu from './components/Menu.js';
import { helpCenterMenu } from '../../config/linkInfo';

export const HelpCenterMenu = () => {
  const path = createURL('https://help.surveymonkey.com/', {
    ut_source: 'megamenu',
  });

  const key = 'Help Center';

  return (
    <Menu {...helpCenterMenu()} path={path} menuKey={key}>
      <div />
    </Menu>
  );
};

export default HelpCenterMenu;
