import React, { ReactElement } from 'react';
import { defineMessages, FormattedDateTime, T } from '@sm/intl';
import { Box } from '@wds/box';
import { ProgressCircle } from '@wds/progress-circle';
import { Typography } from '@wds/typography';
import { WebAssetsThemeWrapper } from '../../theme';
import AccountName from './AccountName/AccountName';
import AccountTypeBadge from './AccountTypeBadge/AccountTypeBadge';
import { AccountCardProps, CardSpinnerProps } from './constants';
import useStyles from './useStyles';

export const ACCOUNT_CARD_TEST_ID = 'AccountPicker__AccountCard';

const COPY = defineMessages({
  lastLogin: {
    id: 'AccountCard.lastLoginDate',
    defaultMessage: 'Last login {date}',
    description:
      '[Type: Label][Vis.: med] - Label for a field that shows the date when user last logged into an account',
  },
});

const CardSpinner = ({
  className = undefined,
  altText,
}: CardSpinnerProps): ReactElement => {
  return (
    <Box
      display="flex"
      flexJustify="center"
      flexAlign="center"
      className={className}
    >
      <ProgressCircle size="sm" continuous valueText={altText} />
    </Box>
  );
};

const AccountCard = (props: AccountCardProps): ReactElement => {
  return (
    <WebAssetsThemeWrapper>
      <AccountCardContent {...props} />
    </WebAssetsThemeWrapper>
  );
};

/**
 * Interactive card for rendering a `LinkedIdentity`
 */
const AccountCardContent = ({
  account,
  addOnLeft,
  addOnRight,
  height,
  onClick,
  onKeyDown,
  selected = false,
  loading = false,
  loadingAltText = '',
  ...props
}: AccountCardProps): ReactElement => {
  const {
    accountCard,
    accountCardDynamic,
    accountCardBody,
    accountType,
    spinner,
  } = useStyles({
    selected,
    loading,
    height,
  });

  const mainContent = (
    <div>
      {/* Top badge */}
      <div className={accountType}>
        <AccountTypeBadge
          packageId={parseInt(account.packageId, 10)}
          teamName={account.teamName || undefined}
        />
      </div>

      <Box
        mt={4}
        display="flex"
        flexDirection="column"
        flexJustify="flex-start"
      >
        <AccountName username={account.username} />
        {account.lastLoggedInAt ? (
          <Typography variant="bodySm" color="darkMuted">
            <T
              desc={COPY.lastLogin}
              values={{
                date: FormattedDateTime({
                  value: new Date(account.lastLoggedInAt),
                  month: 'short',
                  day: 'numeric',
                  year: 'numeric',
                }),
              }}
            />
          </Typography>
        ) : (
          <></>
        )}
      </Box>
    </div>
  );

  const leftContent = addOnLeft ? (
    <Box display="flex" flexAlign="center">
      <Box mr={4}>{addOnLeft}</Box>
    </Box>
  ) : (
    <></>
  );

  const rightContent = addOnRight ? (
    <>
      <div style={{ flexGrow: 1 }} />
      <Box display="flex" flexAlign="center">
        <Box ml={2}>{addOnRight}</Box>
      </Box>
    </>
  ) : (
    <></>
  );

  const cardProps = {
    role: 'button',
    onKeyDown,
    onClick,
    tabIndex: 0,
    ...props,
  };

  return (
    <Box mb={2}>
      <div
        data-testid={ACCOUNT_CARD_TEST_ID}
        className={`${accountCard} ${accountCardDynamic}`}
        {...cardProps}
      >
        {loading ? (
          <div className={accountCardBody}>
            <CardSpinner className={spinner} altText={loadingAltText} />
          </div>
        ) : (
          <div className={accountCardBody}>
            {leftContent}
            {mainContent}
            {rightContent}
          </div>
        )}
      </div>
    </Box>
  );
};

export default AccountCard;
