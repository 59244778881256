import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@wds/grid';
import { Typography } from '@wds/typography';

export const SurveyTypesColumn = ({ children, title }) => {
  return (
    <Grid.Item>
      <div className="sm-nav-submenu__col">
        <div className="sm-nav-submenu__column-title">
          <Typography variant="caption" weight="medium" color="dark">
            {title}
          </Typography>
        </div>
        {children}
      </div>
    </Grid.Item>
  );
};

export default SurveyTypesColumn;

SurveyTypesColumn.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};
