import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';

export const Block = ({ children }) => {
  return (
    <Box
      mt="5"
      mb="5"
      ml="4"
      mr="4"
      key="CustomerSatisfaction"
      className="sm-nav-submenu__by-need-container"
    >
      {children}
    </Box>
  );
};

export default Block;

Block.propTypes = {
  children: PropTypes.node.isRequired,
};
