import React, { useContext } from 'react';
import { List } from '@wds/list';
import { SubMenuLink } from './components/SubMenuLink';
import { SeeMoreLink } from './components/SeeMoreLink';
import {
  resources,
  blog,
  helpCenter,
  exploreSurveyTemplates,
} from '../../config/linkInfo.js';
import SMHeaderContext from '../../SMHeaderContext';

export const ResourcesMenu = () => {
  const { lang } = useContext(SMHeaderContext);

  const isEnglish = ['en', 'en-gb'].includes(lang);

  return (
    <List interactive transparent noRules>
      {isEnglish ? <SubMenuLink {...resources()} /> : null}
      <SubMenuLink {...blog()} />
      <SubMenuLink {...helpCenter(lang)} />
      {isEnglish ? <SeeMoreLink {...exploreSurveyTemplates()} /> : null}
    </List>
  );
};

export default ResourcesMenu;
