import React, { ReactNode, ReactElement } from 'react';
import Media from 'react-media';

const breakPoints: Record<string, number> = {
  md: 768,
  lg: 1200,
};

const MOBILE_SCREEN: string = `(max-width: ${breakPoints.md - 1}px)`;
const TABLET_SCREEN: string = `(min-width: ${breakPoints.md}px)`;
const MOBILE_TABLET_SCREEN: string = `(max-width: ${breakPoints.lg - 1}px)`;
const DESKTOP_SCREEN: string = `(min-width: ${breakPoints.lg}px)`;

type Props = {
  defaultMatches?: boolean;
  children: ReactNode;
};

export const MobileScreen = ({
  children,
  defaultMatches,
}: Props): ReactElement => (
  <Media
    defaultMatches={defaultMatches}
    query={MOBILE_SCREEN}
    render={() => children}
  />
);

export const TabletScreen = ({
  children,
  defaultMatches,
}: Props): ReactElement => (
  <Media
    defaultMatches={defaultMatches}
    query={TABLET_SCREEN}
    render={() => children}
  />
);

export const MobileTabletScreen = ({
  children,
  defaultMatches,
}: Props): ReactElement => (
  <Media
    defaultMatches={defaultMatches}
    query={MOBILE_TABLET_SCREEN}
    render={() => children}
  />
);

export const DesktopScreen = ({ children, defaultMatches }: Props) => (
  <Media
    defaultMatches={defaultMatches}
    query={DESKTOP_SCREEN}
    render={() => children}
  />
);
