import { withWebAssetsTheme } from '../../theme';
import { withClientErrorPage } from '../ClientError';
import FiveHundredError, { FiveHundredErrorProps } from './FiveHundredError';

export default withWebAssetsTheme(
  withClientErrorPage<FiveHundredErrorProps>(FiveHundredError, {
    titularElementTag: 'h1', // set default to H1
    errorCode: 500,
  })
);
