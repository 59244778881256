type DeepReadOnly<T> = { readonly [P in keyof T]: DeepReadOnly<T[P]> };
/**
 * Freezes the object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function deepFreeze<T extends Record<string, any>>(
  o: T
): DeepReadOnly<T> {
  Object.freeze(o);

  Object.getOwnPropertyNames(o).forEach(prop => {
    if (
      Object.hasOwnProperty.call(o, prop) &&
      o[prop] !== null &&
      (typeof o[prop] === 'object' || typeof o[prop] === 'function') &&
      !Object.isFrozen(o[prop])
    ) {
      deepFreeze(o[prop]);
    }
  });

  return o;
}
