import React, { useContext } from 'react';
import { Box } from '@wds/box';
import { getClientEnvironmentDetails } from '@sm/utils';

import useStyles from './useStyles';

import { useTrusteSealsQueryQuery } from './TrusteSeals.generated';

import DirectDebitLogo from './DirectDebitLogo';
import SepaLogo from './SepaLogo';
import BBBLogo from './BBBLogo';
import TrustedShop from './TrustedShop';
import { SMFooterContext } from '../SMFooterContext';

const showTrustedShopSeal = (lang: string, country: string): boolean => {
  if (['DE', 'AT', 'CH'].includes(country) && lang === 'de') {
    return true;
  }
  return false;
};

const handleContextMenuClick = () => {
  // eslint-disable-next-line no-alert
  alert(
    'Copying Prohibited by Law - TrustedSite is a Trademark of TrustedSite, LLC'
  );
  return false;
};

const FooterSeals = (): React.ReactElement | null => {
  const { loading, error, data } = useTrusteSealsQueryQuery();

  if (loading) return null;
  if (error) {
    console.error(`An error occured with the graph query: ${error}`);
  }

  const recentInvoice =
    (data &&
      data.user &&
      data.user.team &&
      data.user.team.billing &&
      data.user.team.billing.recentInvoice) ||
    {};

  const { paymentMethod, paymentMethodSubtype } = recentInvoice;

  return paymentMethod === 'DIRECT_DEBIT' ? (
    <Box m={2}>
      {paymentMethodSubtype === 'GOCARDLESS_SEPA_DD' ? (
        <SepaLogo />
      ) : (
        <DirectDebitLogo />
      )}
    </Box>
  ) : null;
};

const TrusteSeals = () => {
  const {
    lang = '',
    country = '',
    isUserAuthenticated,
    isEUDC,
  } = useContext(SMFooterContext);
  const { trustedSeal } = useStyles();
  const { isBrowser } = getClientEnvironmentDetails();

  return (
    <Box display="flex" flexAlign="center">
      <Box mr={2}>
        <a
          title="Click to verify BBB accreditation and to see a BBB report."
          href="https://www.bbb.org/us/ca/san-mateo/profile/market-survey/surveymonkey-1116-876628"
          rel="noopener noreferrer"
          target="_blank"
        >
          <BBBLogo />
        </a>
      </Box>
      <Box m={2}>
        <a
          rel="noopener noreferrer"
          target="_blank"
          title="View TrustedSite Certification"
          href={`https://www.trustedsite.com/verify?host=${
            isEUDC ? 'eu' : 'www'
          }.surveymonkey.com`}
        >
          <img
            className={trustedSeal}
            alt="TrustedSite helps keep you safe from identity theft, credit card fraud, spyware, spam, viruses and online scams"
            src="https://cdn.ywxi.net/meter/surveymonkey.com/202.svg"
            onContextMenu={handleContextMenuClick}
          />
        </a>
      </Box>
      {!!(isUserAuthenticated && isBrowser) && <FooterSeals />}
      {showTrustedShopSeal(lang, country) && <TrustedShop />}
    </Box>
  );
};

export default TrusteSeals;
