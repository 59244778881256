import React from 'react';
import AnonFooter from './AnonFooter';
import AuthedFooter from './AuthedFooter';
import { FooterContext, FooterContextProps } from './SMFooterContext';

const SMFooter = ({
  isUserAuthenticated,
  lang,
  domain,
  subdomain,
  country,
  variant = 'default',
  showBillingAddress = false,
  isEUDC,
  tld,
  url = undefined,
  onetrustBannerEnabled,
  fidesBannerEnabled,
  makeFooterLinksStatic = false,
}: Partial<FooterContextProps>) => {
  return (
    <FooterContext
      isUserAuthenticated={isUserAuthenticated}
      lang={lang}
      subdomain={subdomain}
      domain={domain}
      country={country}
      variant={variant}
      showBillingAddress={showBillingAddress}
      isEUDC={isEUDC}
      tld={tld}
      url={url}
      onetrustBannerEnabled={onetrustBannerEnabled}
      fidesBannerEnabled={fidesBannerEnabled}
      makeFooterLinksStatic={makeFooterLinksStatic}
    >
      {isUserAuthenticated ? <AuthedFooter /> : <AnonFooter />}
    </FooterContext>
  );
};

export default SMFooter;
