/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { ReactElement, ReactNode } from 'react';
import {
  ApolloClient,
  ApolloError,
  Context,
  DocumentNode,
  OperationVariables,
  useQuery,
} from '@apollo/client';

export type ErrorControllerProps<T> = {
  children: ReactNode;
  query: DocumentNode;
  variables: OperationVariables;
  apolloClient?: ApolloClient<unknown>;
  context?: Context;
  handler(loading: boolean, error?: ApolloError, data?: T): ReactElement;
};

function ErrorController<T>({
  children,
  query,
  context,
  variables,
  apolloClient,
  handler,
}: ErrorControllerProps<T>) {
  const { loading, error, data } = useQuery(query, {
    context,
    variables,
    client: apolloClient,
  });
  return <>{handler(loading, error, data) || children}</>;
}

export default ErrorController;
