/**
 * TODO:
 * Is CookieBanner still being used anywhere? If not, remove it?
 */
import React, { useState, useEffect } from 'react';
import Cookie from 'js-cookie';
import PropTypes from 'prop-types';
import { T, t, defineMessages } from '@sm/intl';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';

import { Box } from '@wds/box';
import { Typography } from '@wds/typography';
import { Sheet } from '@wds/sheet';
import { Button } from '@wds/button';

import { WebAssetsThemeWrapper } from '../../theme';

const CONSENT_KEY = 'gdpr_consent';
const CONSENT_DENIED_KEY = 'gdpr_consent_denied';

const COPY = defineMessages({
  COOKIE_BANNER: {
    id: 'CookieBanner.cookiePolicy',
    defaultMessage:
      // eslint-disable-next-line no-multi-str
      'In order to give you the best experience, SurveyMonkey and our third party partners may use cookies\
    and similar technologies, for example, to analyze usage and optimize our sites and services, personalize content,\
    tailor and measure our marketing and to keep the site secure. Please visit our\
    <a href="/mp/legal/privacy-policy/">privacy policy</a> for more information and our\
    <a href="/mp/legal/cookies/">cookies policy</a> for a list of all cookies used.',
    description: '[Type: Label][Vis.: med] - Cookie Banner Message',
  },
  COOKIE_PREFERENCES: {
    id: 'CookieBanner.preferences',
    defaultMessage:
      '<a href="/mp/legal/cookies/">Clear or manage cookie preferences</a>.',
    description: '[Type: Label][Vis.: med] - Cookie Banner Preferences',
  },
  NO: {
    id: 'CookieBanner.decline',
    defaultMessage: 'DISAGREE',
    description: '[Type: Label][Vis.: med] - Cookie Banner Decline button',
  },
  AGREE: {
    id: 'CookieBanner.agree',
    defaultMessage: 'AGREE',
    description: '[Type: Label][Vis.: med] - Cookie Banner Agree button',
  },
});

const CookieBanner = ({ GDPR }) => {
  const { hasGDPRConsent, hasExplictlyDenied } = GDPR;
  const [show, setShow] = useState(true);
  const [consent, setConsent] = useState('gdpr_consent_denied');

  useEffect(() => {
    if (!show) {
      MetricsTracker.track({
        name: USER_EVENTS.GDPR_ACTION,
        data: {
          actionType: USER_EVENTS.GDPR_ACTION,
          actionFlow: consent !== CONSENT_KEY ? 'denyConsent' : 'giveConsent',
        },
      });
    }
  }, [show, consent]);

  if (hasGDPRConsent || hasExplictlyDenied || !show) {
    return null;
  }

  /**
   * Agree to tracking cookie
   * @param {Object} e
   */
  const onAgree = e => {
    e.stopPropagation();
    Cookie.set(CONSENT_KEY, 'true', { expires: 365, secure: true });
    setConsent(CONSENT_KEY);
    setShow(!show);
  };

  /**
   * Deny tracking cookie
   */
  const onDeny = () => {
    Cookie.set(CONSENT_DENIED_KEY, 'true', { expires: 7, secure: true });
    setConsent(CONSENT_DENIED_KEY);
    setShow(!show);
  };

  return (
    <WebAssetsThemeWrapper>
      <Sheet
        placement="bottom"
        onClose={onDeny}
        show={show}
        closeButtonLabel={t(COPY.NO)}
        showCloseButton
        data-testid="CookieBanner"
      >
        <Box pt="6" pb="5" px="7">
          <Typography component="div" variant="body" color="darkMuted">
            <T desc={COPY.COOKIE_BANNER} html />
          </Typography>
          <Box mb={5} />
          <Typography color="darkMuted" component="div" variant="body">
            <T desc={COPY.COOKIE_PREFERENCES} html />
          </Typography>
          <Box pb="3" pt="3">
            <Button onClick={onAgree} data-testid="CookieBanner__Agree">
              <T desc={COPY.AGREE} />
            </Button>
          </Box>
        </Box>
      </Sheet>
    </WebAssetsThemeWrapper>
  );
};

CookieBanner.propTypes = {
  GDPR: PropTypes.shape({
    hasGDPRConsent: PropTypes.bool,
    hasExplictlyDenied: PropTypes.bool,
  }).isRequired,
};

export default CookieBanner;
