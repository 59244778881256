import { T, defineMessages } from '@sm/intl';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import { createURL } from '@sm/utils';
import { Button, ButtonColor, Variant } from '@wds/button';
import { Size } from '@wds/styles';
import React from 'react';

const COPY = defineMessages({
  JOIN_TEAM: {
    id: 'JoinTeamCTA.JoinTeam',
    defaultMessage: 'Join team',
    description: '[Type: Label][Vis: High] - Join team button in the header',
  },
});

type Props = {
  joinTeamSlug: string;
  className?: string;
  size?: Exclude<Size, 'xs' | 'xl'>;
  variant?: Variant;
  stretched?: boolean;
  color?: ButtonColor;
  amplitudeProps?: {
    pageName?: string;
    surveyId?: number;
    guestId?: number;
    surveyOwnerId?: number;
  };
};

const JoinTeamCTA = ({
  joinTeamSlug,
  className,
  size = 'sm',
  variant = 'solid',
  stretched = false,
  color = 'primary',
  amplitudeProps = {},
}: Props) => {
  const handleClick = () => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: 'join team clicked button',
        ...amplitudeProps,
      },
    });
  };

  return (
    <div className={className}>
      <Button
        size={size}
        variant={variant}
        stretched={stretched}
        color={color}
        target="_blank"
        href={createURL(`/request-team-seat/${joinTeamSlug}`)}
        onClick={handleClick}
      >
        <T desc={COPY.JOIN_TEAM} />
      </Button>
    </div>
  );
};

export default JoinTeamCTA;
