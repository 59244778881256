import React from 'react';
import { IconLogoGoldie } from '@wds/icons';
import { Box } from '@wds/box';

import { WebAssetsThemeWrapper } from '../../theme';

export type LogoColors = 'primary' | 'alt';
export type LogoProps = {
  color?: LogoColors;
  className?: string;
};

const Logo = (props: LogoProps) => (
  <WebAssetsThemeWrapper>
    <LogoContent {...props} />
  </WebAssetsThemeWrapper>
);

const LogoContent = ({
  color = 'primary',
  className = '',
  ...other
}: LogoProps) => {
  return (
    <Box display="inline-flex" className={className} {...other}>
      <IconLogoGoldie
        title="SurveyMonkey Logo"
        color={color === 'primary' ? 'primary' : 'light'}
      />
    </Box>
  );
};

export default Logo;
