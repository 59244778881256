import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Header as ExfoNavHeader } from '@sm/header';
import SMHeader from './Header';
import { HEADER_COLOR, HEADER_VARIANT } from './enums';
import { StaticContext } from '../StaticContext';
import { ValidMenuNameList } from './config/linkInfo';

const Header = ({
  actionFlow,
  alwaysUseAnonymousNav,
  color,
  ctaRedirectUrl,
  displayLICTA,
  excludeMenuItems,
  lang,
  Logo,
  isAnonHeaderSticky,
  isEUDC,
  isMRX,
  isUserAuthenticated,
  showCreateSurveyButton,
  showDrawer,
  showLoginButton,
  showSignupButton,
  title,
  user,
  variant,
  wrenchVariant,
  joinTeamSlug,
  joinTeamAmplitudeProps,
}) => {
  const session = user?.session;
  const {
    environment: { domain, subdomain, tld } = {
      domain: 'surveymonkey',
      subdomain: 'www',
      tld: 'com',
    },
  } = useContext(StaticContext);
  // eslint-disable-next-line jsx-control-statements/jsx-use-if-tag
  return user.isAuthenticated && isUserAuthenticated ? (
    <ExfoNavHeader
      variant={wrenchVariant}
      title={title}
      isEUDC={isEUDC}
      session={session}
      languageCode={lang}
      domain={domain}
      subdomain={subdomain}
      tld={tld}
    />
  ) : (
    <SMHeader
      actionFlow={actionFlow}
      alwaysUseAnonymousNav={alwaysUseAnonymousNav}
      color={color}
      ctaRedirectUrl={ctaRedirectUrl}
      displayLICTA={displayLICTA}
      excludeMenuItems={excludeMenuItems}
      lang={lang}
      Logo={Logo}
      isAnonHeaderSticky={isAnonHeaderSticky}
      isEUDC={isEUDC}
      isMRX={isMRX}
      isUserAuthenticated={isUserAuthenticated}
      showCreateSurveyButton={showCreateSurveyButton}
      showDrawer={showDrawer}
      showLoginButton={showLoginButton}
      showSignupButton={showSignupButton}
      variant={variant}
      user={user}
      joinTeamSlug={joinTeamSlug}
      joinTeamAmplitudeProps={joinTeamAmplitudeProps}
    />
  );
};

Header.propTypes = {
  actionFlow: PropTypes.string,
  alwaysUseAnonymousNav: PropTypes.bool,
  color: PropTypes.oneOf(Object.values(HEADER_COLOR)),
  ctaRedirectUrl: PropTypes.string,
  displayLICTA: PropTypes.bool,
  excludeMenuItems: PropTypes.arrayOf(PropTypes.oneOf(ValidMenuNameList)),
  lang: PropTypes.string.isRequired,
  Logo: PropTypes.oneOfType([PropTypes.node, PropTypes.object]),
  isAnonHeaderSticky: PropTypes.bool,
  isEUDC: PropTypes.bool.isRequired,
  isMRX: PropTypes.bool,
  isUserAuthenticated: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showCreateSurveyButton: PropTypes.bool,
  showDrawer: PropTypes.bool,
  showLoginButton: PropTypes.bool,
  showSignupButton: PropTypes.bool,
  title: PropTypes.string,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  variant: PropTypes.oneOf(Object.values(HEADER_VARIANT)),
  wrenchVariant: PropTypes.string,
  joinTeamSlug: PropTypes.string,
  joinTeamAmplitudeProps: PropTypes.shape({
    guestId: PropTypes.number,
    surveyId: PropTypes.number,
    surveyOwnerId: PropTypes.number,
  }),
};

Header.defaultProps = {
  actionFlow: 'header',
  alwaysUseAnonymousNav: false,
  color: 'primary',
  ctaRedirectUrl: '',
  displayLICTA: true,
  excludeMenuItems: [],
  Logo: undefined,
  isAnonHeaderSticky: true,
  isMRX: false,
  isUserAuthenticated: false,
  showCreateSurveyButton: true,
  showDrawer: true,
  showLoginButton: true,
  showSignupButton: true,
  title: undefined,
  variant: 'default',
  wrenchVariant: 'default',
  joinTeamSlug: undefined,
  joinTeamAmplitudeProps: {},
};

export default Header;

export { Header };
