import React, { createContext, ReactNode } from 'react';
import PropTypes from 'prop-types';

export type FooterVariant = 'default' | 'slim' | 'limited' | 'center';

// TODO: Have we ever defined any enums for supported country, lang, domains, subdomains, tld
export interface FooterContextProps {
  isUserAuthenticated: boolean;
  lang: string;
  domain: string;
  subdomain: string;
  country: string;
  variant: FooterVariant;
  showBillingAddress: boolean;
  isEUDC: boolean;
  tld: string;
  url?: string;
  onetrustBannerEnabled: boolean;
  fidesBannerEnabled: boolean;
  children: ReactNode;
  makeFooterLinksStatic?: boolean;
}

const SMFooterContext = createContext<Partial<FooterContextProps>>({});

const { Provider } = SMFooterContext;

const FooterContext = ({ children, ...props }: Partial<FooterContextProps>) => (
  <Provider
    value={{
      ...props,
    }}
  >
    {children}
  </Provider>
);

FooterContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SMFooterContext, FooterContext };
