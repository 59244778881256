/* eslint-disable @typescript-eslint/no-empty-function */
/**
 * SMPackages S3Uploader Module
 * @module @sm/utils
 */

import S3Upload, { S3UploadOptions } from 'react-s3-uploader/s3upload';

const DEFAULT_OPTIONS: S3UploadOptions = {
  preprocess: (file, next) => {
    next(file);
  },
  onSignedUrl: () => {},
  onProgress: () => {},
  onFinish: () => {},
  onError: () => {},
  signingUrl: '/core/api/s3/sign',
  scrubFilename: filename => {
    return `.${filename.split('.').pop()}`;
  },
  s3path: '',
  autoUpload: true,
};

type S3UploaderOptions = {
  files: File[];
  getSignedUrl: (
    f: File,
    callback: (signResult: { signedUrl: string; fileKey: string }) => void
  ) => void;
  onFinishS3Put: (
    signResult: { signedUrl: string; fileKey: string },
    file: File
  ) => void;
};

/**
 * Creates a new S3Uploader.
 * @class
 */
export default class S3Uploader {
  /**
   * @constructs S3Uploader
   * @param {Object} options S3Uploader options
   * @param {string} options.server S3Uploader server
   * @param {string} options.signingUrl S3Uploader singingUrl
   * @param {string} options.server S3Uploader server
   * @param {Array} options.successResponses {code, code} Success responses
   * @param {string} [options.signingUrlMethod="GET"] S3Uploader singingUrlMethod
   * @param {string} [options.s3path=""] S3Uploader s3path
   * @param {boolean} [options.autoUpload=true] S3Uploader autoupload
   * @param {Object} options.files S3Uploader files
   * @param {Object} options.fileElement S3Uploader fileElement
   * @param {Function} options.onFinishS3Put S3Uploader onFinishS3Put function
   * @param {Function} options.onFinish OnFinish uploading function
   * @param {Function} options.preprocess preprocess function
   * @param {Function} options.onProgress onProgress uploading function
   * @param {Function} options.onError on upload error function
   * @param {Function} options.onSignedUrl signed url function
   * @param {Function} options.fileElement fileElement
   * @param {Function} options.inputRef inputRef
   * @param {Function} options.contentDisposition
   * @param {Object} options.uploadRequestHeaders
   * @param {boolean} options.getSignedUrl
   */
  options: S3UploaderOptions;
  myUploader?: S3Upload;

  constructor(options: S3UploaderOptions) {
    this.options = { ...DEFAULT_OPTIONS, ...options };
  }

  /**
   * Upload the file
   */
  uploadFile(): void {
    this.myUploader = new S3Upload({
      ...DEFAULT_OPTIONS,
      ...this.options,
    });
  }

  /**
   * Abort the upload
   */
  abort(): void {
    if (this.myUploader) {
      this.myUploader.abortUpload();
    }
  }
}
