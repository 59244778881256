import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@wds/typography';

import SMHeaderContext from '../../../SMHeaderContext';

export const SurveySolution = ({ title, url, name }) => {
  const { excludeMenuItems } = useContext(SMHeaderContext);

  if (excludeMenuItems.includes(name)) return null;

  return (
    <a key={title} className="sm-nav-submenu__link-item" href={url}>
      <Typography color="link">{title}</Typography>
    </a>
  );
};

export default SurveySolution;

SurveySolution.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
