import React, { useState } from 'react';

import { Input } from '@wds/input';
import { InputGroup, InputGroupAddon } from '@wds/input-group';
import { Menu } from '@wds/menu';
import { ProgressCircle } from '@wds/progress-circle';
import { IconSearch, IconWarning } from '@wds/icons';
import { WebAssetsThemeWrapper } from '../../theme';
import { AutocompleteProps } from './constants';

const UNSELECTED_ITEM_INDEX = -1;

const Autocomplete = (props: AutocompleteProps) => (
  <WebAssetsThemeWrapper>
    <AutocompleteContent {...props} />
  </WebAssetsThemeWrapper>
);

const AutocompleteContent = ({
  data,
  onTextChanged,
  onItemSelected = () => {},
  text,
  placeholder,
  renderItem = item => item.text,
  onSubmit = () => {},
  isLoading = false,
  icon = <IconSearch title="Search" />,
  hasError = false,
}: AutocompleteProps) => {
  const [currentIndex, setCurrentIndex] = useState(UNSELECTED_ITEM_INDEX);

  const handleTextChange: React.ChangeEventHandler<HTMLInputElement> = e => {
    onTextChanged(e.target.value);
  };

  const handleItemSelected: React.MouseEventHandler<HTMLInputElement> = e => {
    const index: number = Number(e.currentTarget.getAttribute('data-index'));
    if (typeof index === 'number') {
      const item = data[index];
      onItemSelected(item);
    }
  };

  const hasIcon = typeof icon !== 'undefined';

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    const { value } = e.target as HTMLInputElement;
    if (e.key === 'Enter' && currentIndex > -1) {
      onItemSelected(data[currentIndex]);
    } else if (e.key === 'Enter' && value) {
      onSubmit(value);
    } else if (e.key === 'Escape') {
      onTextChanged('');
    } else if (e.key === 'ArrowDown' && currentIndex < data.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (e.key === 'ArrowUp' && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    } else if (e.key !== 'ArrowDown' && e.key !== 'ArrowUp') {
      setCurrentIndex(-1);
    }
  };

  const renderInput = () => {
    const color = hasError ? 'warning' : undefined;
    return (
      <InputGroup data-testid="Autocomplete" stretched color={color}>
        <Input
          id="Autocomplete"
          data-testid="Autocomplete__Input"
          value={text}
          onChange={handleTextChange}
          placeholder={placeholder}
          onKeyDown={handleKeyDown}
          stretched
        />
        {/* eslint-disable-next-line no-nested-ternary */}
        {isLoading ? (
          <InputGroupAddon>
            <ProgressCircle continuous valueText="Loading..." size="xs" />
          </InputGroupAddon>
        ) : // eslint-disable-next-line no-nested-ternary
        hasError ? (
          <InputGroupAddon>
            <IconWarning title="Warning" />
          </InputGroupAddon>
        ) : hasIcon ? (
          <InputGroupAddon>{icon}</InputGroupAddon>
        ) : null}
      </InputGroup>
    );
  };

  return (
    <Menu
      data-testid="Autocomplete__Menu"
      dense
      noRules
      show={data.length > 0 && !!text}
      menuTrigger={renderInput()}
      stretched
    >
      {data.map((item, idx) => (
        <Menu.Item
          data-testid="Autocomplete__MenuItem"
          key={item.id}
          onClick={handleItemSelected}
          isFocused={currentIndex === idx}
        >
          {renderItem(item)}
        </Menu.Item>
      ))}
    </Menu>
  );
};

Autocomplete.defaultProps = {};

export default Autocomplete;
