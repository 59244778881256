import { LANGUAGES_BY_CODE } from '@sm/locale';
import { createURL } from '@sm/utils';

/**
 *
 * @deprecated - The helpLink language codes are not used for the new help center. Instead directly use the regular language code.
 */
const getHelpLinkLangFromCode = lang => {
  const helpLink = LANGUAGES_BY_CODE[lang] && LANGUAGES_BY_CODE[lang].helplink;
  const defaultHelpLink = LANGUAGES_BY_CODE.en.helplink;

  return helpLink || defaultHelpLink;
};

/**
 * @deprecated - please use getHelpCenterLink instead
 */
const getHelpLink = (lang, options = {}, userData = {}) => {
  const helpLang = getHelpLinkLangFromCode(lang);
  const domain = 'https://help.surveymonkey.com';
  let { articlePath, params } = options;
  const { helpName, featurePath, fragment } = options;
  if (!articlePath) articlePath = 'articles';
  if (!params) params = {};
  let path = `${domain}/${articlePath}/${helpLang}/kb/${helpName}`;
  if (featurePath) {
    path = `${domain}/${featurePath}`;
    params.l = helpLang;
  } else if (!helpName) {
    path = `${domain}/`;
    params.l = helpLang;
  }

  // add user info if available
  const { id, username, packageId, datacenterId } = userData;
  if (id && username && packageId && datacenterId) {
    const userDataString = `p__userid=${id}&p__username=${username}&p__packageid=${packageId}&p__datacenter=${datacenterId}`;
    // TODO: encrypt the `userDataString` before adding to the url! (WEBPLAT-793)
    Object.assign(params, { uid: userDataString });
  }

  let url = createURL(path, params);
  if (fragment) {
    url = `${url}#${fragment}`;
  }
  return url;
};

const getHelpCenterLink = (lang, options = {}) => {
  const { path, params, fragment } = options;

  let helpLang;

  if (LANGUAGES_BY_CODE[lang]?.helpCenterLangCode) {
    helpLang = LANGUAGES_BY_CODE[lang]?.helpCenterLangCode;
  } else if (lang === 'jp') {
    // 'jp' is special because it also maps to the 'ja' language but not in our language mapping
    helpLang = 'ja';
  } else if (lang === 'es-la') {
    // 'es' is in LANGUAGES_BY_CODE, but our help page url is 'es-la' so we need an extra clause here
    helpLang = 'es-la';
  } else {
    helpLang = 'en'; // for all other languages, it will use English
  }

  const domain = 'https://help.surveymonkey.com';

  let fullPath = `${domain}/${helpLang}/`;

  if (path) {
    fullPath += `${path}/`;
  }

  let url = createURL(fullPath, params);

  if (fragment) {
    url = `${url}#${fragment}`;
  }
  return url;
};

export default getHelpLink;
export { getHelpLinkLangFromCode, getHelpCenterLink };
