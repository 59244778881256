import { Component } from 'react';
import PropTypes from 'prop-types';

class OnScroll extends Component {
  state = { x: 0, y: 0 };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    this.setState({
      x: window.scrollX,
      y: window.scrollY,
    });
  };

  render() {
    return this.props.render(this.state.x, this.state.y);
  }
}

OnScroll.propTypes = {
  render: PropTypes.func,
};

OnScroll.defaultProps = {
  render: null,
};

export default OnScroll;
