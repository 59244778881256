import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@wds/list';

export const MenusWithoutSubMenu = ({ children }) => {
  return (
    <List interactive>
      <div className="sm-mobile-menu-anon">{children}</div>
    </List>
  );
};

export default MenusWithoutSubMenu;

MenusWithoutSubMenu.propTypes = {
  children: PropTypes.node.isRequired,
};
