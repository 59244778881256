import React, { useContext } from 'react';
import { t } from '@sm/intl';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import NavSubmenuCopy from '../../copy/NavSubmenuCopy';
import Menu from './components/Menu.js';
import SubMenu from './components/SubMenu';
import SubMenuBody from './components/SubMenuBody.js';
import BottomLinkWrapper from './components/BottomLinkWrapper.js';
import BottomLink from './components/BottomLink.js';
import ColumnTitle from './components/ColumnTitle.js';
import SubMenuItem from './components/SubMenuItem.js';
import Column from './components/Column.js';

import SMHeaderContext from '../../SMHeaderContext';

import {
  surveys,
  surveymonkey,
  enterprise,
  integrations,
  specializedProducts,
  audience,
  cx,
  engage,
  usabilla,
  formsWorkFlows,
  none,
  techValidate,
  apply,
  wufoo,
  getFeedback,
  viewAllProducts,
  addIntegrations,
  productsMenu,
} from '../../config/linkInfo.js';

export const ProductMenu = () => {
  const { lang, isEUDC } = useContext(SMHeaderContext);

  const key = 'Products';
  const subMenuTitle = t(NavSubmenuCopy.PRODUCTS_TITLE);

  const handleMenuClick = () => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: 'review session landing page clicked products',
        interactionType: 'click',
      },
    });
  };

  return (
    <Menu menuKey={key} {...productsMenu()} onClick={handleMenuClick}>
      <SubMenu submenuKey={key} title={subMenuTitle}>
        <SubMenuBody>
          {/* eslint-disable-next-line no-nested-ternary */}
          {lang === 'en' ? (
            <>
              <Column>
                <ColumnTitle {...surveys()} />
                <SubMenuItem {...surveymonkey()} />
                <SubMenuItem {...enterprise()} />
                <SubMenuItem {...integrations()} />
              </Column>
              <Column>
                <ColumnTitle {...specializedProducts()} />
                {!isEUDC ? <SubMenuItem {...audience()} /> : null}
                <SubMenuItem {...cx()} />
                <SubMenuItem {...engage()} />
                <SubMenuItem {...usabilla(lang)} />
              </Column>
              <Column>
                <ColumnTitle {...none()} />
                <SubMenuItem {...techValidate()} />
                <SubMenuItem {...apply()} />
                <SubMenuItem {...wufoo()} />
                <SubMenuItem {...getFeedback()} />
              </Column>
            </>
          ) : lang === 'fr' ? (
            <>
              <Column>
                <ColumnTitle {...surveys()} />
                <SubMenuItem {...surveymonkey(lang)} />
                <SubMenuItem {...audience()} />
              </Column>
              <Column>
                <ColumnTitle {...formsWorkFlows()} />
                <SubMenuItem {...apply()} />
                <SubMenuItem {...usabilla(lang)} />
              </Column>
            </>
          ) : (
            <>
              <Column>
                <SubMenuItem {...surveymonkey()} />
              </Column>
              <Column>
                <SubMenuItem {...audience()} />
                <SubMenuItem {...usabilla(lang)} />
              </Column>
            </>
          )}
        </SubMenuBody>

        <BottomLinkWrapper>
          <BottomLink {...viewAllProducts(lang)} />
          {lang !== 'en' ? <BottomLink {...addIntegrations()} /> : null}
        </BottomLinkWrapper>
      </SubMenu>
    </Menu>
  );
};

export default ProductMenu;
