import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import { showBlog } from '../../../../helpers/languageHelpers';
import SMHeaderContext from '../../SMHeaderContext';
import { NavBarContext } from './NavBarAnonContext';
import { ProductMenu } from './ProductsMenu.js';
import { SolutionsMenu } from './SolutionsMenu.js';
import { ResourcesMenu } from './ResourcesMenu.js';
import { PlansAndPricingMenu } from './PlansAndPricingMenu.js';
import { HelpCenterMenu } from './HelpCenterMenu.js';

const NavBarAnon = ({ color }) => {
  const { lang, isMRX } = useContext(SMHeaderContext);
  const [submenuOpened, setOpen] = useState('');

  const toggleSubmenu = key => {
    if (submenuOpened === key) setOpen('');
    else setOpen(key);
  };
  return (
    <NavBarContext
      color={color}
      submenuOpened={submenuOpened}
      toggleSubmenu={toggleSubmenu}
    >
      <div className="sm-header__nav--anon" data-testid="NavBar-Anon">
        <ProductMenu />
        <SolutionsMenu />
        {showBlog(lang) ? <ResourcesMenu /> : null}
        {!showBlog(lang) ? <HelpCenterMenu /> : null}
        {!isMRX ? <PlansAndPricingMenu /> : null}
      </div>
    </NavBarContext>
  );
};

NavBarAnon.propTypes = {
  color: PropTypes.oneOf(['secondary', 'alt']),
};

NavBarAnon.defaultProps = {
  color: 'secondary',
};

export default NavBarAnon;
