import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { IconArrowRight } from '@wds/icons';

export const BottomLink = ({ title, url, alignment }) => {
  return (
    <Box className="sm-nav-submenu__button" style={{ float: alignment }}>
      <Button size="sm" color="link" variant="text" href={url}>
        <Box element="span" display="flex" gap={2} alignItems="center">
          {title}
          <IconArrowRight />
        </Box>
      </Button>
    </Box>
  );
};

export default BottomLink;

BottomLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  alignment: PropTypes.string.isRequired,
};
