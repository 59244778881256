import React, { useState } from 'react';
import { Menu } from '@wds/menu';
import { Button } from '@wds/button';
import { ButtonGroup } from '@wds/button-group';
import { IconCaretDown } from '@wds/icons';

import { getSiteSupportedLanguages } from '@sm/locale';
import { getLanguageNameFromCode } from '../../../helpers/languageHelpers';

interface LanguageMenuLiProps {
  lang?: string;
}

const LanguageMenuLI = ({ lang = 'en' }: LanguageMenuLiProps) => {
  const [show, setShow] = useState(false);

  const orderedLILanguages = getSiteSupportedLanguages()
    .filter(language => language.code !== lang)
    .sort((a, b) => (a.displayOrder ?? 0) - (b.displayOrder ?? 0));

  const handleChange = () => setShow(s => !s);

  const currentLanguageName = getLanguageNameFromCode(lang);

  const menuTrigger = (
    <div onChange={handleChange}>
      <ButtonGroup>
        <Button size="sm" color="secondary">
          {currentLanguageName}
        </Button>
        <Button size="sm" color="secondary" label="Language dropdown">
          <IconCaretDown title="Language dropdown" />
        </Button>
      </ButtonGroup>
    </div>
  );

  return (
    <Menu dense placement="top-left" show={show} menuTrigger={menuTrigger}>
      {orderedLILanguages.map(langItem => {
        return (
          <Menu.Item
            key={langItem.code}
            isNavigable
            navigationTarget={`/user/language?languageid=${langItem.id}&ut_source=footer`}
          >
            {langItem.displayName}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};

export default LanguageMenuLI;
