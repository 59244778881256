import { t } from '@sm/intl';
import { createURL } from '@sm/utils';
import COPY from './footerCopy';
import { getHelpCenterLink } from '../../../helpers/getHelpLink';

export default function getFooterLinks(languageCode: string) {
  const linkParams = { ut_source: 'captive_footer' };
  return [
    {
      text: t(COPY.help),
      href: getHelpCenterLink(languageCode, {
        params: linkParams,
      }),
    },
    {
      text: t(COPY.termsOfUse),
      href: createURL('/mp/legal/terms-of-use/', linkParams),
    },
    {
      text: t(COPY.privacyPolicy),
      href: createURL('/mp/legal/privacy/', linkParams),
    },
    {
      text: t(COPY.regionalPrivacyPolicy),
      href: createURL(
        '/mp/legal/region-specific-privacy-statement/',
        linkParams
      ),
    },
    {
      text: t(COPY.cookiePolicy),
      href: createURL('/mp/legal/cookies/', linkParams),
    },
  ];
}
