/**
 * Creates a url from base and params
 * @param {string} url
 * @param {Object} [params] Query string params
 * @returns {string} url
 * @memberof module:@sm/utils
 */
export default function createURL(
  url: string,
  params?: Record<string, string>
): string {
  if (!params || typeof params !== 'object' || Object.keys(params).length === 0)
    return url;
  const queryString = Object.keys(params)
    .map(key => {
      return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
    })
    .join('&');
  const queryURL = `${url}?${queryString}`;
  return queryURL;
}
