import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

export const SubMenuLink = ({ subTitle, title, url, openInNewWindow }) => {
  return (
    <List.Item
      key={title}
      isNavigable
      navigationTarget={url}
      {...(openInNewWindow === true && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
    >
      <Box my={4} className="sm-mobile-menu__type-title">
        <Typography color="link" weight="medium">
          {title}
        </Typography>
        <Box mt={1}>
          <Typography component="p" variant="bodySm">
            {subTitle}
          </Typography>
        </Box>
      </Box>
    </List.Item>
  );
};

export default SubMenuLink;

SubMenuLink.propTypes = {
  subTitle: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
  openInNewWindow: PropTypes.bool,
};

SubMenuLink.defaultProps = {
  subTitle: '',
  title: '',
  url: '',
  openInNewWindow: false,
};
