import { createUseStyles } from 'react-jss';
import type { Theme } from '@wds/styles';

type StyleClasses =
  | 'pageContainer'
  | 'pageLogoLink'
  | 'childrenContainer'
  | 'hashMessageId';
type StyleProps = {};

const useStyles = createUseStyles<StyleClasses, StyleProps, Theme>(
  ({ palette, spacing, breakpoints, elevation, type }: Theme) => ({
    pageContainer: {
      backgroundColor: palette.background.main,
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
    },
    hashMessageId: {
      fontFamily: type.fontFamily.base,
      color: '#6B787F',
      fontSize: 12,
      position: 'fixed',
      bottom: 24,
      right: 0,
      left: 0,
      textAlign: 'center',
      margin: 0,
    },
    childrenContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      [`@media (max-width: ${breakpoints.sm}px), (max-height: ${breakpoints.sm}px)`]:
        {
          position: 'static',
        },
    },
    pageLogoLink: {
      display: 'inline-block',
      position: 'relative',
      zIndex: elevation.sky,
      margin: spacing[5],
      width: 200,
      height: 30,
    },
  })
);

export default useStyles;
