import React from 'react';
import Helmet from '../Helmet';
import { StaticContext } from '../StaticContext';

const BasePageHead = () => {
  const { 'client-config': clientConfig, environment } =
    React.useContext(StaticContext);
  // https://prod.smassets.com/assets/static/images/surveymonkey
  const assetsPath =
    clientConfig?.assetsHost && environment?.domain
      ? `https://${clientConfig?.assetsHost}/assets/static/images/${environment?.domain}`
      : '';

  return (
    <Helmet>
      <title>Welcome to SurveyMonkey!</title>
      <meta
        name="keywords"
        content="SurveyMonkey,online surveys,online survey,web surveys, web survey,market research,free,research,stats,statistics,polls,voting,analyze,tool,decisions,planning,strategy,Community,forms,formsite,workgroups,cgi,database"
      />
      <meta
        name="description"
        content="Create and publish online surveys in minutes, and view results graphically and in real time. SurveyMonkey provides free online questionnaire and survey software."
      />
      <meta charset="utf-8" />
      <meta name="msapplication-config" content="none" />
      <meta name="author" content="SurveyMonkey" />
      <link
        rel="apple-touch-icon"
        href={`${assetsPath}/apple-touch-icon-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="57x57"
        href={`${assetsPath}/apple-touch-icon-57x57-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="60x60"
        href={`${assetsPath}/apple-touch-icon-60x60-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="72x72"
        href={`${assetsPath}/apple-touch-icon-72x72-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="76x76"
        href={`${assetsPath}/apple-touch-icon-76x76-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="114x114"
        href={`${assetsPath}/apple-touch-icon-114x114-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="120x120"
        href={`${assetsPath}/apple-touch-icon-120x120-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="144x144"
        href={`${assetsPath}/apple-touch-icon-144x144-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href={`${assetsPath}/apple-touch-icon-152x152-precomposed.png`}
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${assetsPath}/apple-touch-icon-180x180-precomposed.png`}
      />
      <link rel="shortcut icon" href={`${assetsPath}/favicon.ico`} />
    </Helmet>
  );
};

BasePageHead.propTypes = {};

BasePageHead.defaultProps = {};

export default BasePageHead;
