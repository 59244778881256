import { defineMessages } from '@sm/intl';

const COPY = defineMessages({
  ABOUT_SURVEYMONKEY: {
    id: 'getFooterCopy.aboutSurveymonkey',
    defaultMessage: 'About SurveyMonkey',
    description: '[Type: Label][Vis.: med] - About Footer Link',
  },
  CAREERS: {
    id: 'getFooterCopy.careers',
    defaultMessage: 'Careers',
    description: '[Type: Label][Vis.: med] - Careers Footer Link',
  },
  DEVELOPERS: {
    id: 'getFooterCopy.developers',
    defaultMessage: 'Developers',
    description: '[Type: Label][Vis.: med] - Developers Footer Link',
  },
  PRIVACY_NOTICE: {
    id: 'getFooterCopy.privacyNotice',
    defaultMessage: 'Privacy Notice',
    description: '[Type: Label][Vis.: med] - Privacy Notice Footer Link',
  },
  CALIFORNIA_PRIVACY_NOTICE: {
    id: 'getFooterCopy.californiaPrivacyNotice',
    defaultMessage: 'California Privacy Notice',
    description:
      '[Type: Label][Vis.: med] - California Privacy Notice Footer Link',
  },
  EMAIL_OPT_IN: {
    id: 'getFooterCopy.emailOptIn',
    defaultMessage: 'Email Opt-In',
    description: '[Type: Label][Vis.: med] - Email Opt-In Footer Link',
  },
  HELP: {
    id: 'getFooterCopy.help',
    defaultMessage: 'Help',
    description: '[Type: Label][Vis.: med] - Help Footer Link',
  },
  COOKIES_NOTICE: {
    id: 'getFooterCopy.cookiesNotice',
    defaultMessage: 'Cookies Notice',
    description: '[Type: Label][Vis.: med] - Cookies Notice Link',
  },
  OFFICE_LOCATIONS: {
    id: 'getFooterCopy.officeLocations',
    defaultMessage: 'Office Locations',
    description: '[Type: Label][Vis.: med] - Office Locations Link',
  },
  COMMUNITY: {
    id: 'getFooterCopy.community',
    defaultMessage: 'Community:',
    description: '[Type: Label][Vis.: med] - Community Link',
  },
  OUR_BLOG: {
    id: 'getFooterCopy.ourBlog',
    defaultMessage: 'Our Blog',
    description: '[Type: Label][Vis.: med] - Our Blog Link',
  },
  ABOUT_US: {
    id: 'getFooterCopy.aboutUs',
    defaultMessage: 'About Us:',
    description: '[Type: Label][Vis.: med] - About Us Link',
  },
  LEADERSHIP_TEAM: {
    id: 'getFooterCopy.leadershipTeam',
    defaultMessage: 'Leadership Team',
    description: '[Type: Label][Vis.: med] - Leadership Team Link',
  },
  APP_DIRECTORY: {
    id: 'getFooterCopy.appDirectory',
    defaultMessage: 'App Directory',
    description: '[Type: Label][Vis.: med] - App directory Link',
  },
  NEWSROOM: {
    id: 'getFooterCopy.newsroom',
    defaultMessage: 'Newsroom',
    description: '[Type: Label][Vis.: med] - Newsroom Link',
  },
  PAYMENT_METHODS: {
    id: 'getFooterCopy.paymentMethods',
    defaultMessage: 'Payment Methods',
    description: '[Type: Label][Vis.: med] - Payment Methods Link',
  },
  IMPRINT: {
    id: 'getFooterCopy.imprint',
    defaultMessage: 'Imprint',
    description: '[Type: Label][Vis.: med] - Imprint Link',
  },
  SITEMAP: {
    id: 'getFooterCopy.siteMap',
    defaultMessage: 'Sitemap',
    description: '[Type: Label][Vis.: med] - Site Map Link',
  },
  POLICIES: {
    id: 'getFooterCopy.policies',
    defaultMessage: 'Policies:',
    description: '[Type: Label][Vis.: med] - Policies Link',
  },
  TERMS_OF_USE: {
    id: 'getFooterCopy.termsOfUse',
    defaultMessage: 'Terms of Use',
    description: '[Type: Label][Vis.: med] - Terms of Use Link',
  },
  ACCEPTABLE_USES_POLICY: {
    id: 'getFooterCopy.acceptableUsesPolicy',
    defaultMessage: 'Acceptable Uses Policy',
    description: '[Type: Label][Vis.: med] - Acceptable Uses Policy Link',
  },
  SECURITY_STATEMENT: {
    id: 'getFooterCopy.securityStatement',
    defaultMessage: 'Security Statement',
    description: '[Type: Label][Vis.: med] - Security Statement Link',
  },
  GDPR_COMPLIANCE: {
    id: 'getFooterCopy.gdprCompliance',
    defaultMessage: 'GDPR Compliance',
    description: '[Type: Label][Vis.: med] - GDPR Compliance Link',
  },
  ACCESSIBILITY: {
    id: 'getFooterCopy.accessibility',
    defaultMessage: 'Accessibility',
    description: '[Type: Label][Vis.: med] - Accessibility Link',
  },
});

export default COPY;
