import React, { ReactNode, useContext } from 'react';
import classnames from 'classnames';
import useStyles from './useStyles';
import { SMFooterContext } from '../SMFooterContext';
import makeLinkStatic from '../FooterLinks/helpers/makeLinksStatic';

export interface InlineListItemProps {
  href?: string;
  className?: string;
  children?: ReactNode;
  anchorAttributes?: {
    rel: string;
  } | null;
  isTitle?: boolean;
  lastItem?: boolean;
}

const InlineListItem = ({
  children = '',
  className = '',
  isTitle = false,
  href = '',
  anchorAttributes = null,
  lastItem = false,
  ...rest
}: InlineListItemProps) => {
  const { inlineListItem, inlineListItemBullet, inlineListItemAnchor } =
    useStyles({ isTitle });

  const { makeFooterLinksStatic } = useContext(SMFooterContext);

  const showBullet = !isTitle && !lastItem;

  const inlineListItemAnchorMerged = classnames(
    inlineListItemAnchor,
    className
  );

  return (
    <li className={inlineListItem} {...rest}>
      {href ? (
        <a
          className={inlineListItemAnchorMerged}
          href={makeFooterLinksStatic ? makeLinkStatic(href) : href}
          {...anchorAttributes}
        >
          {children}
        </a>
      ) : (
        children
      )}
      {showBullet && <span className={inlineListItemBullet}>&bull;</span>}
    </li>
  );
};

export default InlineListItem;
