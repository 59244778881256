import React, { ReactNode } from 'react';
import { Section, SectionProps } from '@wds/section';
import { Box } from '@wds/box';
import { LogoWithText } from '../Logos';
import Footer from './Footer';
import useStyles from './useStyles';

export type CaptiveWindowProps = Pick<SectionProps, 'children'> & {
  /** Optional content placed between the logo and the card */
  addOnTop?: ReactNode;
  logoPosition?: 'auto' | 'center';
};

const CaptiveWindow: React.FC<CaptiveWindowProps> = ({
  addOnTop = null,
  logoPosition = 'auto',
  ...rest
}) => {
  const {
    captiveWindowPage,
    captiveWindowSection,
    smLogoHeader,
    smLogoHeaderCenter,
    smLogoLogo,
  } = useStyles();

  const headerClassName = [
    smLogoHeader,
    logoPosition === 'center' ? smLogoHeaderCenter : '',
  ].join(' ');

  return (
    <Box className={captiveWindowPage}>
      <Box className={captiveWindowSection}>
        <header className={headerClassName}>
          <LogoWithText className={smLogoLogo} />
        </header>

        {addOnTop}

        <Section {...rest} />
      </Box>
      <Box className={captiveWindowSection}>
        <Footer />
      </Box>
    </Box>
  );
};

export default CaptiveWindow;
