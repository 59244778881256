import React, { useContext } from 'react';
import { List } from '@wds/list';
import { SubMenuLink } from './components/SubMenuLink';
import { SeeMoreLink } from './components/SeeMoreLink';
import SolutionByNeed from './components/SolutionByNeed';
import {
  customerFeedback,
  employeeFeedback,
  marketResearch,
  customerExperience,
  humanResources,
  marketing,
  customerSatisfaction,
  customerLoyalty,
  eventSurveys,
  employeeEngagement,
  jobSatisfaction,
  hrSurveys,
  marketResearchSurveys,
  opinionPolls,
  conceptTesting,
  exploreSurveyTypes,
} from '../../config/linkInfo.js';
import SMHeaderContext from '../../SMHeaderContext';

export const SolutionsMenu = () => {
  const { lang } = useContext(SMHeaderContext);
  return (
    <List interactive transparent noRules>
      {lang === 'en' ? (
        <>
          <SubMenuLink {...customerFeedback()} />
          <SubMenuLink {...employeeFeedback()} />
          <SubMenuLink {...marketResearch()} />
          <SubMenuLink {...customerExperience()} />
          <SubMenuLink {...humanResources()} />
          <SubMenuLink {...marketing()} />
        </>
      ) : (
        <>
          <SubMenuLink {...customerFeedback()} />
          <SubMenuLink {...employeeFeedback()} />
          <SubMenuLink {...marketResearch()} />
        </>
      )}
      <SolutionByNeed {...customerSatisfaction()} />
      <SolutionByNeed {...customerLoyalty()} />
      <SolutionByNeed {...eventSurveys()} />
      <SolutionByNeed {...employeeEngagement()} />
      <SolutionByNeed {...jobSatisfaction()} />
      <SolutionByNeed {...hrSurveys()} />
      <SolutionByNeed {...marketResearchSurveys()} />
      <SolutionByNeed {...opinionPolls()} />
      <SolutionByNeed {...conceptTesting()} />

      <SeeMoreLink {...exploreSurveyTypes(lang)} />
    </List>
  );
};

export default SolutionsMenu;
