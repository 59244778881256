import React from 'react';
import PropTypes from 'prop-types';
import { IconWarning } from '@wds/icons';
import { Section } from '@wds/section';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { Typography } from '@wds/typography';

import { T } from '@sm/intl';
import COPY from './Copy';
import useStyles from './useStyles';
import { WebAssetsThemeWrapper } from '../../theme';

const ErrorCard = props => (
  <WebAssetsThemeWrapper>
    <ErrorCardContent {...props} />
  </WebAssetsThemeWrapper>
);

const ErrorCardContent = ({
  iconOnly,
  iconOptions,
  copyOptions,
  errorCopy,
  refreshCopy,
  refetch,
  ...otherProps
}) => {
  const classes = useStyles();
  const Icon = <IconWarning title="An error has occurred" {...iconOptions} />;
  const getErrorMessage = () => {
    if (!errorCopy) {
      return (
        <>
          {refetch ? (
            <T desc={COPY.troubleLoading} />
          ) : (
            <T desc={COPY.weSlippedError} />
          )}
        </>
      );
    }
    return errorCopy;
  };

  return (
    <>
      {iconOnly ? (
        <Box
          display="flex"
          flexJustify="center"
          flexAlign="center"
          {...otherProps}
        >
          <Typography data-testid="ErrorCard" component="h2">
            {Icon}
          </Typography>
        </Box>
      ) : (
        <Section data-testid="ErrorCard">
          <Box display="flex" flexJustify="center" {...otherProps}>
            <Box display="flex" flexAlign="center" mr={3}>
              {Icon}
            </Box>
            <Box display="flex" flexAlign="center">
              <Typography {...copyOptions}>{getErrorMessage()}</Typography>
            </Box>
            {refetch ? (
              <Box display="flex" flexAlign="center">
                <Button
                  px={1}
                  variant="text"
                  size="sm"
                  onClick={() => refetch()}
                >
                  <div className={classes.refreshText}>
                    <Typography variant="link">
                      {refreshCopy ? (
                        <>{refreshCopy}</>
                      ) : (
                        <T desc={COPY.refresh} />
                      )}
                    </Typography>
                  </div>
                </Button>
              </Box>
            ) : null}
          </Box>
        </Section>
      )}
    </>
  );
};

ErrorCardContent.propTypes = {
  /**
   * Show only the Error Icon without any text.
   */
  iconOnly: PropTypes.bool,
  /**
   * This is a prop overrides object for the IconWarning component.
   */
  iconOptions: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.oneOf(['lg', 'md', 'sm', 'xl']),
  }),
  /**
   * Refetch function; Shows a button to refetch data.
   */
  refetch: PropTypes.func,
  /**
   * This is a prop overrides object for the Typography component.
   */
  copyOptions: PropTypes.shape({
    variant: PropTypes.string,
  }),
  /**
   * Error Message.
   */
  errorCopy: PropTypes.string,
  /**
   * Refetch Button Message.
   */
  refreshCopy: PropTypes.string,
};

ErrorCardContent.defaultProps = {
  iconOnly: false,
  iconOptions: {
    color: null,
    size: 'lg',
  },
  refetch: undefined,
  copyOptions: {
    variant: 'sectionTitle',
  },
  errorCopy: undefined,
  refreshCopy: undefined,
};

export default ErrorCard;
