import { withWebAssetsTheme } from '../../theme';
import { withClientErrorPage } from '../ClientError';
import FourOhFourError, { FourOhFourErrorProps } from './FourOhFourError';

/* A variant when showing 404 error as a page */
export default withWebAssetsTheme(
  withClientErrorPage<FourOhFourErrorProps>(FourOhFourError, {
    titularElementTag: 'h1', // set default to H1
    showLinks: true,
    errorCode: 404,
  })
);
