import React from 'react';
import classnames from 'classnames';
import { useTheme } from 'react-jss';
import { WrenchTheme } from '@wds/styles';

import { LogoProps } from './Logo';
import useStyles from './useStyles';
import { WebAssetsThemeWrapper } from '../../theme';

const LogoWithText = (props: LogoProps) => (
  <WebAssetsThemeWrapper>
    <LogoWithTextContent {...props} />
  </WebAssetsThemeWrapper>
);

const LogoWithTextContent = ({
  color = 'primary',
  className = '',
}: LogoProps) => {
  const theme: typeof WrenchTheme = useTheme();
  const { logo } = useStyles({ color, theme });

  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 505.84 75.76"
      className={classnames(logo, className)}
    >
      <title>{`SurveyMonkey logo with text in ${color}`}</title>
      <g id="_Group_" data-name="&lt;Group&gt;">
        <path
          id="_Compound_Path_"
          data-name="&lt;Compound Path&gt;"
          d="M144.72,87.19l4.12-4.9c3.73,3.23,7.45,5.06,12.29,5.06,4.23,0,6.9-1.95,6.9-4.9v-.11c0-2.78-1.56-4.28-8.79-6-8.29-2-13-4.45-13-11.63v-.11c0-6.67,5.56-11.29,13.3-11.29a21.58,21.58,0,0,1,14.13,4.9L170,63.44c-3.5-2.61-7-4-10.57-4-4,0-6.34,2.06-6.34,4.62v.11c0,3,1.78,4.34,9.23,6.12,8.23,2,12.52,5,12.52,11.4v.11c0,7.29-5.73,11.63-13.91,11.63A24,24,0,0,1,144.72,87.19Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_2"
          data-name="&lt;Compound Path&gt;"
          d="M179.36,82.19V63.5h6.73V80.19c0,4.56,2.28,7.18,6.29,7.18S199,84.64,199,80.07V63.5h6.73V92.87H199v-3a11.58,11.58,0,0,1-9.18,3.64C183.2,93.48,179.36,89,179.36,82.19Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_3"
          data-name="&lt;Compound Path&gt;"
          d="M212.93,63.5h6.73v4.9c1.83-4.4,5.23-5.68,10.4-5.45v6h-.39c-5.9,0-10,3.94-10,12.78V92.87h-6.73Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_4"
          data-name="&lt;Compound Path&gt;"
          d="M232.46,63.5h7.23l7.9,20.44,8-20.44h7.06l-12,29.6h-6.06Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_5"
          data-name="&lt;Compound Path&gt;"
          d="M262.66,78.29v-.11c0-8.4,6-15.3,14.35-15.3,9.35,0,14.08,7.34,14.08,15.8,0,.61-.06,1.22-.11,1.89H269.39c.72,4.78,4.12,7.46,8.46,7.46a10.67,10.67,0,0,0,8-3.51L289.75,88a14.86,14.86,0,0,1-12,5.5C269.23,93.53,262.66,87.36,262.66,78.29Zm21.75-2.17c-.44-4.34-3-7.73-7.45-7.73-4.12,0-7,3.17-7.62,7.73Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_6"
          data-name="&lt;Compound Path&gt;"
          d="M326.76,53.93h7.29L345.9,73.79l11.85-19.86H365V92.87H358.2V64.94L345.9,84.75h-.22L333.5,65.05V92.87h-6.73Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_7"
          data-name="&lt;Compound Path&gt;"
          d="M369.86,78.35v-.11a15.66,15.66,0,0,1,31.32-.11v.11a15.36,15.36,0,0,1-15.74,15.3A15.19,15.19,0,0,1,369.86,78.35Zm24.59,0v-.11c0-5.17-3.73-9.46-9-9.46s-8.85,4.23-8.85,9.35v.11c0,5.12,3.73,9.46,9,9.46C391,87.7,394.45,83.41,394.45,78.35Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_8"
          data-name="&lt;Compound Path&gt;"
          d="M438.83,52.26h6.73V76.51l12.18-13h8.18l-11.68,12,12.07,17.41h-7.79l-8.85-12.68-4.12,4.28v8.4h-6.73Z"
          transform="translate(-31.32 -31.32)"
        />
        <path
          id="_Compound_Path_9"
          data-name="&lt;Compound Path&gt;"
          d="M465.77,78.29v-.11c0-8.4,6-15.3,14.35-15.3,9.35,0,14.08,7.34,14.08,15.8,0,.61-.06,1.22-.11,1.89H472.5c.72,4.78,4.12,7.46,8.46,7.46a10.66,10.66,0,0,0,8-3.51L492.86,88a14.86,14.86,0,0,1-12,5.5C472.33,93.53,465.77,87.36,465.77,78.29Zm21.75-2.17c-.44-4.34-3-7.73-7.45-7.73-4.12,0-7,3.17-7.62,7.73Z"
          transform="translate(-31.32 -31.32)"
        />
        <polygon
          id="_Path_"
          data-name="&lt;Path&gt;"
          points="283.41 32.18 275.28 52.21 267.21 32.18 260.2 32.18 271.77 60.87 265.73 75.75 272.74 75.75 290.42 32.18 283.41 32.18"
        />
        <path
          id="_Compound_Path_10"
          data-name="&lt;Compound Path&gt;"
          d="M532.33,64a4.11,4.11,0,0,0-2,.52,3.79,3.79,0,0,0-1.52,1.49,4.24,4.24,0,0,0-.54,2,4.07,4.07,0,0,0,.53,2,3.79,3.79,0,0,0,1.5,1.49,4,4,0,0,0,4,0,3.78,3.78,0,0,0,1.49-1.49,4.07,4.07,0,0,0,0-4,3.69,3.69,0,0,0-1.51-1.49,4.16,4.16,0,0,0-2-.52m0-.8a4.9,4.9,0,0,1,2.37.63,4.35,4.35,0,0,1,1.81,1.78,4.8,4.8,0,0,1,0,4.82,4.56,4.56,0,0,1-1.79,1.79,4.87,4.87,0,0,1-2.4.63,5,5,0,0,1-2.41-.63,4.55,4.55,0,0,1-1.79-1.79,4.9,4.9,0,0,1-.63-2.39,4.79,4.79,0,0,1,.65-2.42,4.4,4.4,0,0,1,1.8-1.78,5,5,0,0,1,2.38-.63"
          transform="translate(-31.32 -31.32)"
        />
        <g id="_Group_2" data-name="&lt;Group&gt;">
          <path
            id="_Compound_Path_11"
            data-name="&lt;Compound Path&gt;"
            d="M530.28,65.36h2.41a2.18,2.18,0,0,1,1.56.54,1.64,1.64,0,0,1,.45,1.17v0a1.62,1.62,0,0,1-1.28,1.64l1.46,2h-1.13l-1.33-1.89h-1.19v1.89h-1ZM532.62,68c.68,0,1.11-.36,1.11-.9v0c0-.58-.42-.9-1.12-.9h-1.38V68Z"
            transform="translate(-31.32 -31.32)"
          />
        </g>
        <polygon
          id="_Path_2"
          data-name="&lt;Path&gt;"
          points="486.52 32.18 478.39 52.21 470.31 32.18 463.31 32.18 474.88 60.87 468.84 75.75 475.85 75.75 493.53 32.18 486.52 32.18"
        />
        <path
          id="_Compound_Path_12"
          data-name="&lt;Compound Path&gt;"
          d="M432,74.18V92.87h-6.73V76.18C425.24,71.62,423,69,419,69s-6.62,2.73-6.62,7.29V92.87H405.6V63.5h6.73v3a11.58,11.58,0,0,1,9.18-3.64C428.13,62.88,432,67.33,432,74.18Z"
          transform="translate(-31.32 -31.32)"
        />
      </g>
      <path
        id="_Compound_Path_13"
        data-name="&lt;Compound Path&gt;"
        d="M118.49,70.66a11.78,11.78,0,0,0-2.79.35,36,36,0,0,0-28.5-26.56c-.62-.11-1.18-.19-1.81-.27h0c.1-3.4.26-7.33,5.27-10.87l-.79-2s-9.75,3-10.88,11.37c-.49-2.27-5-5.11-7.28-5.65l-1.12,1.81a7.84,7.84,0,0,1,3.71,5.61h0A36,36,0,0,0,45.79,71a11.68,11.68,0,1,0,1.47,22.19,35.8,35.8,0,0,0,3.79,7.06l9.69-6.53-.08-.11a23.87,23.87,0,0,1-4.39-13c-.29-5.32,1-10.61,4.41-13.72,7-6,14.64-3.25,19.42,2.47h1.29c4.78-5.72,12.41-8.44,19.42-2.47,3.4,3.11,4.7,8.4,4.41,13.72a23.87,23.87,0,0,1-4.39,13l-.08.11,9.69,6.53a35.8,35.8,0,0,0,3.79-7.06,11.67,11.67,0,1,0,4.27-22.54ZM42.77,85.78a3.45,3.45,0,0,1,0-6.89,3.47,3.47,0,0,1,1.93.6,52.34,52.34,0,0,0,.37,5.41A3.45,3.45,0,0,1,42.77,85.78Zm75.95,0a3.45,3.45,0,0,1-2.3-.88,52.34,52.34,0,0,0,.37-5.41,3.44,3.44,0,0,1,5.37,2.86A3.44,3.44,0,0,1,118.71,85.78Z"
        transform="translate(-31.32 -31.32)"
      />
    </svg>
  );
};

export default LogoWithText;
