import React, { useEffect, useRef } from 'react';

const TrustedShop = () => {
  const trustedSpan = useRef<HTMLSpanElement>(null);

  useEffect(() => {
    const tsID = 'X8F338485E616D91F6D5515EA0347B70B';
    const scriptSource = `//widgets.trustedshops.com/js/${tsID}.js`;
    const script = document.createElement('script');
    const type = 'text/javascript';
    const charset = 'utf-8';
    script.type = type;
    script.charset = charset;
    script.src = scriptSource;

    const tsScript = document.getElementsByTagName('script')[0];
    if (!tsScript.parentNode)
      throw Error('Trusted Shop script does not exist!');
    tsScript.parentNode.insertBefore(script, tsScript);

    const tsConfigScript = document.createElement('script');

    tsConfigScript.type = type;
    tsConfigScript.innerHTML = `
      (function () {
        var _tsid = '${tsID}';
        _tsConfig = {
            'yOffset': '0', /* offset from page bottom */
            'variant': 'custom', /* text, default, small, reviews, custom, custom_reviews */
            'customElementId': 'TrustedShops', /* required for variants custom and custom_reviews */
            'trustcardDirection': '', /* for custom variants: topRight, topLeft, bottomRight, bottomLeft */
            'customBadgeWidth': '40', /* for custom variants: 40 - 90 (in pixels) */
            'customBadgeHeight': '40', /* for custom variants: 40 - 90 (in pixels) */
            'disableResponsive': 'false', /* deactivate responsive behaviour */
            'disableTrustbadge': 'false', /* deactivate trustbadge */
            'trustCardTrigger': 'mouseenter' /* set to 'click' if you want the trustcard to be opened on click instead */
        };
      })();`;
    if (!trustedSpan.current) throw Error('trustedSpan is not assigned');
    trustedSpan.current.appendChild(tsConfigScript);
  }, []);

  return <span id="TrustedShops" data-language="en" ref={trustedSpan} />;
};

export default TrustedShop;
