import React, { useContext } from 'react';
import './footer.scss';
import { Box } from '@wds/box';
import getFooterLinks from './getFooterLinks';
import { StaticContext } from '../../StaticContext';

const Footer = () => {
  const {
    environment: { languageCode },
  } = useContext(StaticContext);
  return (
    <Box element="footer" display="flex" className="sm-captive-footer">
      <Box p={2} m={3}>
        {getFooterLinks(languageCode).map(linkData => (
          <a
            key={linkData.href}
            className="sm-captive-footer__link"
            href={linkData.href}
            rel="noopener noreferrer"
          >
            {linkData.text}
          </a>
        ))}
        {/* Onetrust pipes in the text content here */}
        {/* eslint-disable-next-line jsx-a11y/anchor-has-content */}
        <a
          href="#cookiePreferences"
          className="ot-sdk-show-settings"
          data-testid="CaptiveFooterLinks__cookiePreferences"
        />
      </Box>
    </Box>
  );
};
export default Footer;
