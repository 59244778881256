import React, { useEffect, useMemo, useState } from 'react';
import { defineMessages, T, t } from '@sm/intl';
import PropTypes from 'prop-types';
import { IconLock } from '@wds/icons';
import { Modal } from '@wds/modal';
import { Button } from '@wds/button';
import { Grid } from '@wds/grid';
import { useTheme } from 'react-jss';

import SessionCtrl from './SessionCtrl';
import useStyles from './useStyles';
import { WebAssetsThemeWrapper } from '../../theme';

const COPY = defineMessages({
  CONTINUE_SESSION: {
    id: 'SessionTimeoutModal.ContinueSession',
    defaultMessage: 'CONTINUE SESSION',
    description:
      '[Type: Button][Vis.: med] - CTA for the user to continue their browsing session and not be logged out automatically.',
  },
  TIME_LEFT_MSG: {
    id: 'SessionTimeoutModal.TimeLeftMessage',
    defaultMessage:
      'After {minutesLeft, plural, one { 1 minute } other {{ minutesLeft } minutes}} of inactivity, we sign you out to keep your data safe. Your session will expire soon, unless you click <i>Continue Session</i>',
    description:
      '[Type: Paragraph][Vis.: med] - Message given to the user informing them of the time left before automatic expiration.',
  },
  SESSION_HEADER: {
    id: 'SessionTimeoutModal.TimeLeftHeader',
    defaultMessage: 'Do you want to continue your session?',
    description:
      '[Type: Header][Vis.: med] - Modal header for the prompt asking the user if they want to continue their browsing session.',
  },
});

const SessionTimeoutModal = props => (
  <WebAssetsThemeWrapper>
    <SessionTimeoutModalContent {...props} />
  </WebAssetsThemeWrapper>
);

const SessionTimeoutModalContent = ({ user }) => {
  const [show, setShow] = useState(false);
  const timeLeft = 30; // see WEBPLAT-4491; we're not doing a time countdown anymore
  const theme = useTheme();
  const { iconBackground } = useStyles({ theme });

  const onReminder = () => {
    setShow(true);
  };
  const onClose = () => {
    SessionCtrl.continueSession(() => {
      setShow(false);
    });
  };

  const timeout = useMemo(() => {
    return (
      (user?.hipaa?.isHipaaEnabled && 30 * 60) ||
      (user?.group?.sessionTimeout &&
        parseInt(user.group.sessionTimeout, 10) * 60)
    );
  }, [user?.hipaa?.isHipaaEnabled, user?.group?.sessionTimeout]);

  useEffect(() => {
    if (timeout !== undefined) {
      // it could be 0
      SessionCtrl.init({ timeout, reminderCb: onReminder });
    }
    return function cleanup() {
      SessionCtrl.removeCallback();
    };
  }, [timeout]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      className="sm-session-timeout-modal-container"
    >
      <Modal.Header header={t(COPY.SESSION_HEADER)} />
      <Modal.Body>
        <Grid noBleeds noGutters>
          <Grid.Item xs={3}>
            <div className={iconBackground}>
              <IconLock title="SessionTimeoutIcon" color="light" size="xl" />
            </div>
          </Grid.Item>
          <Grid.Item xs={9}>
            <T
              desc={COPY.TIME_LEFT_MSG}
              values={{ minutesLeft: timeLeft }}
              html
            />
          </Grid.Item>
        </Grid>
      </Modal.Body>
      <Modal.Footer align="right">
        <Button color="secondary" onClick={onClose}>
          <T desc={COPY.CONTINUE_SESSION} />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

SessionTimeoutModalContent.propTypes = {
  user: PropTypes.shape({
    hipaa: PropTypes.shape({
      isHipaaEnabled: PropTypes.bool,
    }),
    group: PropTypes.shape({
      sessionTimeout: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default SessionTimeoutModal;
