import React, { useContext } from 'react';
import { List } from '@wds/list';
import { SubMenuLink } from './components/SubMenuLink';
import { SeeMoreLink } from './components/SeeMoreLink';
import {
  surveymonkey,
  enterprise,
  integrations,
  audience,
  cx,
  engage,
  usabilla,
  techValidate,
  apply,
  wufoo,
  getFeedback,
  viewAllProducts,
  addIntegrations,
} from '../../config/linkInfo.js';
import SMHeaderContext from '../../SMHeaderContext';

export const ProductsMenu = () => {
  const { lang, isEUDC } = useContext(SMHeaderContext);
  return (
    <List interactive transparent noRules>
      {/* eslint-disable-next-line no-nested-ternary */}
      {lang === 'en' ? (
        <>
          <SubMenuLink {...surveymonkey()} />
          <SubMenuLink {...enterprise()} />
          <SubMenuLink {...integrations()} />
          {!isEUDC ? <SubMenuLink {...audience()} /> : null}
          <SubMenuLink {...cx()} />
          <SubMenuLink {...engage()} />
          <SubMenuLink {...usabilla(lang)} />
          <SubMenuLink {...techValidate()} />
          <SubMenuLink {...apply()} />
          <SubMenuLink {...wufoo()} />
          <SubMenuLink {...getFeedback()} />
        </>
      ) : lang === 'fr' ? (
        <>
          <SubMenuLink {...surveymonkey(lang)} />
          <SubMenuLink {...audience()} />
          <SubMenuLink {...apply()} />
          <SubMenuLink {...usabilla(lang)} />
        </>
      ) : (
        <>
          <SubMenuLink {...surveymonkey()} />
          <SubMenuLink {...audience()} />
          <SubMenuLink {...usabilla(lang)} />
        </>
      )}
      <SeeMoreLink {...viewAllProducts(lang)} />
      {lang !== 'en' ? <SeeMoreLink {...addIntegrations()} /> : null}
    </List>
  );
};

export default ProductsMenu;
