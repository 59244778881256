import fetch from 'isomorphic-fetch';
import { getClientEnvironmentDetails } from '@sm/utils';
import { clientErrorHandler } from '../..';

const { isBrowser } = getClientEnvironmentDetails();

// Small in-memory only localstorage
function inMemoryLocalStorage() {
  return {
    _data: {},
    setItem(id, val) {
      let dataId = this._data[id];
      dataId = String(val);
      return dataId;
    },
    getItem(id) {
      const dataID = Object.prototype.hasOwnProperty.call(this._data, id)
        ? this._data[id]
        : undefined;
      return dataID;
    },
    removeItem(id) {
      const deleteDataId = delete this._data[id];
      return deleteDataId;
    },
    clear() {
      let data = this._data;
      data = {};
      return data;
    },
  };
}
if (isBrowser && !('localStorage' in window)) {
  window.localStorage = inMemoryLocalStorage();
}

let Storage;

const LAST_BACKEND_ACTIVITY_KEY = 'SessionCtrl.lba';
const LAST_FRONTEND_ACTIVITY_KEY = 'SessionCtrl.lfa';

const RUN_EVERY = 1000 * 30; // Every 30 seconds

const state = {
  initialized: false,
  timeoutInterval: 0, // seconds
  intervalInstanceId: null, // setInterval instance ID
  reminderCb: null, // function passed during init

  // settings
  minimumDeltaForFlight: 5, // seconds
  minimumDeltaForBackendExtension: 5 * 60, // 5 minutes
  minimumDeltaToShowReminder: 5 * 60, // 5 minutes
};

function now() {
  return parseInt(new Date().getTime() / 1000, 10);
}

function getTimeToBackendTimeout() {
  const lastBackendActivity = Storage.getItem(LAST_BACKEND_ACTIVITY_KEY);
  const n = now();
  return state.timeoutInterval - (n - lastBackendActivity);
}

function getTimeToFrontendTimeout() {
  const lastFrontendActivity = Storage.getItem(LAST_FRONTEND_ACTIVITY_KEY);
  const n = now();
  return state.timeoutInterval - (n - lastFrontendActivity);
}

function logout() {
  if (state.timeoutInterval === 0) {
    window.location.assign(`/user/sign-out/?timeout=true`);
  } else {
    window.location.assign(
      `/user/sign-out/?timeout=${Math.ceil(state.timeoutInterval / 60)}`
    );
  }
}

function updateBackendActivity() {
  return Storage.setItem(LAST_BACKEND_ACTIVITY_KEY, now());
}

async function extendBackend(callback) {
  try {
    const resp = await fetch('/user/session/', {
      method: 'GET',
      credentials: 'include',
    });
    if (/sign-in/.test(resp.url)) {
      logout();
    } else {
      updateBackendActivity();
      return callback && callback();
    }
  } catch (e) {
    clientErrorHandler.logError(e);
  }
  return null;
}

function updateFrontendActivity() {
  return Storage.setItem(LAST_FRONTEND_ACTIVITY_KEY, now());
}

function verifyLocalStorage() {
  const testKey = 'value_that_is_not_expected_to_be_there';
  Storage = localStorage;
  try {
    Storage.setItem(testKey, 'foo');
    if (Storage.getItem(testKey) !== 'foo') {
      throw new Error('Unable to find match in localStorage');
    }
    Storage.removeItem(testKey);
  } catch {
    Storage = inMemoryLocalStorage();
  }
}

function bindActivityEvents() {
  document.addEventListener('mousedown', updateFrontendActivity, false);
  document.addEventListener('touchstart', updateFrontendActivity, false);
  document.addEventListener('keydown', updateFrontendActivity, false);
}

function timer() {
  const timeToFrontendTimeout = getTimeToFrontendTimeout();
  const timeToBackendTimeout = getTimeToBackendTimeout();
  if (timeToFrontendTimeout < state.minimumDeltaForFlight) {
    return logout();
  }

  // If we have been active in the frontend only but not done any AJAX request
  // then we want to extend the backend
  if (
    timeToBackendTimeout < state.minimumDeltaForBackendExtension &&
    timeToFrontendTimeout > state.minimumDeltaToShowReminder
  ) {
    extendBackend();
  } else if (timeToFrontendTimeout < state.minimumDeltaToShowReminder) {
    state.reminderCb(timeToFrontendTimeout);
  }
  return null;
}

function startTimer() {
  state.intervalInstanceId = window.setInterval(timer, RUN_EVERY);
}

// Make it a singleton
const instance = {
  init({ timeout, reminderCb }) {
    if (!isBrowser) {
      // eslint-disable-next-line
      console.warn('SessionCtrl init() method was called without a window!');
      return;
    }
    state.timeoutInterval = timeout; // timeout in seconds
    state.reminderCb = reminderCb;
    if (timeout && !state.initialized) {
      verifyLocalStorage();
      bindActivityEvents();
      updateBackendActivity();
      updateFrontendActivity();
      startTimer();
      state.initialized = true;
    }
  },

  removeCallback() {
    delete state.reminderCb;
  },

  continueSession(callback) {
    updateFrontendActivity();
    extendBackend(callback);
  },
};

Object.freeze(instance);

export default instance;
