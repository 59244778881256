import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@wds/grid';
import SMHeaderContext from '../../../SMHeaderContext';

export const SubMenuBody = ({ children }) => {
  const { lang } = useContext(SMHeaderContext);
  return <Grid noBleeds={lang === 'en'}>{children}</Grid>;
};

SubMenuBody.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SubMenuBody;
