import React, { ReactNode } from 'react';
import useStyles from './useStyles';

export interface InlineListProps {
  noMargin?: boolean;
  children?: ReactNode;
}

const InlineList = ({
  children = '',
  noMargin = false,
  ...rest
}: InlineListProps) => {
  const { inlineList } = useStyles({ noMargin });

  return (
    <ul className={inlineList} {...rest}>
      {children}
    </ul>
  );
};

export default InlineList;
