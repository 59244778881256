import { withWebAssetsTheme } from '../../theme';
import { withClientErrorPage } from '../ClientError';
import FourHundredError, { FourHundredErrorProps } from './FourHundredError';

export default withWebAssetsTheme(
  withClientErrorPage<FourHundredErrorProps>(FourHundredError, {
    titularElementTag: 'h1', // set default to H1
    errorCode: 400,
  })
);
