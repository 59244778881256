import { LANGUAGES } from '@sm/locale';

export const isEnglishLang = lang => lang === 'en';
export const hasCX = lang => isEnglishLang(lang);
export const hasEngage = lang => isEnglishLang(lang);
export const hasTechValidate = lang => isEnglishLang(lang);
export const hasWufoo = lang => ['en', 'es'].includes(lang);
export const hasApply = lang => ['en', 'fr'].includes(lang);
export const hasUsabilla = lang =>
  ['en', 'fr', 'sv', 'de', 'nl'].includes(lang);
export const hasGetFeedback = lang => isEnglishLang(lang);

export const getWufooURL = lang => {
  const url = 'https://www.wufoo.';
  if (lang === 'en-gb') return `${url}co.uk/`;
  if (lang === 'es') return `${url}mx/`;
  return `${url}com/`;
};

/**
 * Find the correct marketing link for Usabilla
 * @param lang
 * @return {String}
 */
export const usabillaLink = lang => {
  return (
    {
      de: 'https://usabilla.com/de/',
      sv: 'https://usabilla.com/sv/',
      fr: 'https://usabilla.com/fr/',
    }[lang] || 'https://usabilla.com/'
  );
};

export const showNewsRoom = (lang, country) =>
  lang === 'en' && country === 'US';
export const showDachPaymentLinks = (lang, country) =>
  lang === 'de' && ['DE', 'AT', 'CH'].includes(country);
export const showImprintLink = (lang, country) =>
  lang === 'en' && country !== 'US';
export const showBlog = lang =>
  ['de', 'en', 'en-gb', 'es', 'fr', 'nl', 'pt-br'].includes(lang);

export const getLanguageNameFromCode = langCode => {
  const currentLangList = LANGUAGES.filter(
    language => language.code === langCode
  );
  if (currentLangList.length === 0) return 'unknown';
  // pick the first one gb / en issue TODO
  return currentLangList[0].displayName || 'unknown';
};
