import { defineMessages } from '@sm/intl';

const COPY = defineMessages({
  weSlippedError: {
    id: 'Copy.ErrorCardWeSlippedError',
    defaultMessage: 'It looks like we slipped!',
    description: '[Type: Label][Vis.: med] - It looks like we slipped!',
  },
  refresh: {
    id: 'Copy.ErrorCardRefresh',
    defaultMessage: 'Refresh',
    description: '[Type: Button][Vis.: high] - Refresh',
  },
  troubleLoading: {
    id: 'Copy.ErrorCardTroubleLoading',
    defaultMessage: 'We had trouble loading your data.',
    description:
      '[Type: Label][Vis.: high] - We had trouble loading your data.',
  },
});

export default COPY;
