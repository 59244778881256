export enum CountryCodes {
  AE = 'AE',
  AR = 'AR',
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  CH = 'CH',
  LI = 'LI',
  DK = 'DK',
  FO = 'FO',
  GL = 'GL',
  AD = 'AD',
  AL = 'AL',
  AT = 'AT',
  AX = 'AX',
  BA = 'BA',
  BE = 'BE',
  BG = 'BG',
  BY = 'BY',
  CY = 'CY',
  CZ = 'CZ',
  DE = 'DE',
  EE = 'EE',
  ES = 'ES',
  FI = 'FI',
  FR = 'FR',
  GF = 'GF',
  GP = 'GP',
  GR = 'GR',
  HR = 'HR',
  HU = 'HU',
  IE = 'IE',
  IS = 'IS',
  IT = 'IT',
  LT = 'LT',
  LU = 'LU',
  LV = 'LV',
  MC = 'MC',
  MD = 'MD',
  ME = 'ME',
  MF = 'MF',
  MK = 'MK',
  MQ = 'MQ',
  MT = 'MT',
  NL = 'NL',
  PL = 'PL',
  PM = 'PM',
  PT = 'PT',
  RE = 'RE',
  RO = 'RO',
  RS = 'RS',
  RU = 'RU',
  SI = 'SI',
  SK = 'SK',
  SM = 'SM',
  TF = 'TF',
  TR = 'TR',
  UA = 'UA',
  VA = 'VA',
  YT = 'YT',
  GB = 'GB',
  GG = 'GG',
  GI = 'GI',
  GS = 'GS',
  IM = 'IM',
  JE = 'JE',
  HK = 'HK',
  IN = 'IN',
  JP = 'JP',
  KR = 'KR',
  MX = 'MX',
  NO = 'NO',
  NZ = 'NZ',
  SE = 'SE',
  SG = 'SG',
  TW = 'TW',
  AF = 'AF',
  AG = 'AG',
  AI = 'AI',
  AM = 'AM',
  AN = 'AN',
  AO = 'AO',
  AQ = 'AQ',
  AS = 'AS',
  AW = 'AW',
  AZ = 'AZ',
  BB = 'BB',
  BD = 'BD',
  BF = 'BF',
  BH = 'BH',
  BI = 'BI',
  BJ = 'BJ',
  BL = 'BL',
  BM = 'BM',
  BN = 'BN',
  BO = 'BO',
  BS = 'BS',
  BT = 'BT',
  BV = 'BV',
  BW = 'BW',
  BZ = 'BZ',
  CC = 'CC',
  CD = 'CD',
  CF = 'CF',
  CG = 'CG',
  CI = 'CI',
  CK = 'CK',
  CL = 'CL',
  CM = 'CM',
  CN = 'CN',
  CO = 'CO',
  CR = 'CR',
  CV = 'CV',
  CX = 'CX',
  DJ = 'DJ',
  DM = 'DM',
  DO = 'DO',
  DZ = 'DZ',
  EC = 'EC',
  EG = 'EG',
  EH = 'EH',
  ER = 'ER',
  ET = 'ET',
  FJ = 'FJ',
  FK = 'FK',
  FM = 'FM',
  GA = 'GA',
  GD = 'GD',
  GE = 'GE',
  GH = 'GH',
  GM = 'GM',
  GN = 'GN',
  GQ = 'GQ',
  GT = 'GT',
  GU = 'GU',
  GW = 'GW',
  GY = 'GY',
  HM = 'HM',
  HN = 'HN',
  HT = 'HT',
  ID = 'ID',
  IL = 'IL',
  IO = 'IO',
  IQ = 'IQ',
  JM = 'JM',
  JO = 'JO',
  KE = 'KE',
  KG = 'KG',
  KH = 'KH',
  KI = 'KI',
  KM = 'KM',
  KN = 'KN',
  KP = 'KP',
  KW = 'KW',
  KY = 'KY',
  KZ = 'KZ',
  LA = 'LA',
  LB = 'LB',
  LC = 'LC',
  LK = 'LK',
  LR = 'LR',
  LS = 'LS',
  LY = 'LY',
  MA = 'MA',
  MG = 'MG',
  MH = 'MH',
  ML = 'ML',
  MM = 'MM',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MR = 'MR',
  MS = 'MS',
  MU = 'MU',
  MV = 'MV',
  MW = 'MW',
  MY = 'MY',
  MZ = 'MZ',
  NA = 'NA',
  NC = 'NC',
  NE = 'NE',
  NF = 'NF',
  NG = 'NG',
  NI = 'NI',
  NP = 'NP',
  NR = 'NR',
  NU = 'NU',
  OM = 'OM',
  PA = 'PA',
  PE = 'PE',
  PF = 'PF',
  PG = 'PG',
  PH = 'PH',
  PK = 'PK',
  PN = 'PN',
  PR = 'PR',
  PS = 'PS',
  PW = 'PW',
  PY = 'PY',
  QA = 'QA',
  RW = 'RW',
  SA = 'SA',
  SB = 'SB',
  SC = 'SC',
  SH = 'SH',
  SJ = 'SJ',
  SL = 'SL',
  SN = 'SN',
  SO = 'SO',
  SR = 'SR',
  ST = 'ST',
  SV = 'SV',
  SY = 'SY',
  SZ = 'SZ',
  TC = 'TC',
  TD = 'TD',
  TG = 'TG',
  TH = 'TH',
  TJ = 'TJ',
  TK = 'TK',
  TL = 'TL',
  TM = 'TM',
  TN = 'TN',
  TO = 'TO',
  TT = 'TT',
  TV = 'TV',
  TZ = 'TZ',
  UG = 'UG',
  UM = 'UM',
  US = 'US',
  UY = 'UY',
  UZ = 'UZ',
  VC = 'VC',
  VE = 'VE',
  VG = 'VG',
  VI = 'VI',
  VN = 'VN',
  VU = 'VU',
  WF = 'WF',
  WS = 'WS',
  YE = 'YE',
  ZM = 'ZM',
  ZW = 'ZW',
  ZA = 'ZA',
}

export const GDPRCountryCodes = [
  CountryCodes.AT,
  CountryCodes.BE,
  CountryCodes.BG,
  CountryCodes.CH,
  CountryCodes.CY,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.DK,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GB,
  CountryCodes.GR,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.IE,
  CountryCodes.IM,
  CountryCodes.IT,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MC,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.PL,
  CountryCodes.PT,
  CountryCodes.RO,
  CountryCodes.SE,
  CountryCodes.SI,
  CountryCodes.SK,
  CountryCodes.IS,
  CountryCodes.LI,
  CountryCodes.NO,
];
export const DASHCountryCodes = [
  CountryCodes.DE,
  CountryCodes.AT,
  CountryCodes.CH,
];
export const GBCountryCodes = [
  CountryCodes.GB,
  CountryCodes.GG,
  CountryCodes.GI,
  CountryCodes.GS,
  CountryCodes.IM,
  CountryCodes.JE,
];
export const USTerritoryCountryCodes = [
  CountryCodes.GU,
  CountryCodes.PR,
  CountryCodes.UM,
  CountryCodes.US,
  CountryCodes.VI,
];
export const EUCountryCodes = [
  CountryCodes.AT,
  CountryCodes.BE,
  CountryCodes.BG,
  CountryCodes.CY,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.DK,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GB,
  CountryCodes.GR,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.IE,
  CountryCodes.IM,
  CountryCodes.IT,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MC,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.PL,
  CountryCodes.PT,
  CountryCodes.RO,
  CountryCodes.SE,
  CountryCodes.SI,
  CountryCodes.SK,
];
export const SMSupportedCountryCodes = [
  CountryCodes.AE,
  CountryCodes.AR,
  CountryCodes.AU,
  CountryCodes.BR,
  CountryCodes.CA,
  CountryCodes.CH,
  CountryCodes.LI,
  CountryCodes.DK,
  CountryCodes.FO,
  CountryCodes.GL,
  CountryCodes.AD,
  CountryCodes.AL,
  CountryCodes.AT,
  CountryCodes.AX,
  CountryCodes.BA,
  CountryCodes.BE,
  CountryCodes.BG,
  CountryCodes.BY,
  CountryCodes.CY,
  CountryCodes.CZ,
  CountryCodes.DE,
  CountryCodes.EE,
  CountryCodes.ES,
  CountryCodes.FI,
  CountryCodes.FR,
  CountryCodes.GF,
  CountryCodes.GP,
  CountryCodes.GR,
  CountryCodes.HR,
  CountryCodes.HU,
  CountryCodes.IE,
  CountryCodes.IS,
  CountryCodes.IT,
  CountryCodes.LT,
  CountryCodes.LU,
  CountryCodes.LV,
  CountryCodes.MC,
  CountryCodes.MD,
  CountryCodes.ME,
  CountryCodes.MF,
  CountryCodes.MK,
  CountryCodes.MQ,
  CountryCodes.MT,
  CountryCodes.NL,
  CountryCodes.PL,
  CountryCodes.PM,
  CountryCodes.PT,
  CountryCodes.RE,
  CountryCodes.RO,
  CountryCodes.RS,
  CountryCodes.RU,
  CountryCodes.SI,
  CountryCodes.SK,
  CountryCodes.SM,
  CountryCodes.TF,
  CountryCodes.TR,
  CountryCodes.UA,
  CountryCodes.VA,
  CountryCodes.YT,
  CountryCodes.GB,
  CountryCodes.GG,
  CountryCodes.GI,
  CountryCodes.GS,
  CountryCodes.IM,
  CountryCodes.JE,
  CountryCodes.HK,
  CountryCodes.IN,
  CountryCodes.JP,
  CountryCodes.KR,
  CountryCodes.MX,
  CountryCodes.NO,
  CountryCodes.NZ,
  CountryCodes.SE,
  CountryCodes.SG,
  CountryCodes.TW,
  CountryCodes.AF,
  CountryCodes.AG,
  CountryCodes.AI,
  CountryCodes.AM,
  CountryCodes.AN,
  CountryCodes.AO,
  CountryCodes.AQ,
  CountryCodes.AS,
  CountryCodes.AW,
  CountryCodes.AZ,
  CountryCodes.BB,
  CountryCodes.BD,
  CountryCodes.BF,
  CountryCodes.BH,
  CountryCodes.BI,
  CountryCodes.BJ,
  CountryCodes.BL,
  CountryCodes.BM,
  CountryCodes.BN,
  CountryCodes.BO,
  CountryCodes.BS,
  CountryCodes.BT,
  CountryCodes.BV,
  CountryCodes.BW,
  CountryCodes.BZ,
  CountryCodes.CC,
  CountryCodes.CD,
  CountryCodes.CF,
  CountryCodes.CG,
  CountryCodes.CI,
  CountryCodes.CK,
  CountryCodes.CL,
  CountryCodes.CM,
  CountryCodes.CN,
  CountryCodes.CO,
  CountryCodes.CR,
  CountryCodes.CV,
  CountryCodes.CX,
  CountryCodes.DJ,
  CountryCodes.DM,
  CountryCodes.DO,
  CountryCodes.DZ,
  CountryCodes.EC,
  CountryCodes.EG,
  CountryCodes.EH,
  CountryCodes.ER,
  CountryCodes.ET,
  CountryCodes.FJ,
  CountryCodes.FK,
  CountryCodes.FM,
  CountryCodes.GA,
  CountryCodes.GD,
  CountryCodes.GE,
  CountryCodes.GH,
  CountryCodes.GM,
  CountryCodes.GN,
  CountryCodes.GQ,
  CountryCodes.GT,
  CountryCodes.GU,
  CountryCodes.GW,
  CountryCodes.GY,
  CountryCodes.HM,
  CountryCodes.HN,
  CountryCodes.HT,
  CountryCodes.ID,
  CountryCodes.IL,
  CountryCodes.IO,
  CountryCodes.IQ,
  CountryCodes.JM,
  CountryCodes.JO,
  CountryCodes.KE,
  CountryCodes.KG,
  CountryCodes.KH,
  CountryCodes.KI,
  CountryCodes.KM,
  CountryCodes.KN,
  CountryCodes.KP,
  CountryCodes.KW,
  CountryCodes.KY,
  CountryCodes.KZ,
  CountryCodes.LA,
  CountryCodes.LB,
  CountryCodes.LC,
  CountryCodes.LK,
  CountryCodes.LR,
  CountryCodes.LS,
  CountryCodes.LY,
  CountryCodes.MA,
  CountryCodes.MG,
  CountryCodes.MH,
  CountryCodes.ML,
  CountryCodes.MM,
  CountryCodes.MN,
  CountryCodes.MO,
  CountryCodes.MP,
  CountryCodes.MR,
  CountryCodes.MS,
  CountryCodes.MU,
  CountryCodes.MV,
  CountryCodes.MW,
  CountryCodes.MY,
  CountryCodes.MZ,
  CountryCodes.NA,
  CountryCodes.NC,
  CountryCodes.NE,
  CountryCodes.NF,
  CountryCodes.NG,
  CountryCodes.NI,
  CountryCodes.NP,
  CountryCodes.NR,
  CountryCodes.NU,
  CountryCodes.OM,
  CountryCodes.PA,
  CountryCodes.PE,
  CountryCodes.PF,
  CountryCodes.PG,
  CountryCodes.PH,
  CountryCodes.PK,
  CountryCodes.PN,
  CountryCodes.PR,
  CountryCodes.PS,
  CountryCodes.PW,
  CountryCodes.PY,
  CountryCodes.QA,
  CountryCodes.RW,
  CountryCodes.SA,
  CountryCodes.SB,
  CountryCodes.SC,
  CountryCodes.SH,
  CountryCodes.SJ,
  CountryCodes.SL,
  CountryCodes.SN,
  CountryCodes.SO,
  CountryCodes.SR,
  CountryCodes.ST,
  CountryCodes.SV,
  CountryCodes.SY,
  CountryCodes.SZ,
  CountryCodes.TC,
  CountryCodes.TD,
  CountryCodes.TG,
  CountryCodes.TH,
  CountryCodes.TJ,
  CountryCodes.TK,
  CountryCodes.TL,
  CountryCodes.TM,
  CountryCodes.TN,
  CountryCodes.TO,
  CountryCodes.TT,
  CountryCodes.TV,
  CountryCodes.TZ,
  CountryCodes.UG,
  CountryCodes.UM,
  CountryCodes.US,
  CountryCodes.UY,
  CountryCodes.UZ,
  CountryCodes.VC,
  CountryCodes.VE,
  CountryCodes.VG,
  CountryCodes.VI,
  CountryCodes.VN,
  CountryCodes.VU,
  CountryCodes.WF,
  CountryCodes.WS,
  CountryCodes.YE,
  CountryCodes.ZM,
  CountryCodes.ZW,
  CountryCodes.ZA,
];
