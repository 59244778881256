import { createMemoStyles, Theme } from '@wds/styles';

import { InlineListProps } from './InlineList';
import { InlineListItemProps } from './InlineListItem';

type InlineStyleProps = Pick<InlineListProps, 'noMargin'> &
  Pick<InlineListItemProps, 'isTitle'>;

const useStyles = createMemoStyles(({ palette, spacing, type }: Theme) => ({
  inlineList: ({ noMargin }: InlineStyleProps) => ({
    display: 'table',
    margin: noMargin ? 0 : [0, 0, spacing[4]],
    paddingLeft: 0,
    paddingRight: 0,
  }),
  inlineListItem: ({ isTitle }: InlineStyleProps) => ({
    display: isTitle ? 'table-cell' : 'inline-block',
    listStyle: 'disc none',
    margin: [0, spacing[4], spacing[1], 0],
    position: 'relative',
    fontSize: type.fontSize.bodySm,
    color: palette.text.darkMuted,

    ...(isTitle && {
      fontWeight: type.fontWeight.medium,
      width: spacing[9],
      listStyleType: 'none',
    }),

    '&:last-child': {
      marginRight: 0,
    },
  }),
  inlineListItemBullet: {
    position: 'absolute',
    right: -spacing[3],
    top: 0,
  },
  inlineListItemAnchor: {
    color: palette.text.darkMuted,
    textDecoration: 'none',

    '&:visited': {
      color: palette.text.darkMuted,
    },

    '&:hover, &:focus': {
      color: palette.text.dark,
      textDecoration: 'underline',
    },
  },
}));

export default useStyles;
