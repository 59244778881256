import { useEffect, useState } from 'react';

const useFides = (): Window['Fides'] => {
  const [fides, setFides] = useState<Window['Fides']>();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setFides(window.Fides);
    }
  }, []);
  return fides;
};

export default useFides;
