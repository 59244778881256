import React, { ReactElement } from 'react';
import { InlineList, InlineListItem } from '../InlineList';

export interface BillingAddressProps {
  country?: string;
}

const BillingAddress = ({
  country = 'US',
}: BillingAddressProps): ReactElement => (
  <InlineList noMargin data-testid="BillingAddress__Footer">
    {country === 'US' && (
      <InlineListItem lastItem>
        SurveyMonkey 3050 South Delaware Street, San Mateo, CA, 94403, USA
      </InlineListItem>
    )}
    {country === 'BR' && (
      <InlineListItem lastItem>
        SurveyMonkey Brasil Internet Ltda. Rua Joaquim Floriano, Nº 243, Suíte
        113, Itaim Bibi, São Paulo, SP, CEP 04534-010, Brasil
      </InlineListItem>
    )}
    {country !== 'US' && country !== 'BR' && (
      <InlineListItem lastItem>
        SurveyMonkey Europe UC 2 Shelbourne Buildings, 2nd Floor, Shelbourne
        Road, Ballsbridge, Dublin 4, Ireland
      </InlineListItem>
    )}
  </InlineList>
);

export default BillingAddress;
