import React from 'react';
import { Box } from '@wds/box';
import { t, defineMessages } from '@sm/intl';
import { ClientError, ClientErrorProps } from '../ClientError';
import Links, { LinksProps } from '../ClientError/Links';
import { withWebAssetsTheme } from '../../theme';

const DEFAULT_COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'FiveHundredError.SorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  SOMETHING_WRONG: {
    id: 'FiveHundredError.SomethingWentWrong',
    defaultMessage: 'Something went wrong.',
    description: '[Type: Label][Vis.: med] - something went wrong subtitle',
  },
  FIVE_HUNDRED_MESSAGE: {
    id: 'FiveHundredError.Message',
    defaultMessage:
      "Sorry if you lost your place - we're working hard to get things up and running again. Thanks for your patience!",
    description: '[Type: Label][Vis.: med] - Five Hundred Error Message',
  },
});

export type FiveHundredErrorProps = Partial<ClientErrorProps> & {
  /** Error content message */
  errorMessage?: React.ReactNode;
  /** ClientError Links component props */
  linksProps?: LinksProps;
};

const FiveHundredError = ({
  openingMessage = t(DEFAULT_COPY.SORRY_MESSAGE),
  errorTitle = t(DEFAULT_COPY.SOMETHING_WRONG),
  titularElementTag = 'p',
  errorMessage = t(DEFAULT_COPY.FIVE_HUNDRED_MESSAGE),
  linksProps,
  'data-testid': dataTestId = 'FiveHundredError',
  ...props
}: FiveHundredErrorProps): React.ReactElement => {
  return (
    <ClientError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      data-testid={dataTestId}
      {...props}
    >
      <Box mt={4}>
        {React.Children.map(errorMessage, (message: React.ReactNode) => {
          return <p data-testid={`${dataTestId}_ErrorMessage`}>{message}</p>;
        })}
      </Box>
      <Links {...linksProps} mt={6} />
    </ClientError>
  );
};
export default withWebAssetsTheme(FiveHundredError);
