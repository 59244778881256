import { Typography } from '@wds/typography';
import { createMemoStyles, Theme } from '@wds/styles';
import React, { ReactElement } from 'react';

const useStyles = createMemoStyles((theme: Theme) => ({
  accountName: {
    maxWidth: '225px',
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: '300px',
    },
  },
  truncate: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const AccountName = ({ username }: { username: string }): ReactElement => {
  const { accountName, truncate } = useStyles();
  return (
    <div className={accountName}>
      <Typography component="div" variant="bodySm">
        <div data-testid="AccountName__Username" className={truncate}>
          <strong>{username}</strong>
        </div>
      </Typography>
    </div>
  );
};

export default AccountName;
