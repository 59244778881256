import { defineMessages } from '@sm/intl';

export default defineMessages({
  help: {
    id: 'footerCopy.Help',
    defaultMessage: 'Help',
    description: '[Type: Link][Vis: low] - Link to the Help Center',
  },
  termsOfUse: {
    id: 'footerCopy.TermsOfUse',
    defaultMessage: 'Terms of Use',
    description: '[Type: Link][Vis: low] - Link to the Terms of Use',
  },
  privacyPolicy: {
    id: 'footerCopy.PrivacyPolicy',
    defaultMessage: 'Privacy Notice',
    description: '[Type: Link][Vis: low] - Link to the Privacy Notice',
  },
  regionalPrivacyPolicy: {
    id: 'footerCopy.RegionalPrivacyPolicy',
    defaultMessage: 'California Privacy Notice',
    description:
      '[Type: Link][Vis: low] - Link to the California Privacy Notice',
  },
  cookiePolicy: {
    id: 'footerCopy.CookiesPolicy',
    defaultMessage: 'Cookies Policy',
    description: '[Type: Link][Vis: low] - Link to the Cookies Policy',
  },
});
