import { createURL } from '@sm/utils';
import { t } from '@sm/intl';
import { getHelpCenterLink } from '../../../../helpers/getHelpLink';
import COPY from './getFooterCopy';

const getAuthFooterLinks = (domain: string, lang: string) => {
  return [
    {
      title: t(COPY.ABOUT_SURVEYMONKEY),
      url: createURL('/about/', { ut_source: 'footer' }),
    },
    {
      title: t(COPY.CAREERS),
      url: createURL('/careers/', { ut_source: 'footer' }),
    },
    {
      title: t(COPY.DEVELOPERS),
      url: createURL(`https://developer.${domain}.com/`, {
        ut_source: 'footer',
      }),
    },
    {
      title: t(COPY.PRIVACY_NOTICE),
      url: createURL('/mp/legal/privacy/', { ut_source: 'footer' }),
    },
    {
      title: t(COPY.CALIFORNIA_PRIVACY_NOTICE),
      url: createURL('/mp/legal/region-specific-privacy-statement/', {
        ut_source: 'footer',
      }),
    },
    ...(lang === 'de'
      ? [
          {
            title: 'Impressum',
            url: createURL('/about/impressum/', { ut_source: 'footer' }),
          },
        ]
      : []),
    {
      title: t(COPY.EMAIL_OPT_IN),
      url: createURL('/user/email-opt-in/', { ut_source: 'footer' }),
    },
    {
      title: t(COPY.HELP),
      url: getHelpCenterLink(lang, {
        params: { ut_source: 'footer' },
      }),
    },
    {
      title: t(COPY.COOKIES_NOTICE),
      url: createURL('/mp/legal/cookies/', { ut_source: 'footer' }),
    },
  ];
};

export default getAuthFooterLinks;
