import { parseDomain, fromUrl, ParseResultType } from 'parse-domain';
import dataCentersData from './data/dataCenters.json';

export enum DataCenterIds {
  US = '0',
  CA = '1',
  EU = '2',
}

export type WWWSubdomain = 'www';
export type EUSubdomain = 'eu';
export type DCSubdomain = WWWSubdomain | EUSubdomain;

type DataCenterSpecificSubdomains = Record<DCSubdomain, DataCenterIds>;

export type DataCenter = {
  id: DataCenterIds;
  regionCode: string;
  regionName: string;
  surveyCollectionDefaultTld: string;
  surveyCollectionWhiteLabelTld: string;
  defaultSubdomain: DCSubdomain;
  CCTldSupport: boolean;
};

export enum DC_SPECIFIC_SUBDOMAINS {
  eu = DataCenterIds.EU,
}

/**
 * Returns the data center by id
 * @param {string} dataCenterId
 * @returns {Object} data center details
 * @memberof module:@sm/locale
 */
export function getDataCenterById(dataCenterId: DataCenterIds): DataCenter {
  return dataCentersData.dataCenters[dataCenterId] as DataCenter;
}

/**
 * Returns the data center id from the url
 * @param {string} url
 * @param {string} dataCenterAffinityCookie
 * @returns {string} data center id
 * @memberof module:@sm/locale
 */
export function getDataCenterIdFromCurrentUrl(
  url: string,
  dataCenterAffinityCookie?: string
): DataCenterIds {
  const parseResult = parseDomain(fromUrl(url)) || {};
  let subdomain: string;
  let dataCenterId: DataCenterIds = DataCenterIds.US;

  if (parseResult.type === ParseResultType.Listed) {
    subdomain = parseResult.subDomains.join('.');
    if (subdomain) {
      const dcSub = Object.keys(
        dataCentersData.dataCenterSpecificSubdomains
      ).find(
        subSpecific =>
          subSpecific === subdomain || subdomain.endsWith(`.${subSpecific}`)
      ) as DCSubdomain;
      if (dcSub) {
        dataCenterId = (
          dataCentersData.dataCenterSpecificSubdomains as DataCenterSpecificSubdomains
        )[dcSub];
      }
    }
  }

  if (
    dataCenterId === DataCenterIds.US &&
    `${dataCenterAffinityCookie}` === DataCenterIds.CA
  ) {
    dataCenterId = DataCenterIds.CA;
  }

  return dataCenterId;
}

// This is currently being used in UserWeb here https://github.com/mntv-enterprise-admin/userweb/blob/develop/userweb/lib/language.py#L31
// So I'm leaving this function for when we port the corresponding code in UserWeb.
/**
 * Returns the default subdomain for a data center
 * @param {string} dataCenterId
 * @returns {string} subdomain
 * @memberof module:@sm/locale
 */
export function getDefaultSubdomainForDataCenter(
  dataCenterId: DataCenterIds
): DCSubdomain {
  const dc = getDataCenterById(dataCenterId);
  return (dc && dc.defaultSubdomain) || 'www';
}
