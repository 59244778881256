import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useMobileDrawerTriggerStyles = createUseStyles(
  ({ breakpoints, palette }: Theme) => ({
    MobileTrigger: {
      float: 'left',

      '&.sm-mobile-menu__cta [class^="wrenchButton"]': {
        border: 0,
        width: 20,
        height: 50,
        fontSize: 20,
        cursor: 'pointer',
        boxSizing: 'content-box',
        outline: 0,
        order: 3,
        position: 'absolute',
        left: 0,
        color: palette.text.light,

        [`@media (min-width: ${breakpoints.xl}px)`]: {
          display: 'none',
        },
      },

      '&.sm-mobile-menu--alt [class^="wrenchButton"]': {
        color: palette.text.light,

        '&:hover, &:focus': {
          background: 'transparent',
        },
      },

      '&.sm-mobile-menu__cta.sm-mobile-menu--secondary [class^="wrenchButton"]':
        {
          color: palette.primary.main,

          '&:hover, &:focus': {
            background: palette.background.main,
          },
        },
    },
  })
);

export default useMobileDrawerTriggerStyles;
