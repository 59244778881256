import type { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';
import { LogoColors } from './Logo';

const useStyles = createUseStyles(({ palette }: Theme) => ({
  logo: (props: { color: LogoColors }) => ({
    color: props.color === 'alt' ? palette.text.light : palette.primary.main,
    fill: props.color === 'alt' ? palette.text.light : palette.primary.main,
  }),
}));

export default useStyles;
