import Bowser from 'bowser';

/**
 * Environment Details
 * @typedef {Object}
 * @property {boolean} isNode
 * @property {boolean} isBrowser
 * @property {boolean} isDesktop
 * @property {boolean} isHeadlessChrome
 * @property {boolean} isMobile
 * @property {boolean} isTablet
 * @property {boolean} isIOS
 * @property {boolean} isAndroid
 * @property {Object} clientWindowSize
 * @property {string} clientWindowSize.width
 * @property {string} clientWindowSize.height
 * @memberof module:@sm/utils
 */
export const environmentDetails: EnvironmentDetails = {
  isNode: false,
  isBrowser: false,
  isDesktop: false,
  isHeadlessChrome: false,
  isMobile: false,
  isTablet: false,
  isIOS: false,
  isAndroid: false,
  clientWindowSize: {
    width: undefined,
    height: undefined,
  },
};

type EnvironmentDetails = {
  isNode: boolean;
  isBrowser: boolean;
  isDesktop: boolean;
  isHeadlessChrome: boolean;
  isMobile: boolean;
  isTablet: boolean;
  isIOS: boolean;
  isAndroid: boolean;
  clientWindowSize: {
    width?: number;
    height?: number;
  };
};
let userAgentTracker = '';

/**
 * inspectAgent
 * @param {string} userAgent The string for the user agent
 */
function inspectAgent(userAgent: string) {
  if (!userAgent) {
    return;
  }
  if (userAgent.match(/iPhone|iPod|iPad/i) !== null) {
    environmentDetails.isIOS = true;
    if (userAgent.match(/iPad/i) !== null) {
      environmentDetails.isTablet = true;
    } else {
      environmentDetails.isMobile = true;
    }
  } else if (userAgent.match(/Android/i) !== null) {
    environmentDetails.isMobile = true;
    environmentDetails.isAndroid = true;
  } else if (userAgent.match(/HeadlessChrome/i) !== null) {
    environmentDetails.isHeadlessChrome = true;
  } else {
    environmentDetails.isDesktop = true;
  }
}

/**
 * Initialize
 * @param {string} userAgent The string for the user agent
 * @private
 */
function _initialize(userAgent: string) {
  let ua = userAgent;
  if (typeof window === 'object') {
    environmentDetails.isBrowser = true;
    window.SM = window.SM || {};
    const { SM } = window;
    SM.__LOAD_PAYLOAD_CACHE__ = SM.__LOAD_PAYLOAD_CACHE__ || {};
    // Detect banner type (iOS or Android)
    environmentDetails.clientWindowSize = {
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    };
    ua = navigator.userAgent;
  } else {
    environmentDetails.isNode = true;
  }
  inspectAgent(ua);
}

/**
 * Returns the environment details
 * @param {string} userAgent The string for the user agent
 * @returns {environmentDetails} env details
 * @memberof module:@sm/utils
 */
export function getClientEnvironmentDetails(
  userAgent = ''
): EnvironmentDetails {
  if (
    (environmentDetails.isBrowser === false &&
      environmentDetails.isNode === false) ||
    userAgentTracker !== userAgent
  ) {
    userAgentTracker = userAgent;
    _initialize(userAgentTracker);
  }
  return environmentDetails;
}

export enum SupportedBrowserVendors {
  Chrome = 'chrome',
  Firefox = 'firefox',
  Safari = 'safari',
  IE = 'ie',
  Edge = 'edge',
}
export type VersionOperators = '>' | '<' | '~' | '=' | '>=' | '<=';
export type SupportedBrowsers = {
  vendor: SupportedBrowserVendors;
  operator: VersionOperators;
  version: string;
};

const defaultSupportedBrowsers: SupportedBrowsers[] = [
  {
    vendor: SupportedBrowserVendors.Chrome,
    operator: '>=',
    version: '18',
  },
  {
    vendor: SupportedBrowserVendors.Firefox,
    operator: '>=',
    version: '24',
  },
  {
    vendor: SupportedBrowserVendors.Safari,
    operator: '>=',
    version: '7',
  },
  {
    vendor: SupportedBrowserVendors.Edge,
    operator: '>=',
    version: '12',
  },
  {
    vendor: SupportedBrowserVendors.IE,
    operator: '>=',
    version: '11',
  },
];

export function isBrowserSupported(
  userAgent: string,
  supportedBrowsers?: readonly SupportedBrowsers[]
): boolean {
  const browser = Bowser.getParser(userAgent);
  const browsers = supportedBrowsers || defaultSupportedBrowsers;

  browsers.forEach(({ version }) => {
    if (!version.match(/^\d+(\.\d+)?(\.\d+)?$/)) {
      throw new Error(`Unsupported version number: ${version}`);
    }
  });

  const config = browsers.reduce<Bowser.Parser.checkTree>(
    (cfg, { vendor, operator, version }) => {
      cfg[vendor] = `${operator}${version}`;
      return cfg;
    },
    {}
  );

  return browser.satisfies(config) || false;
}
