import React, { createContext } from 'react';
import { createTheming, ThemeProvider } from 'react-jss';
import { WrenchTheme as WebAssetsTheme } from '@wds/styles';

const ThemeContext = createContext({});

const theming = createTheming(ThemeContext);

const {
  ThemeProvider: WebAssetsThemeProvider,
  useTheme: useWebAssetsTheme,
} = theming;

const WebAssetsThemeWrapper = ({ children }: any) => {
  const theme = useWebAssetsTheme();
  return (
    <ThemeProvider theme={{ ...WebAssetsTheme, ...theme }}>
      {children}
    </ThemeProvider>
  );
};

const withWebAssetsTheme = <T extends object>(
  Component: React.ComponentType<T>
) => (props: T) => {
  return (
    <WebAssetsThemeWrapper>
      <Component {...props} />
    </WebAssetsThemeWrapper>
  );
};

export {
  WebAssetsThemeProvider,
  useWebAssetsTheme,
  WebAssetsTheme,
  WebAssetsThemeWrapper,
  withWebAssetsTheme,
};
export type ThemeShape = typeof WebAssetsTheme;
