import { defineMessages } from '@sm/intl';

const TEAM_MENU_COPY = defineMessages({
  TEAM_SUMMARY: {
    id: 'TeamMenuCopy.teamSummary',
    defaultMessage: 'Team Summary',
    description: '[Type: Label][Vis.: low] - Team Summary section of nav bar',
  },
  MANAGE_USERS: {
    id: 'TeamMenuCopy.manageUsers',
    defaultMessage: 'Manage Users',
    description: '[Type: Label][Vis.: low] - Manage Users section of nav bar',
  },
  ADD_USERS: {
    id: 'TeamMenuCopy.addUsers',
    defaultMessage: 'Add Users',
    description: '[Type: Label][Vis.: low] - Add Users section of nav bar',
  },
  ADMIN_DASHBOARD: {
    id: 'TeamMenuCopy.adminDashboard',
    defaultMessage: 'Admin Dashboard',
    description:
      '[Type: Label][Vis.: low] - Admin Dashboard section of nav bar',
  },
  USER_REPORT: {
    id: 'TeamMenuCopy.userReport',
    defaultMessage: 'User Report',
    description: '[Type: Label][Vis.: low] - User Report section of nav bar',
  },
  WORKGROUPS: {
    id: 'TeamMenuCopy.workgroups',
    defaultMessage: 'Workgroups',
    description: '[Type: Label][Vis.: low] - Workgroup section of nav bar',
  },
  LIBRARY: {
    id: 'TeamMenuCopy.library',
    defaultMessage: 'Library',
    description: '[Type: Label][Vis.: low] - Library section of nav bar',
  },
  ACTIVITY: {
    id: 'TeamMenuCopy.activity',
    defaultMessage: 'Activity',
    description: '[Type: Label][Vis.: low] - Activity section of nav bar',
  },
  OFFLINE_DEVICES: {
    id: 'TeamMenuCopy.offlineDevices',
    defaultMessage: 'Offline Devices',
    description:
      '[Type: Label][Vis.: low] - Offline Devices section of nav bar',
  },
  MANAGE_CREDITS: {
    id: 'TeamMenuCopy.manageCredits',
    defaultMessage: 'Manage Credits',
    description: '[Type: Label][Vis.: low] - Manage Credits section of nav bar',
  },
  SETTINGS: {
    id: 'TeamMenuCopy.settings',
    defaultMessage: 'Settings',
    description: '[Type: Label][Vis.: low] - Settings section of nav bar',
  },
  DIVISIONS: {
    id: 'TeamMenuCopy.divisions',
    defaultMessage: 'Divisions',
    description: '[Type: Label][Vis.: low] - Settings section of nav bar',
  },
});

export default TEAM_MENU_COPY;
