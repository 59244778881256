/* eslint-disable */
import * as Types from '../../../types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type TrusteSealsQueryQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type TrusteSealsQueryQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    team?: {
      __typename?: 'Team';
      id: string;
      billing: {
        __typename?: 'TeamBilling';
        recentInvoice?: {
          __typename?: 'BillingInvoice';
          paymentMethod?: Types.BillingPaymentMethod | null;
          paymentMethodSubtype?: Types.BillingPaymentMethodSubtype | null;
        } | null;
      };
    } | null;
  } | null;
};

export const TrusteSealsQueryDocument = gql`
  query trusteSealsQuery {
    user {
      id
      team {
        id
        billing {
          recentInvoice {
            paymentMethod
            paymentMethodSubtype
          }
        }
      }
    }
  }
`;

/**
 * __useTrusteSealsQueryQuery__
 *
 * To run a query within a React component, call `useTrusteSealsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrusteSealsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrusteSealsQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useTrusteSealsQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    TrusteSealsQueryQuery,
    TrusteSealsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrusteSealsQueryQuery, TrusteSealsQueryQueryVariables>(
    TrusteSealsQueryDocument,
    options
  );
}
export function useTrusteSealsQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    TrusteSealsQueryQuery,
    TrusteSealsQueryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    TrusteSealsQueryQuery,
    TrusteSealsQueryQueryVariables
  >(TrusteSealsQueryDocument, options);
}
export type TrusteSealsQueryQueryHookResult = ReturnType<
  typeof useTrusteSealsQueryQuery
>;
export type TrusteSealsQueryLazyQueryHookResult = ReturnType<
  typeof useTrusteSealsQueryLazyQuery
>;
export type TrusteSealsQueryQueryResult = Apollo.QueryResult<
  TrusteSealsQueryQuery,
  TrusteSealsQueryQueryVariables
>;
