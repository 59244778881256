import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useHeaderLogoStyles = createUseStyles(
  ({ breakpoints, spacing, type }: Theme) => ({
    LogoLink: {
      margin: 'auto',
      textDecoration: 'none',

      '&:hover, &:focus': {
        textDecoration: 'none',
      },

      '&.anon--limited': {
        [`@media (min-width: ${breakpoints.xl}px)`]: {
          flexGrow: 1,
        },
      },

      '&.auth--default, &.auth--limited': {
        padding: spacing[1],
        margin: 'auto',

        [`@media (min-width: ${breakpoints.md}px)`]: {
          padding: [spacing[1], 0, 0, spacing[7]],
          flexGrow: 1,
        },

        [`@media (min-width: ${breakpoints.xl}px)`]: {
          padding: [spacing[1], 0, 0, spacing[1]],
        },
      },

      '&.auth--default': {
        [`@media (min-width: ${breakpoints.xl}px)`]: {
          flexGrow: 0,
        },
      },

      '&.auth--limited': {
        padding: spacing[2],
        margin: 'auto',

        [`@media (min-width: ${breakpoints.md}px)`]: {
          padding: [spacing[2], 0, 0, spacing[7]],
          flexGrow: 1,
        },

        [`@media (min-width: ${breakpoints.xl}px)`]: {
          padding: [spacing[2], 0, 0, spacing[1]],
        },
      },
    },

    LogoAuthIcon: {
      margin: spacing[3],
      fontSize: 34,
      fontWeight: type.fontWeight.regular,
    },

    LogoAnonIcon: {
      margin: 0,
      fontSize: 34,
      fontWeight: type.fontWeight.regular,

      [`@media (min-width: ${breakpoints.lg}px)`]: {
        display: 'none',
      },
    },

    LogoText: {
      width: 160,
      height: 24,
      display: 'none',

      [`@media (min-width: ${breakpoints.lg}px)`]: {
        display: 'inline-flex',
      },

      [`@media (min-width: ${breakpoints.xl}px)`]: {
        width: 180,
        height: 36,
      },
    },
  })
);

export default useHeaderLogoStyles;
