import React, { useState, useEffect } from 'react';
import { isBrowserSupported, SupportedBrowsers } from '@sm/utils';
import { Modal } from '@wds/modal';
import { defineMessages, T, t } from '@sm/intl';
import { WebAssetsThemeWrapper } from '../../theme';

export interface BrowserGuardProps {
  children: JSX.Element;
  supportedBrowsers: SupportedBrowsers[];
}

const COPY = defineMessages({
  DIALOG_HEADER: {
    id: 'BrowserGuard.DialogHeader',
    defaultMessage: "Your browser isn't supported",
    description:
      'Header of a dialog displaying a message to the users that their browser is not supported.',
  },
  DIALOG_BODY: {
    id: 'BrowserGuard.DialogBody',
    defaultMessage: 'For more information please visit:',
    description:
      'Text describing that more information on the issue will be available on the following link',
  },
  LINK_TEXT: {
    id: 'BrowserGuard.LinkText',
    defaultMessage: 'Supported Browsers',
    description:
      'Text for a link directing the user to the Supported Browsers help page',
  },
});

const BrowserGuardDialog = (): JSX.Element => {
  return (
    <WebAssetsThemeWrapper>
      <div data-testid="BrowserGuardDialog">
        <Modal show dismissible>
          <Modal.Header header={t(COPY.DIALOG_HEADER)} />
          <Modal.Body>
            <p>
              <T desc={COPY.DIALOG_BODY} />
            </p>
            <p>
              <a href="https://help.surveymonkey.com/articles/en_US/kb/What-browser-versions-do-you-support">
                <T desc={COPY.LINK_TEXT} />
              </a>
            </p>
          </Modal.Body>
        </Modal>
      </div>
    </WebAssetsThemeWrapper>
  );
};

/**
 * Component for checking if the user browser is supported
 *
 * Basic usage example:
 * `<BrowserGuard />`
 *
 * The basic usage will check against the default supported browsers
 * as documented here: https://help.surveymonkey.com/articles/en_US/kb/What-browser-versions-do-you-support
 *
 * If the user's browser is not supported a modal window will be displayed to the user.
 *
 * Optionally one can provide a list of supported browsers via the supportedBrowsers prop.
 * Also, a different UI may be rendered if a children is passed to the component.
 */
const BrowserGuard = ({
  // defaultProps will eventually be deprecated, so using object default values
  children = <BrowserGuardDialog />,
  supportedBrowsers,
}: BrowserGuardProps): JSX.Element => {
  const [hasVerified, setHasVerified] = useState(false);
  const [isSupported, setIsSupported] = useState(false);

  useEffect(() => {
    if (window && !hasVerified) {
      setIsSupported(
        isBrowserSupported(window.navigator.userAgent, supportedBrowsers)
      );
      setHasVerified(true);
    }
  }, [hasVerified, supportedBrowsers]);

  return (
    <>
      {hasVerified && !isSupported ? (
        <div data-testid="BrowserGuard">{children}</div>
      ) : null}
    </>
  );
};

export default BrowserGuard;
