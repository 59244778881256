import React, { useContext } from 'react';
import { t } from '@sm/intl';
import NavSubmenuCopy from '../../copy/NavSubmenuCopy';
import Menu from './components/Menu.js';
import SubMenu from './components/SubMenu';
import SubMenuBody from './components/SubMenuBody.js';
import ByNeedSubMenu from './components/ByNeedSubMenu.js';
import BottomLinkWrapper from './components/BottomLinkWrapper.js';
import SubMenuItem from './components/SubMenuItem.js';
import Column from './components/Column.js';
import SurveyTypesColumn from './components/SurveyTypesColumn.js';
import ByNeedColumn from './components/ByNeedColumn.js';
import BottomLink from './components/BottomLink.js';
import Block from './components/Block.js';
import ColumnTitle from './components/ColumnTitle.js';
import SolutionByNeed from './components/SolutionByNeed.js';
import SurveySolution from './components/SurveySolution.js';

import {
  exploreSurveyTypes,
  peoplePoweredData,
  customerFeedback,
  employeeFeedback,
  marketResearch,
  solutionsForTeams,
  customerExperience,
  humanResources,
  marketing,
  customerSatisfaction,
  customerLoyalty,
  eventSurveys,
  employeeEngagement,
  jobSatisfaction,
  hrSurveys,
  marketResearchSurveys,
  opinionPolls,
  conceptTesting,
  solutionsMenu,
} from '../../config/linkInfo';

import SMHeaderContext from '../../SMHeaderContext';

export const SolutionsMenu = () => {
  const { lang } = useContext(SMHeaderContext);

  const key = 'Solutions';
  const subMenuTitle = t(NavSubmenuCopy.SOLUTIONS_TITLE);

  return (
    <Menu menuKey={key} {...solutionsMenu()}>
      <SubMenu submenuKey={key} title={subMenuTitle}>
        <SubMenuBody>
          {lang === 'en' ? (
            <>
              <SurveyTypesColumn title="Survey Types">
                <Block>
                  <SurveySolution {...customerSatisfaction()} />
                  <SurveySolution {...customerLoyalty()} />
                  <SurveySolution {...eventSurveys()} />
                </Block>
                <Block>
                  <SurveySolution {...employeeEngagement()} />
                  <SurveySolution {...jobSatisfaction()} />
                  <SurveySolution {...hrSurveys()} />
                </Block>
                <Block>
                  <SurveySolution {...marketResearchSurveys()} />
                  <SurveySolution {...opinionPolls()} />
                  <SurveySolution {...conceptTesting()} />
                </Block>
              </SurveyTypesColumn>
              <Column>
                <ColumnTitle {...peoplePoweredData()} />
                <SubMenuItem {...customerFeedback()} />
                <SubMenuItem {...employeeFeedback()} />
                <SubMenuItem {...marketResearch()} />
              </Column>
              <Column>
                <ColumnTitle {...solutionsForTeams()} />
                <SubMenuItem {...customerExperience()} />
                <SubMenuItem {...humanResources()} />
                <SubMenuItem {...marketing()} />
              </Column>
            </>
          ) : (
            <>
              <Column>
                <SubMenuItem {...customerFeedback()} />
              </Column>
              <Column>
                <SubMenuItem {...employeeFeedback()} />
              </Column>
              <Column>
                <SubMenuItem {...marketResearch()} />
              </Column>
            </>
          )}
        </SubMenuBody>
        {lang !== 'en' ? (
          <ByNeedSubMenu>
            <ByNeedColumn>
              <SolutionByNeed {...customerSatisfaction()} />
              <SolutionByNeed {...customerLoyalty()} />
              <SolutionByNeed {...eventSurveys()} />
            </ByNeedColumn>
            <ByNeedColumn>
              <SolutionByNeed {...employeeEngagement()} />
              <SolutionByNeed {...jobSatisfaction()} />
              <SolutionByNeed {...hrSurveys()} />
            </ByNeedColumn>
            <ByNeedColumn>
              <SolutionByNeed {...marketResearchSurveys()} />
              <SolutionByNeed {...opinionPolls()} />
              <SolutionByNeed {...conceptTesting()} />
            </ByNeedColumn>
          </ByNeedSubMenu>
        ) : null}
        <BottomLinkWrapper>
          <BottomLink {...exploreSurveyTypes(lang)} />
        </BottomLinkWrapper>
      </SubMenu>
    </Menu>
  );
};

export default SolutionsMenu;
