import React from 'react';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import Menu from './components/Menu';
import { plansPricingMenu } from '../../config/linkInfo.js';

export const PlansAndPricingMenu = () => {
  const handleMenuClick = () => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: 'review session landing page clicked pricingcta',
        interactionType: 'click',
      },
    });
  };

  return <Menu {...plansPricingMenu()} onClick={handleMenuClick} />;
};

export default PlansAndPricingMenu;
