import React, { useState, ReactElement } from 'react';
import { Box } from '@wds/box';
import { Sheet } from '@wds/sheet';
import { Button } from '@wds/button';
import { Grid } from '@wds/grid';
import { Typography } from '@wds/typography';
import { IconLogoGoldie, IconStar, IconStarFilled } from '@wds/icons';
import { getClientEnvironmentDetails } from '@sm/utils';
import useStyles from './useStyles';
import { WebAssetsThemeWrapper } from '../../theme';
import { MobileBannerProps } from './constants';

const MobileBanner = (props: MobileBannerProps) => (
  <WebAssetsThemeWrapper>
    <MobileBannerContent {...props} />
  </WebAssetsThemeWrapper>
);

const MobileBannerContent = ({
  iosRatingText = 'SurveyMonkey on iOS',
  iosRating = 4,
  androidRatingText = 'SurveyMonkey on Android',
  androidRating = 4,
  deepLinkText = 'Open in App',
  installText = 'Install',
  deepLinkData = {},
  downloadLink,
  isUserEligible = false,
}: MobileBannerProps): ReactElement => {
  const { logoAlign } = useStyles();
  const environmentDetails = getClientEnvironmentDetails();
  const getCookie = (name: string) => {
    const match = environmentDetails.isNode
      ? null
      : document.cookie.match(new RegExp(`(^| )${name}=([^;]+)`));
    if (match) {
      return match[2];
    }
    return null;
  };

  const [showBanner, setShowBanner] = useState(true);
  const [sessionTime] = useState(new Date().getTime());
  const [isUserEligibleState] = useState(() => {
    if (
      isUserEligible ||
      (environmentDetails.isMobile &&
        !environmentDetails.isNode &&
        getCookie('sb-closed') !== 'true' &&
        getCookie('sb-installed') !== 'true')
    ) {
      return true;
    }
    return false;
  });

  const setCookie = (name: string) => {
    // Set close cookie
    const dateRightNow = new Date();
    const expiryDate = new Date(
      dateRightNow.getTime() + 15 * 24 * 60 * 60 * 1000
    );
    const cookieValue = `${encodeURI(
      'true'
    )}; expires=${expiryDate.toUTCString()}`;
    document.cookie = `${name}=${cookieValue}; path=/;`;
  };

  const getDownloadLink = () => {
    if (!downloadLink) {
      // eslint-disable-next-line no-throw-literal
      throw 'no download link';
    }
    let newDownloadLink = `${downloadLink}?path=${encodeURIComponent(
      window.location.pathname
    )}`;
    if (deepLinkData && Object.keys(deepLinkData).length !== 0) {
      newDownloadLink = `${downloadLink}&${Object.keys(deepLinkData)
        .map(
          k => `${encodeURIComponent(k)}=${encodeURIComponent(deepLinkData[k])}`
        )
        .join('&')}`;
    }
    newDownloadLink = `${downloadLink}&session=${sessionTime}`;
    return newDownloadLink;
  };

  const handleOnClose = () => {
    setCookie('sb-closed');
    setShowBanner(false);
  };

  const handleInstallClick = () => {
    setCookie('sb-installed');
    setShowBanner(false);
    window.location.assign(getDownloadLink());
  };

  const handleOpenClick = () => {
    setCookie('sb-closed');
    setShowBanner(false);
    window.location.assign(getDownloadLink());
  };

  const getRatingStars = () => {
    const starRating = environmentDetails.isAndroid ? androidRating : iosRating;
    const starRatingNodes = [];
    for (
      let starCount = 0;
      starCount < starRating && starCount < 5;
      starCount += 1
    ) {
      starRatingNodes.push(
        <IconStarFilled title="Star Filled" color="upgrade" />
      );
    }
    for (let starCount = starRating; starCount < 5; starCount += 1) {
      starRatingNodes.push(<IconStar title="Empty Star" />);
    }
    return starRatingNodes;
  };

  const getRatingText = () => {
    const starRatingText = environmentDetails.isAndroid
      ? androidRatingText
      : iosRatingText;
    return (
      <Typography component="div" variant="cardTitle">
        {starRatingText}
      </Typography>
    );
  };

  return isUserEligibleState ? (
    <Sheet
      show={showBanner}
      blocking={false}
      placement="bottom"
      onClose={handleOnClose}
      showCloseButton
    >
      <Box p={1}>
        <Grid>
          <Grid.Item xs={2}>
            <div className={logoAlign}>
              <IconLogoGoldie
                title="SurveyMonkey Logo"
                size="xl"
                color="primary"
              />
            </div>
          </Grid.Item>
          <Grid.Item>
            <Grid noGutters>
              <Grid.Item>
                <Box display="flex" flexJustify="center" flexAlign="flex-start">
                  {getRatingText()}
                  &nbsp;
                  <div>{getRatingStars()}</div>
                </Box>
              </Grid.Item>
              <Grid.Item>
                <Box display="flex" flexJustify="center" flexAlign="center">
                  <Button label={installText} onClick={handleInstallClick}>
                    {installText}
                  </Button>
                  <Button
                    label={deepLinkText}
                    color="alt"
                    onClick={handleOpenClick}
                  >
                    {deepLinkText}
                  </Button>
                </Box>
              </Grid.Item>
            </Grid>
          </Grid.Item>
        </Grid>
      </Box>
    </Sheet>
  ) : (
    <></>
  );
};

export default MobileBanner;
