import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  refreshText: {
    pointerEvents: 'none',
    fontWeight: 'normal',
  },
});

export default useStyles;
