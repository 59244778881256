import { createURL } from '@sm/utils';
import { t } from '@sm/intl';
import COPY from './getFooterCopy';

import { FooterVariant } from '../../SMFooterContext';

import { getHelpCenterLink } from '../../../..';
import {
  showNewsRoom,
  showDachPaymentLinks,
  showImprintLink,
  showBlog,
} from '../../../../helpers/languageHelpers';

export const getSlimLinks = (lang: string) => [
  {
    title: t(COPY.ABOUT_SURVEYMONKEY),
    url: createURL('/about/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.PRIVACY_NOTICE),
    url: createURL('/mp/legal/privacy/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.CALIFORNIA_PRIVACY_NOTICE),
    url: createURL('/mp/legal/region-specific-privacy-statement/', {
      ut_source: 'footer',
    }),
    anchorAttributes: { rel: 'nofollow' },
  },
  ...(lang === 'de'
    ? [
        {
          title: 'Impressum',
          url: createURL('/about/impressum/', { ut_source: 'footer' }),
        },
      ]
    : []),
  {
    title: t(COPY.EMAIL_OPT_IN),
    url: createURL('/user/email-opt-in/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.OFFICE_LOCATIONS),
    url: createURL('/about/office-locations/', { ut_source: 'footer' }),
    lastItem: true,
  },
];

export const getCommunityLinks = (domain: string, lang: string) => [
  {
    title: t(COPY.COMMUNITY),
    url: 'https://www.facebook.com/surveymonkey/',
    isTitle: true,
  },
  ...(showBlog(lang)
    ? [
        {
          title: t(COPY.OUR_BLOG),
          url: createURL('/curiosity/', { ut_source: 'footer' }),
        },
      ]
    : []),
  {
    title: 'Facebook',
    url: 'https://www.facebook.com/surveymonkey/',
  },
  {
    title: 'Twitter',
    url: 'https://twitter.com/SurveyMonkey',
  },
  {
    title: 'LinkedIn',
    url: 'https://www.linkedin.com/company/surveymonkey/',
  },
  {
    title: 'Instagram',
    url: 'https://www.instagram.com/surveymonkey/',
  },
  {
    title: 'YouTube',
    url: 'https://www.youtube.com/surveymonkey',
  },
  {
    title: t(COPY.DEVELOPERS),
    url: createURL(`https://developer.${domain}.com`, {
      ut_source: 'footer',
    }),
    lastItem: true,
  },
];

export const getAboutUsLinks = (
  domain: string,
  lang: string,
  country: string
) => [
  {
    title: t(COPY.ABOUT_US),
    url: createURL('/about/', { ut_source: 'footer' }),
    isTitle: true,
  },
  {
    title: t(COPY.LEADERSHIP_TEAM),
    url: createURL('/about/leadership/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.APP_DIRECTORY),
    url: createURL('/apps/', { ut_source: 'footer' }),
  },
  ...(showNewsRoom(lang, country)
    ? [
        {
          title: t(COPY.NEWSROOM),
          url: createURL('/newsroom/', { ut_source: 'footer' }),
        },
      ]
    : []),
  {
    title: t(COPY.OFFICE_LOCATIONS),
    url: createURL('/about/office-locations/', { ut_source: 'footer' }),
  },
  ...(showDachPaymentLinks(lang, country)
    ? [
        {
          title: 'Impressum',
          url: createURL('/about/impressum/', { ut_source: 'footer' }),
        },
      ]
    : []),
  ...(showDachPaymentLinks(lang, country)
    ? [
        {
          title: t(COPY.PAYMENT_METHODS),
          url: getHelpCenterLink(lang, {
            path: 'billing/payment-methods',
            params: { ut_source: 'footer' },
          }),
        },
      ]
    : []),
  ...(showImprintLink(lang, country)
    ? [
        {
          title: t(COPY.IMPRINT),
          url: createURL(`${domain}about/imprint/`, { ut_source: 'footer' }),
        },
      ]
    : []),
  {
    title: t(COPY.CAREERS),
    url: createURL('/careers/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.SITEMAP),
    url: createURL('/mp/sitemap/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.HELP),
    url: getHelpCenterLink(lang, {
      params: { ut_source: 'footer' },
    }),
    lastItem: true,
  },
];

export const getPolicesLink = (lang: string) => [
  {
    title: t(COPY.POLICIES),
    url: createURL('/mp/legal/', { ut_source: 'footer' }),
    isTitle: true,
  },
  {
    title: t(COPY.TERMS_OF_USE),
    url: createURL('/mp/legal/terms-of-use/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.PRIVACY_NOTICE),
    url: createURL('/mp/legal/privacy/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.CALIFORNIA_PRIVACY_NOTICE),
    url: createURL('/mp/legal/region-specific-privacy-statement/', {
      ut_source: 'footer',
    }),
    anchorAttributes: { rel: 'nofollow' },
  },
  {
    title: t(COPY.ACCEPTABLE_USES_POLICY),
    url: createURL('/mp/legal/acceptable-uses-policy/', {
      ut_source: 'footer',
    }),
  },
  {
    title: t(COPY.SECURITY_STATEMENT),
    url: createURL('/mp/legal/security/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.GDPR_COMPLIANCE),
    url: createURL('/mp/privacy/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.EMAIL_OPT_IN),
    url: createURL('/user/email-opt-in/', { ut_source: 'footer' }),
  },
  {
    title: t(COPY.ACCESSIBILITY),
    url: getHelpCenterLink(lang, {
      path: 'create/accessibility',
      params: { ut_source: 'footer' },
    }),
  },
  {
    title: t(COPY.COOKIES_NOTICE),
    url: createURL('/mp/legal/cookies/', { ut_source: 'footer' }),
  },
];

export const getAnonCategories = (
  domain = '',
  lang = '',
  country = '',
  variant?: FooterVariant
) => {
  const defaultLinks = [
    getCommunityLinks(domain, lang),
    getAboutUsLinks(domain, lang, country),
    getPolicesLink(lang),
  ];
  const slimLinks = [getSlimLinks(lang)];
  return variant === 'default' ? defaultLinks : slimLinks;
};
