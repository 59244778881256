import React, { useContext } from 'react';
import { Accordion } from '@wds/accordion';
import PropTypes from 'prop-types';
import LogInCTA from '../../components/LogInCTA';
import SignUpCTA from '../../components/SignUpCTA';
import { MobileScreen } from '../../../..';

import { PlansAndPricingMenu } from './PlansAndPricingMenu';
import { HelpCenterMenu } from './HelpCenterMenu';
import { ProductsMenu } from './ProductsMenu';
import { SolutionsMenu } from './SolutionsMenu';
import { ResourcesMenu } from './ResourcesMenu';

import { MenusWithoutSubMenu } from './components/MenusWithoutSubMenu';

import SMHeaderContext from '../../SMHeaderContext';

import { showBlog } from '../../../../helpers/languageHelpers';

import useMobileMenuStyles from './useMobileMenuStyles';

import {
  productsMenu,
  solutionsMenu,
  resourcesMenu,
} from '../../config/linkInfo.js';
import JoinTeamCTA from '../../components/JoinTeamCTA';

export const MobileAnon = ({ joinTeamSlug }) => {
  const { lang } = useContext(SMHeaderContext);

  const { MobileMenuAnon } = useMobileMenuStyles();

  return (
    <>
      <Accordion
        noRules
        interactive
        className={MobileMenuAnon}
        data-testid="MobileMenuAnon"
      >
        <Accordion.Item title={productsMenu().title}>
          <ProductsMenu />
        </Accordion.Item>
        <Accordion.Item title={solutionsMenu().title}>
          <div data-testid="Solutions-Menu">
            <SolutionsMenu />
          </div>
        </Accordion.Item>
        {showBlog(lang) ? (
          <Accordion.Item title={resourcesMenu().title}>
            <ResourcesMenu />
          </Accordion.Item>
        ) : null}
      </Accordion>

      <MenusWithoutSubMenu>
        {!showBlog(lang) ? <HelpCenterMenu /> : null}
        <PlansAndPricingMenu />
      </MenusWithoutSubMenu>

      <MobileScreen>
        <div className={MobileMenuAnon}>
          <SignUpCTA
            size="md"
            variant="solid"
            color="upgrade"
            stretched
            className="sm-mobile-menu-anon__cta"
          />
          <LogInCTA
            size="md"
            variant="solid"
            color="primary"
            stretched
            className="sm-mobile-menu-anon__cta"
          />
          {!!joinTeamSlug?.length && (
            <JoinTeamCTA
              size="md"
              variant="solid"
              color="primary"
              stretched
              className="sm-mobile-menu-anon__cta"
              joinTeamSlug={joinTeamSlug}
            />
          )}
        </div>
      </MobileScreen>
    </>
  );
};

MobileAnon.propTypes = {
  joinTeamSlug: PropTypes.string,
};

MobileAnon.defaultProps = {
  joinTeamSlug: undefined,
};

export default MobileAnon;
