import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { IconChevronDown, IconChevronUp } from '@wds/icons';
import classNames from 'classnames';
import { NavBarAnonContext } from '../NavBarAnonContext.js';

import SMHeaderContext from '../../../SMHeaderContext';

export const Menu = ({ path, title, menuKey, children, name, onClick }) => {
  const { color, submenuOpened, toggleSubmenu } = useContext(NavBarAnonContext);
  const { excludeMenuItems } = useContext(SMHeaderContext);

  const linksClassname = classNames(
    `sm-header__nav-cta--${color}`,
    'sm-header__text',
    { 'sm-header--open': submenuOpened === menuKey }
  );

  if (excludeMenuItems.includes(name)) return null;

  return (
    <>
      {path === '' ? (
        <Box display="inline-block" key={menuKey}>
          <Box className={linksClassname}>
            <Button
              variant="text"
              color={color}
              onClick={() => {
                onClick?.();
                toggleSubmenu(menuKey);
              }}
            >
              {title}
              {submenuOpened === menuKey ? (
                <IconChevronUp size="xs" />
              ) : (
                <IconChevronDown size="xs" />
              )}
            </Button>
          </Box>
          {children}
        </Box>
      ) : (
        <Box
          display="inline-block"
          className={`sm-header__nav-cta--${color} sm-header__text`}
        >
          <Button
            key={menuKey}
            variant="text"
            color={color}
            href={path}
            onClick={onClick}
          >
            {title}
          </Button>
        </Box>
      )}
    </>
  );
};

Menu.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string.isRequired,
  menuKey: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
Menu.defaultProps = {
  path: '',
  onClick: undefined,
};

export default Menu;
