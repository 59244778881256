import { createMemoStyles, Theme } from '@wds/styles';

const useStyles = createMemoStyles(
  ({ palette, spacing, elevation, type, border }: Theme) => ({
    fullHeight: {
      height: '100%',
    },
    footerAnon: {
      position: 'relative',
      zIndex: elevation.sky,
      backgroundColor: palette.background.light,
      padding: [spacing[7], 0],
      color: palette.text.dark,
      fontSize: type.fontSize.bodySm,
      borderTop: border.main,
    },
    footerAuth: {
      position: 'relative',
      zIndex: elevation.sky,
      backgroundColor: palette.background.light,
      padding: [spacing[7], 0],
      color: palette.text.dark,
      fontSize: type.fontSize.bodySm,
    },
    footerBorder: {
      borderBottom: border.main,
    },
  })
);

export default useStyles;
