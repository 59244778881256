import { Typography } from '@wds/typography';
import { Box, BoxProps } from '@wds/box';
import React from 'react';
import { t, defineMessages } from '@sm/intl';
import useStyles from './useStyles';

const COPY = defineMessages({
  HOME: {
    id: 'Links.home',
    defaultMessage: 'Home',
    description: '[Type: Label][Vis.: med] - Home link in Error Message',
  },
  SITEMAP: {
    id: 'Links.sitemap',
    defaultMessage: 'Sitemap',
    description: '[Type: Label][Vis.: med] - Sitemap link in Error Message',
  },
  HELP_CENTER: {
    id: 'Links.helpCenter',
    defaultMessage: 'Help Center',
    description: '[Type: Label][Vis.: med] - Help Center link in Error Message',
  },
  TEMPLATES: {
    id: 'Links.templates',
    defaultMessage: 'Templates',
    description: '[Type: Label][Vis.: med] - Templates link in Error Message',
  },
  LEARN_MORE: {
    id: 'Links.learnMore',
    defaultMessage: 'Learn More',
    description:
      '[Type: Label][Vis.: med] - Learn More link in 404 Error Message',
  },
});

const defaultLinks = [
  {
    href: '/',
    label: t(COPY.HOME),
  },
  {
    href: '/mp/sitemap/',
    label: t(COPY.SITEMAP),
  },
  {
    href: 'https://help.surveymonkey.com/',
    label: t(COPY.HELP_CENTER),
  },
  {
    href: '/mp/survey-templates/',
    label: t(COPY.TEMPLATES),
  },
  {
    href: '/mp/take-a-tour/',
    label: t(COPY.LEARN_MORE),
  },
];

export type Link = {
  href: string;
  label: string;
};

export type LinksProps = {
  links?: Link[];
} & BoxProps;

const Links = ({
  links = defaultLinks,
  ...containerBoxProps
}: LinksProps): React.ReactElement => {
  const { linkList, linkItem: linkStyles, linkAnchor } = useStyles();
  return (
    <Box {...containerBoxProps}>
      <Typography component="div" variant="bodySm" align="center">
        <nav>
          <ul className={linkList}>
            {links.map(link => (
              <li
                className={linkStyles}
                key={link.label}
                data-testid="ClientError_Link"
              >
                <a className={linkAnchor} href={link.href}>
                  {link.label}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </Typography>
    </Box>
  );
};
export default Links;
