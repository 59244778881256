import React from 'react';
import Menu from './components/Menu';

import { mobileHelpCenterMenu } from '../../config/linkInfo.js';

export const HelpCenterMenu = () => {
  return <Menu {...mobileHelpCenterMenu()} />;
};

export default HelpCenterMenu;
