import { createUseStyles } from 'react-jss';
import type { Theme } from '@wds/styles';

const useStyles = createUseStyles(({ border, palette }: Theme) => ({
  linkList: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  },
  linkItem: {
    '&:not(:last-child)': {
      '&::after': {
        content: '"|"',
        margin: '0 3px',
      },
    },
  },
  linkAnchor: {
    color: palette.link.main,
    textDecoration: 'none',
    fontWeight: 'inherit',
    fontSize: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:focus': {
      textDecoration: 'none',
      outlineStyle: 'auto',
      outlineWidth: '1px',
      outlineColor: border.hover,
      outlineOffset: '1px',
    },
  },
}));

export default useStyles;
