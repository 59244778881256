import React from 'react';
import PropTypes from 'prop-types';
import { clientErrorHandler } from '../..';

const ErrorBoundaryFallbackComponent = () => {
  return null;
};

const formatComponentStack = str => {
  const lines = str.split(/\s*\n\s*/g);
  let ret = '';
  for (let line = 0, len = lines.length; line < len; line += 1) {
    if (lines[line].length) ret += `${ret.length ? '\n' : ''}${lines[line]}`;
  }
  return ret;
};

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    FallbackComponent: PropTypes.any, // eslint-disable-line
    children: PropTypes.node.isRequired,
  };

  static defaultProps = {
    FallbackComponent: ErrorBoundaryFallbackComponent,
  };

  state = {
    error: null,
    info: null,
  };

  componentDidCatch(error, information) {
    const info = information;
    if (info && info.componentStack) {
      info.componentStack = formatComponentStack(info.componentStack);
    }
    clientErrorHandler.logError(error, info, {
      fallback:
        this.props.FallbackComponent && this.props.FallbackComponent.name,
    });
    return this.setState({ error, info });
  }

  render() {
    const { children, FallbackComponent } = this.props;
    const { error, info } = this.state;
    if (error) {
      return <FallbackComponent error={error} info={info} />;
    }
    return children;
  }
}
