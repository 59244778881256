import { t } from '@sm/intl';
import { createURL } from '@sm/utils';
import NavSubmenuCopy from '../copy/NavSubmenuCopy';
import NavBarCopy from '../copy/NavBarCopy';
import { usabillaLink } from '../../../helpers/languageHelpers';
import { getHelpCenterLink } from '../../../helpers/getHelpLink';

// Main Title Links

export const productsMenu = () => ({
  name: 'anon_products_menu',
  title: t(NavBarCopy.PRODUCTS),
});

export const solutionsMenu = () => ({
  name: 'anon_solutions_menu',
  title: t(NavBarCopy.SOLUTIONS),
});

export const resourcesMenu = () => ({
  name: 'anon_resources_menu',
  title: t(NavBarCopy.RESOURCES),
});

export const plansPricingMenu = () => ({
  name: 'anon_plans_pricing_menu',
  title: t(NavBarCopy.PLANS_AND_PRICING),
  url: createURL('/pricing/', { ut_source: 'megamenu' }),
});

export const helpCenterMenu = () => ({
  name: 'anon_help_center_menu',
  title: t(NavBarCopy.HELP_CENTER),
  url: getHelpCenterLink('en', {
    params: {
      ut_source: 'megamenu',
    },
  }),
});

export const mobileHelpCenterMenu = lang => ({
  name: 'anon_mobile_help_center_menu',
  title: t(NavBarCopy.HELP_CENTER),
  url: getHelpCenterLink(lang, {
    params: {
      ut_source: 'megamenu',
    },
  }),
});

// Column Title Links

export const surveys = () => ({
  name: 'anon_surveys',
  title: t(NavSubmenuCopy.SURVEYS),
});

export const none = () => ({
  name: 'anon_no_column_title',
  title: '',
});

export const specializedProducts = () => ({
  name: 'anon_specialized_products',
  title: t(NavSubmenuCopy.SPECIALIZED_PRODUCTS),
});

export const formsWorkFlows = () => ({
  name: 'anon_forms_workflows',
  title: t(NavSubmenuCopy.FORMS_AND_WORKFLOWS),
});

export const peoplePoweredData = () => ({
  name: 'anon_people_powered_data',
  title: t(NavSubmenuCopy.PEOPLE_POWERED_DATA),
});

export const solutionsForTeams = () => ({
  name: 'anon_solutions_for_teams',
  title: t(NavSubmenuCopy.SOLUTIONS_FOR_TEAMS),
});

// SubMenu Links

export const surveymonkey = () => ({
  name: 'anon_surveymonkey',
  title: 'SurveyMonkey',
  subTitle: t(NavSubmenuCopy.SURVEYMONKEY_SUBTITLE),
  url: createURL('/mp/take-a-tour/', { ut_source: 'megamenu' }),
});

export const enterprise = () => ({
  name: 'anon_enterprise',
  title: 'Enterprise',
  subTitle: t(NavSubmenuCopy.ENTERPRISE_SUBTITLE),
  url: createURL('/mp/enterprise/', { ut_source: 'megamenu' }),
});

export const integrations = () => ({
  name: 'anon_integrations_plugins',
  title: t(NavSubmenuCopy.INTEGRATIONS_PLUGINS),
  subTitle: t(NavSubmenuCopy.INTEGRATIONS_PLUGINS_SUBTITLE),
  url: createURL('/apps/', { ut_source: 'megamenu' }),
});

export const audience = () => ({
  name: 'anon_audience',
  title: 'Audience',
  subTitle: t(NavSubmenuCopy.AUDIENCE_SUBTITLE),
  url: createURL('/market-research/solutions/audience-panel/', {
    ut_source: 'megamenu',
  }),
  excludeForEUDC: true,
});

export const cx = () => ({
  name: 'anon_cx',
  title: 'CX',
  subTitle: t(NavSubmenuCopy.CX_SUBTITLE),
  url: createURL('/cx/', { ut_source: 'megamenu' }),
});

export const engage = () => ({
  name: 'anon_engage',
  title: 'Engage',
  subTitle: t(NavSubmenuCopy.ENGAGE_SUBTITLE),
  url: createURL('https://www.surveymonkey.com/engage/', {
    ut_source: 'megamenu',
  }),
});

export const usabilla = lang => ({
  name: 'anon_usabilla',
  title: 'Usabilla',
  subTitle: t(NavSubmenuCopy.USABILLA_SUBTITLE),
  url: createURL(usabillaLink(lang), { utm_source: 'megamenu' }),
});

export const techValidate = () => ({
  name: 'anon_techvalidate',
  title: 'TechValidate',
  subTitle: t(NavSubmenuCopy.TECH_VALIDATE_SUBTITLE),
  url: createURL('https://www.surveymonkey.com/techvalidate/', {
    ut_source: 'megamenu',
  }),
});

export const apply = () => ({
  name: 'anon_apply',
  title: 'Apply',
  subTitle: t(NavSubmenuCopy.APPLY_SUBTITLE),
  url: createURL('https://apply.surveymonkey.com/', {
    ut_source: 'megamenu',
  }),
});

export const wufoo = () => ({
  name: 'anon_wufoo',
  title: 'Wufoo',
  subTitle: t(NavSubmenuCopy.WUFOO_SUBTITLE),
  url: createURL('https://www.wufoo.com/', { ut_source: 'megamenu' }),
});

export const getFeedback = () => ({
  name: 'anon_getfeedback',
  title: 'GetFeedback',
  subTitle: t(NavSubmenuCopy.GET_FEEDBACK_SUBTITLE),
  url: createURL('https://www.getfeedback.com/', {
    utm_source: 'surveymonkey',
  }),
  openInNewWindow: true,
});

export const customerSatisfaction = () => ({
  name: 'anon_customer_satisfaction',
  title: t(NavSubmenuCopy.CUSTOMER_SATISFACTION),
  url: createURL('/mp/customer-satisfaction-surveys/', {
    ut_source: 'megamenu',
  }),
});

export const customerLoyalty = () => ({
  name: 'anon_customer_loyalty',
  title: t(NavSubmenuCopy.CUSTOMER_LOYALTY),
  url: createURL('/mp/customer-loyalty-surveys/', { ut_source: 'megamenu' }),
});

export const eventSurveys = () => ({
  name: 'anon_event_surveys',
  title: t(NavSubmenuCopy.EVENT_SURVEYS),
  url: createURL('/mp/event-planning-surveys/', { ut_source: 'megamenu' }),
});

export const employeeEngagement = () => ({
  name: 'anon_employee_engagement',
  title: t(NavSubmenuCopy.EMPLOYEE_ENGAGEMENT),
  url: createURL('/mp/employee-surveys/', { ut_source: 'megamenu' }),
});

export const jobSatisfaction = () => ({
  name: 'anon_job_satisfaction',
  title: t(NavSubmenuCopy.JOB_SATISFACTION),
  url: createURL('/mp/job-satisfaction-survey/', { ut_source: 'megamenu' }),
});

export const hrSurveys = () => ({
  name: 'anon_hr_surveys',
  title: t(NavSubmenuCopy.HR_SURVEYS),
  url: createURL('/mp/human-resource-surveys/', { ut_source: 'megamenu' }),
});

export const marketResearchSurveys = () => ({
  name: 'anon_market_research_surveys',
  title: t(NavSubmenuCopy.MARKET_RESEARCH),
  url: createURL('/mp/market-research-surveys/', { ut_source: 'megamenu' }),
});

export const opinionPolls = () => ({
  name: 'anon_opinion_polls',
  title: t(NavSubmenuCopy.OPINION_POLLS),
  url: createURL('/mp/online-polls/', { ut_source: 'megamenu' }),
});

export const conceptTesting = () => ({
  name: 'anon_concept_testing',
  title: t(NavSubmenuCopy.CONCEPT_TESTING),
  url: createURL('/mp/concept-testing/', { ut_source: 'megamenu' }),
});

export const customerFeedback = () => ({
  name: 'anon_customer_feedback',
  title: t(NavSubmenuCopy.CUSTOMERS),
  subTitle: t(NavSubmenuCopy.CUSTOMERS_SUBTITLE),
  url: createURL('/mp/customer-feedback/', { ut_source: 'megamenu' }),
});

export const employeeFeedback = () => ({
  name: 'anon_employee_feedback',
  title: t(NavSubmenuCopy.EMPLOYEES),
  subTitle: t(NavSubmenuCopy.EMPLOYEES_SUBTITLE),
  url: createURL('/mp/employee-feedback/', { ut_source: 'megamenu' }),
});

export const marketResearch = () => ({
  name: 'anon_market_research',
  title: t(NavSubmenuCopy.MARKETS),
  subTitle: t(NavSubmenuCopy.MARKETS_SUBTITLE),
  url: createURL('/market-research/', { ut_source: 'megamenu' }),
});

export const customerExperience = () => ({
  name: 'anon_customer_experience',
  title: t(NavSubmenuCopy.CUSTOMER_EXPERIENCE),
  subTitle: t(NavSubmenuCopy.CUSTOMER_EXPERIENCE_SUBTITLE),
  url: createURL('/mp/customer-experience-solutions/', {
    ut_source: 'megamenu',
  }),
});

export const humanResources = () => ({
  name: 'anon_human_resources',
  title: t(NavSubmenuCopy.HUMAN_RESOURCES),
  subTitle: t(NavSubmenuCopy.HUMAN_RESOURCES_SUBTITLE),
  url: createURL('/mp/hr-solutions/', { ut_source: 'megamenu' }),
});

export const marketing = () => ({
  name: 'anon_marketing',
  title: t(NavSubmenuCopy.MARKETING),
  subTitle: t(NavSubmenuCopy.MARKETING_SUBTITLE),
  url: createURL('/mp/marketing-solutions/', { ut_source: 'megamenu' }),
});

export const resources = () => ({
  name: 'anon_resources',
  title: t(NavSubmenuCopy.RESOURCES),
  subTitle: t(NavSubmenuCopy.RESOURCES_SUBTITLE),
  url: createURL('/resources/', { ut_source: 'megamenu' }),
});

export const blog = () => ({
  name: 'anon_blog',
  title: t(NavSubmenuCopy.BLOG),
  subTitle: t(NavSubmenuCopy.BLOG_SUBTITLE),
  url: createURL('/curiosity/', { ut_source: 'megamenu' }),
});

export const helpCenter = lang => ({
  name: 'anon_help_center',
  title: t(NavSubmenuCopy.HELP_CENTER),
  subTitle: t(NavSubmenuCopy.HELP_CENTER_SUBTITLE),
  url: getHelpCenterLink(lang, { params: { ut_source: 'megamenu' } }),
});

// Bottom Links

export const viewAllProducts = lang => ({
  name: 'anon_view_all_products',
  title:
    lang === 'en'
      ? t(NavSubmenuCopy.ALL_PRODUCTS_LINK)
      : t(NavSubmenuCopy.PRODUCTS_LINK),
  alignment: lang === 'en' ? 'left' : 'right',
  url: createURL('/mp/take-a-tour/', {
    ut_source: 'megamenu',
  }),
});

export const addIntegrations = () => ({
  name: 'anon_integrations_plugins',
  title: t(NavSubmenuCopy.INTEGRATIONS_PLUGINS_LINK),
  alignment: 'left',
  url: createURL('/apps/', { ut_source: 'megamenu' }),
});

export const exploreSurveyTypes = lang => ({
  name: 'anon_explore_survey_types',
  title:
    lang === 'en'
      ? t(NavSubmenuCopy.VIEW_MORE_SURVEY_TYPES_LINK)
      : t(NavSubmenuCopy.VIEW_MORE_LINK),
  alignment: lang === 'en' ? 'left' : 'right',
  url: createURL('/mp/survey-types/', { ut_source: 'megamenu' }),
});

export const exploreSurveyTemplates = () => ({
  name: 'anon_explore_survey_templates',
  title: t(NavSubmenuCopy.EXPLORE_SURVEY_TEMPLATES_LINK),
  alignment: 'right',
  url: createURL('/mp/sample-survey-questionnaire-templates/', {
    ut_source: 'megamenu',
  }),
});

/**
 * @deprecated
 * To be used for js files of the legacy header
 * the new header can use VALID_MENU_NAME_LIST
 * in src/components/Header/wrench/types.ts
 */
export const ValidMenuNameList = [
  'anon_products_menu',
  'anon_solutions_menu',
  'anon_resources_menu',
  'anon_plans_pricing_menu',
  'anon_help_center_menu',

  'anon_surveymonkey',
  'anon_enterprise',
  'anon_integrations_plugins',
  'anon_audience',
  'anon_cx',
  'anon_engage',
  'anon_usabilla',
  'anon_techvalidate',
  'anon_apply',
  'anon_wufoo',
  'anon_getfeedback',

  'anon_customer_satisfaction',
  'anon_customer_loyalty',
  'anon_event_surveys',
  'anon_employee_engagement',
  'anon_job_satisfaction',
  'anon_hr_surveys',
  'anon_market_research_surveys',
  'anon_opinion_polls',
  'anon_concept_testing',
  'anon_customer_feedback',
  'anon_employee_feedback',
  'anon_market_research',
  'anon_customer_experience',
  'anon_human_resources',
  'anon_marketing',

  'anon_resources',
  'anon_blog',
  'anon_help_center',
];
