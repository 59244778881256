import { defineMessages } from '@sm/intl';

export default defineMessages({
  PRODUCTS_TITLE: {
    id: 'NavSubmenuCopy.productsTitle',
    defaultMessage: 'Power your curiosity with our data platforms',
    description: '[Type: Label][Vis.: low] - Products title of NavBar submenu',
  },
  SOLUTIONS_TITLE: {
    id: 'NavSubmenuCopy.solutionsTitle',
    defaultMessage: 'Drive business forward with People Powered Data',
    description: '[Type: Label][Vis.: low] - Solutions title of NavBar submenu',
  },
  RESOURCES_TITLE: {
    id: 'NavSubmenuCopy.resourcesTitle',
    defaultMessage: 'Seek the inspiration and expertise you need',
    description: '[Type: Label][Vis.: low] - Resources title of NavBar submenu',
  },
  SURVEYS: {
    id: 'NavSubmenuCopy.surveys',
    defaultMessage: 'Surveys',
    description: '[Type: Label][Vis.: low] - Surveys section of NavBar submenu',
  },
  SPECIALIZED_PRODUCTS: {
    id: 'NavSubmenuCopy.specializedProducts',
    defaultMessage: 'Specialized products',
    description:
      '[Type: Label][Vis.: low] - Specialized Products section of NavBar submenu',
  },
  FORMS_AND_WORKFLOWS: {
    id: 'NavSubmenuCopy.formsWorkflows',
    defaultMessage: 'Forms and workflows',
    description:
      '[Type: Label][Vis.: low] - Forms and Workflows section of NavBar submenu',
  },
  SURVEY_TYPES: {
    id: 'NavSubmenuCopy.surveyTypes',
    defaultMessage: 'Survey Types',
    description:
      '[Type: Label][Vis.: low] - SurveyTypes section in NavBar submenu',
  },
  PEOPLE_POWERED_DATA: {
    id: 'NavSubmenuCopy.peoplePoweredData',
    defaultMessage: 'People Powered Data for business',
    description:
      '[Type: Label][Vis.: low] - People Powered Data section in NavBar submenu',
  },
  SOLUTIONS_FOR_TEAMS: {
    id: 'NavSubmenuCopy.solutionsForTeams',
    defaultMessage: 'Solutions for teams',
    description:
      '[Type: Label][Vis.: low] - SolutionsForTeams section in NavBar submenu',
  },
  SURVEYMONKEY_SUBTITLE: {
    id: 'NavSubmenuCopy.surveyMonkeySubtitle',
    defaultMessage:
      'Create & send surveys with the world’s #1 online survey software',
    description:
      '[Type: Label][Vis.: low] - SurveyMonkey subtitle of NavBar submenu',
  },
  ENTERPRISE_SUBTITLE: {
    id: 'NavSubmenuCopy.enterpriseSubtitle',
    defaultMessage: 'Empower your organization with our secure survey platform',
    description:
      '[Type: Label][Vis.: low] - Enterprise Subtitle in NavBar submenu',
  },
  INTEGRATIONS_PLUGINS: {
    id: 'NavSubmenuCopy.integrationsPlugins',
    defaultMessage: 'Integrations & plug-ins',
    description:
      '[Type: Label][Vis.: low] - Integrations and plugins section of NavBar submenu',
  },
  INTEGRATIONS_PLUGINS_SUBTITLE: {
    id: 'NavSubmenuCopy.integrationsPluginsSubtitle',
    defaultMessage: 'Bring survey insights into your business apps',
    description:
      '[Type: Label][Vis.: low] - Integrations and plugins subtitle in NavBar submenu',
  },
  AUDIENCE_SUBTITLE: {
    id: 'NavSubmenuCopy.audienceSubtitle',
    defaultMessage: 'Collect survey responses from our global consumer panel',
    description:
      '[Type: Label][Vis.: low] - Audience subtitle of NavBar submenu',
  },
  CX_SUBTITLE: {
    id: 'NavSubmenuCopy.cxSubtitle',
    defaultMessage: 'Understand & improve customer experience (NPS®)',
    description: '[Type: Label][Vis.: low] - CX subtitle in NavBar submenu',
  },
  ENGAGE_SUBTITLE: {
    id: 'NavSubmenuCopy.engageSubtitle',
    defaultMessage: 'Measure & increase employee engagement',
    description: '[Type: Label][Vis.: low] - Engage subtitle in NavBar submenu',
  },
  USABILLA_SUBTITLE: {
    id: 'NavSubmenuCopy.usabillaSubtitle',
    defaultMessage: 'Get in-the-moment feedback across all digital channels',
    description: '[Type: Label][Vis.: low] - Usabilla label of NavBar submenu',
  },
  TECH_VALIDATE_SUBTITLE: {
    id: 'NavSubmenuCopy.techValidateSubtitle',
    defaultMessage: 'Create marketing content from customer feedback',
    description:
      '[Type: Label][Vis.: low] - Tech Validate subtitle in NavBar submenu',
  },
  APPLY_SUBTITLE: {
    id: 'NavSubmenuCopy.applySubtitle',
    defaultMessage: 'Collect, review & manage applications online',
    description: '[Type: Label][Vis.: low] - Apply Subtitle of NavBar submenu',
  },
  WUFOO_SUBTITLE: {
    id: 'NavSubmenuCopy.wufooSubtitle',
    defaultMessage: 'Gather data & accept payments with online forms',
    description: '[Type: Label][Vis.: low] - Wufoo Subtitle of NavBar submenu',
  },
  GET_FEEDBACK_SUBTITLE: {
    id: 'NavSubmenuCopy.getFeedbackSubtitle',
    defaultMessage: 'Customer feedback for Salesforce',
    description:
      '[Type: Label][Vis.: low] - GetFeedback Subtitle of NavBar submenu',
  },
  CUSTOMER_SATISFACTION: {
    id: 'NavSubmenuCopy.customerSatisfaction',
    defaultMessage: 'Customer Satisfaction',
    description:
      '[Type: Label][Vis.: low] - Customer Satisfaction section in NavBar submenu',
  },
  CUSTOMER_LOYALTY: {
    id: 'NavSubmenuCopy.customerLoyalty',
    defaultMessage: 'Customer Loyalty',
    description:
      '[Type: Label][Vis.: low] - Customer Loyalty section in NavBar submenu',
  },
  EVENT_SURVEYS: {
    id: 'NavSubmenuCopy.eventSurveys',
    defaultMessage: 'Event Surveys',
    description:
      '[Type: Label][Vis.: low] - Event Surveys section in NavBar submenu',
  },
  EMPLOYEE_ENGAGEMENT: {
    id: 'NavSubmenuCopy.employeeEngagement',
    defaultMessage: 'Employee Engagement',
    description:
      '[Type: Label][Vis.: low] - Employee Engagement section in NavBar submenu',
  },
  JOB_SATISFACTION: {
    id: 'NavSubmenuCopy.jobSatisfaction',
    defaultMessage: 'Job Satisfaction',
    description:
      '[Type: Label][Vis.: low] - Job Satisfaction section in NavBar submenu',
  },
  HR_SURVEYS: {
    id: 'NavSubmenuCopy.HRSurveys',
    defaultMessage: 'HR Surveys',
    description:
      '[Type: Label][Vis.: low] - HR Surveys section in NavBar submenu',
  },
  MARKET_RESEARCH: {
    id: 'NavSubmenuCopy.marketResearch',
    defaultMessage: 'Market Research',
    description:
      '[Type: Label][Vis.: low] - Market Research section in NavBar submenu',
  },
  OPINION_POLLS: {
    id: 'NavSubmenuCopy.opinionPolls',
    defaultMessage: 'Opinion Polls',
    description:
      '[Type: Label][Vis.: low] - Opinion Polls section in NavBar submenu',
  },
  CONCEPT_TESTING: {
    id: 'NavSubmenuCopy.conceptTesting',
    defaultMessage: 'Concept Testing',
    description:
      '[Type: Label][Vis.: low] - Concept Testing section in NavBar submenu',
  },
  CUSTOMERS: {
    id: 'NavSubmenuCopy.customers',
    defaultMessage: 'Customers',
    description:
      '[Type: Label][Vis.: low] - Customers Section in NavBar submenu',
  },
  CUSTOMERS_SUBTITLE: {
    id: 'NavSubmenuCopy.customersSubtitle',
    defaultMessage: 'Win more business with Customer Powered Data',
    description:
      '[Type: Label][Vis.: low] - Customers subtitle in NavBar submenu',
  },
  EMPLOYEES: {
    id: 'NavSubmenuCopy.employees',
    defaultMessage: 'Employees',
    description:
      '[Type: Label][Vis.: low] - Employees Section in NavBar submenu',
  },
  EMPLOYEES_SUBTITLE: {
    id: 'NavSubmenuCopy.employeesSubtitle',
    defaultMessage: 'Build a stronger workforce with Employee Powered Data',
    description:
      '[Type: Label][Vis.: low] - Employees subtitle in NavBar submenu',
  },
  MARKETS: {
    id: 'NavSubmenuCopy.markets',
    defaultMessage: 'Markets',
    description: '[Type: Label][Vis.: low] - Markets Section in NavBar submenu',
  },
  MARKETS_SUBTITLE: {
    id: 'NavSubmenuCopy.marketsSubtitle',
    defaultMessage: 'Validate business strategy with Market Powered Data',
    description:
      '[Type: Label][Vis.: low] - Markets subtitle in NavBar submenu',
  },
  CUSTOMER_EXPERIENCE: {
    id: 'NavSubmenuCopy.customerExperience',
    defaultMessage: 'Customer Experience',
    description:
      '[Type: Label][Vis.: low] - Customer Experience Section in NavBar submenu',
  },
  CUSTOMER_EXPERIENCE_SUBTITLE: {
    id: 'NavSubmenuCopy.customerExperienceSubtitle',
    defaultMessage: 'Delight customers & increase loyalty through feedback',
    description:
      '[Type: Label][Vis.: low] - Customer Experience subtitle in NavBar submenu',
  },
  HUMAN_RESOURCES: {
    id: 'NavSubmenuCopy.humanResources',
    defaultMessage: 'Human Resources',
    description:
      '[Type: Label][Vis.: low] - Human Resources Section in NavBar submenu',
  },
  HUMAN_RESOURCES_SUBTITLE: {
    id: 'NavSubmenuCopy.humanResourcesSubtitle',
    defaultMessage: 'Improve your employee experience, engagement & retention',
    description:
      '[Type: Label][Vis.: low] - Human Resources subtitle in NavBar submenu',
  },
  MARKETING: {
    id: 'NavSubmenuCopy.marketing',
    defaultMessage: 'Marketing',
    description:
      '[Type: Label][Vis.: low] - Marketing Section in NavBar submenu',
  },
  MARKETING_SUBTITLE: {
    id: 'NavSubmenuCopy.marketingSubtitle',
    defaultMessage: 'Create winning campaigns, boost ROI & drive growth',
    description:
      '[Type: Label][Vis.: low] - Marketing subtitle in NavBar submenu',
  },
  RESOURCES: {
    id: 'NavSubmenuCopy.resources',
    defaultMessage: 'Resources',
    description:
      '[Type: Label][Vis.: low] - Resources Section in NavBar submenu',
  },
  RESOURCES_SUBTITLE: {
    id: 'NavSubmenuCopy.resourcesSubtitle',
    defaultMessage: 'Best practices for using surveys & survey data',
    description:
      '[Type: Label][Vis.: low] - Resources subtitle in NavBar submenu',
  },
  BLOG: {
    id: 'NavSubmenuCopy.blog',
    defaultMessage: 'Curiosity at Work',
    description: '[Type: Label][Vis.: low] - Blog section in NavBar submenu',
  },
  BLOG_SUBTITLE: {
    id: 'NavSubmenuCopy.blogSubtitle',
    defaultMessage: 'Our blog about surveys, tips for business, & more',
    description: '[Type: Label][Vis.: low] - Blog subtitle in NavBar submenu',
  },
  HELP_CENTER: {
    id: 'NavSubmenuCopy.helpCenter',
    defaultMessage: 'Help Center',
    description:
      '[Type: Label][Vis.: low] - Help Center section in NavBar submenu',
  },
  BY_NEED: {
    id: 'NavSubmenuCopy.byNeed',
    defaultMessage: 'By Need',
    description: '[Type: Label][Vis.: med] - By need label for Nav Submenu',
  },
  HELP_CENTER_SUBTITLE: {
    id: 'NavSubmenuCopy.helpCenterSubtitle',
    defaultMessage: 'Survey how-to guides & tutorials ',
    description:
      '[Type: Label][Vis.: low] - Help Center Subtitle in NavBar submenu',
  },
  // english products bottom link
  ALL_PRODUCTS_LINK: {
    id: 'NavSubmenuCopy.allProductsLink',
    defaultMessage: 'View all products',
    description:
      '[Type: Label][Vis.: low] - View all Products Link in NavBar submenu',
  },
  // non english products bottom link
  PRODUCTS_LINK: {
    id: 'NavSubmenuCopy.productsLink',
    defaultMessage: 'VIEW ALL PRODUCTS',
    description: '[Type: Label][Vis.: low] - Products Link in NavBar submenu',
  },
  INTEGRATIONS_PLUGINS_LINK: {
    id: 'NavSubmenuCopy.integrationsPluginsLink',
    defaultMessage: 'ADD INTEGRATIONS & PLUG-INS',
    description:
      '[Type: Label][Vis.: low] - Integrations and plugins Link in NavBar submenu',
  },
  // english solutions bottom link
  VIEW_MORE_SURVEY_TYPES_LINK: {
    id: 'NavSubmenuCopy.viewMoreSurveyTypes',
    defaultMessage: 'Explore more survey types',
    description:
      '[Type: Label][Vis.: low] - Explore more survey types link in NavBar submenu',
  },
  // non english solutions bottom link
  VIEW_MORE_LINK: {
    id: 'NavSubmenuCopy.viewMoreLink',
    defaultMessage: 'VIEW MORE',
    description: '[Type: Label][Vis.: low] - View More in NavBar submenu',
  },
  EXPLORE_SURVEY_TEMPLATES_LINK: {
    id: 'NavSubmenuCopy.exploreSurveyTemplates',
    defaultMessage: 'Explore our 180+ survey templates',
    description:
      '[Type: Label][Vis.: low] - Explore Survey Templates section in NavBar submenu',
  },
});
