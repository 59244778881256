import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

import SMHeaderContext from '../../../SMHeaderContext';

export const SolutionByNeed = ({ title, url, name }) => {
  const { excludeMenuItems } = useContext(SMHeaderContext);

  if (excludeMenuItems.includes(name)) return null;

  return (
    <List.Item key={title} transparent isNavigable navigationTarget={url}>
      <Box my={4} className="sm-nav-submenu__by-need">
        <Typography variant="link">{title}</Typography>
      </Box>
    </List.Item>
  );
};

export default SolutionByNeed;

SolutionByNeed.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};
