import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const NavBarAnonContext = createContext({});

const { Provider } = NavBarAnonContext;

const NavBarContext = props => (
  <Provider
    value={{
      ...props,
    }}
  >
    {props.children}
  </Provider>
);

NavBarContext.propTypes = {
  children: PropTypes.node.isRequired,
};

export { NavBarAnonContext, NavBarContext };
