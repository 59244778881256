import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { Sheet } from '@wds/sheet';
import { IconMenu, IconX } from '@wds/icons';

import useMobileDrawerStyles from './useMobileDrawerTriggerStyles';

export const MobileDrawer = ({ menuColor, children }) => {
  const [drawerOpen, setOpen] = useState(false);
  const toggleDrawer = () => setOpen(!drawerOpen);

  const { MobileTrigger } = useMobileDrawerStyles();

  const buttonClass = `${MobileTrigger} sm-mobile-menu__cta sm-mobile-menu--${menuColor}`;

  return (
    <>
      <Box className={buttonClass}>
        <Button
          variant="text"
          size="sm"
          onClick={toggleDrawer}
          data-testid="Mobile-Trigger"
        >
          {drawerOpen ? <IconX /> : <IconMenu />}
        </Button>
      </Box>
      <Sheet
        show={drawerOpen}
        onClose={toggleDrawer}
        placement="left"
        offsetTop={50}
      >
        {children}
      </Sheet>
    </>
  );
};

MobileDrawer.propTypes = {
  menuColor: PropTypes.oneOf(['alt', 'secondary']),
  children: PropTypes.node.isRequired,
};

MobileDrawer.defaultProps = {
  menuColor: 'alt',
};

export default MobileDrawer;
