import React, { ReactElement } from 'react';
import { isEnterprisePackage } from '@sm/utils';
import getPackageName from '../../../helpers/packageHelpers';
import { withWebAssetsTheme } from '../../../theme';
import useStyles from './useStyles';

const AccountTypeBadge = ({
  packageId,
  teamName,
}: {
  packageId: number;
  teamName?: string;
}): ReactElement => {
  const { badge } = useStyles({ packageId });
  return (
    <span data-testid="AccountTypeBadge" className={badge}>
      {!!teamName && isEnterprisePackage(packageId)
        ? teamName
        : getPackageName(packageId)}
    </span>
  );
};

export default withWebAssetsTheme(AccountTypeBadge);
