import React, { useContext } from 'react';
import { defineMessages, T } from '@sm/intl';
import { getClientEnvironmentDetails } from '@sm/utils';
import getFooterLanguageList from './helpers/getFooterLanguageList';
import { InlineList, InlineListItem } from '../InlineList';
import { SMFooterContext } from '../SMFooterContext';

const COPY = defineMessages({
  LANGUAGES: {
    id: 'LanguageListLO.languageMenu',
    defaultMessage: 'Language:',
    description:
      '[Type: Label][Vis.: med] - A label for logged out footer language list',
  },
});

const LanguageListLO = () => {
  const { subdomain, domain, tld = 'com', url } = useContext(SMFooterContext);

  // if SSR, build the url from the request
  const SSRUrl = `https://${subdomain}.${domain}.${tld}${url}`;

  const envDetails = getClientEnvironmentDetails();
  const location = new URL(
    envDetails.isBrowser ? window.location.href : SSRUrl
  );

  const languages = getFooterLanguageList(location.href, tld);
  const orderedLanguages = languages.sort((a, b) => (
    (!a.sortOrder ? 0 : a.sortOrder) > (!b.sortOrder ? 0 : b.sortOrder) ? -1 : 1)
  );
  const languageItems = orderedLanguages.map(lang => {
    return (
      <InlineListItem
        key={`${lang.name}-${lang.sortOrder}`}
        href={`https://${lang.subdomain}.${domain}.${lang.tld}${location.pathname}?ut_source=footer`}
      >
        {lang.displayName}
      </InlineListItem>
    );
  });
  return (
    <InlineList>
      <InlineListItem isTitle>
        <T desc={COPY.LANGUAGES} />
      </InlineListItem>
      {languageItems}
    </InlineList>
  );
};

export default LanguageListLO;
