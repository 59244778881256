import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles(theme => {
  const { palette, radius } = theme;
  return {
    iconBackground: () => ({
      background: palette.warning.main,
      'border-radius': radius.large,
      height: '70px',
      width: '70px',
      display: 'flex',
      'justify-content': 'center',
      'align-items': 'center',
    }),
  };
});

export default useStyles;
