import { withWebAssetsTheme } from '../../theme';
import { withClientErrorPage } from '../ClientError';
import FourTenError, { FourTenErrorProps } from './FourTenError';

export default withWebAssetsTheme(
  withClientErrorPage<FourTenErrorProps>(FourTenError, {
    titularElementTag: 'h1', // set default to H1
    errorCode: 410,
  })
);
