import { createMemoStyles, Theme } from '@wds/styles';

const useStyles = createMemoStyles(({ palette, type }: Theme) => ({
  copyRight: {
    fontSize: type.fontSize.bodySm,
    color: palette.text.darkMuted,
  },
}));

export default useStyles;
