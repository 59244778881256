import React from 'react';
import { t, defineMessages } from '@sm/intl';
import { Typography } from '@wds/typography';
import { Box, BoxProps } from '@wds/box';
import { Button } from '@wds/button';
import useStyles from './useStyles';

const DEFAULT_COPY = defineMessages({
  SIGN_UP_TEXT: {
    id: 'SignupButton.signUpText',
    defaultMessage: 'Want to create your own survey?',
    description:
      '[Type: Label][Vis.: med] - Sign up free button description text',
  },
  SIGN_UP_FREE: {
    id: 'SignupButton.signUpFree',
    defaultMessage: 'Sign Up FREE',
    description: '[Type: Label][Vis.: med] - Sign up free button',
  },
});

const DEFAULT_HREF = '/user/sign-up/?ut_source=404_page';

type ButtonProps = {
  label?: string;
  href?: string;
};

export type SignupButtonProps = {
  buttonProps?: ButtonProps;
  signUpText?: string;
} & BoxProps;

const SignupButton = ({
  buttonProps,
  signUpText,
  ...containerBoxProps
}: SignupButtonProps): React.ReactElement => {
  const { container } = useStyles();
  const buttonLabel = buttonProps?.label ?? t(DEFAULT_COPY.SIGN_UP_FREE);
  const buttonHref = buttonProps?.href ?? DEFAULT_HREF;
  const description = signUpText ?? t(DEFAULT_COPY.SIGN_UP_TEXT);
  return (
    <Box {...containerBoxProps} className={container}>
      <Box mb={4}>
        <Typography component="div" variant="cardTitle" align="center">
          {description}
        </Typography>
      </Box>
      <Button
        href={buttonHref}
        color="upgrade"
        size="lg"
        data-testid="ClientError_SignUpButton"
      >
        {buttonLabel}
      </Button>
    </Box>
  );
};

export default SignupButton;
