import React from 'react';
import PropTypes from 'prop-types';

import OnScroll from './OnScroll';
import SMHeaderContext from './SMHeaderContext';
import { Addon } from './components/Addon';
import HeaderLogo from './components/HeaderLogo';
import { MobileDrawer } from './components/Drawer/Mobile';

import NavBarAnon from './anonymous/Desktop';
import MobileAnon from './anonymous/Mobile';
import { ValidMenuNameList } from './config/linkInfo';
import { HEADER_COLOR, HEADER_VARIANT } from './enums';

import useHeaderStyles from './useHeaderStyles';

const SMHeader = ({
  actionFlow,
  color,
  ctaRedirectUrl,
  excludeMenuItems,
  lang,
  Logo,
  isAnonHeaderSticky,
  isEUDC,
  isMRX,
  isUserAuthenticated,
  showLoginButton,
  showSignupButton,
  variant,
  user,
  joinTeamSlug,
  joinTeamAmplitudeProps,
}) => {
  const LogoComponent = Logo || HeaderLogo;
  const auth = isUserAuthenticated ? 'auth' : 'anon';
  const headerColor = isUserAuthenticated ? 'primary' : color;
  const headerVariant = isUserAuthenticated ? 'default' : variant;

  const { Header } = useHeaderStyles();

  return (
    <SMHeaderContext.Provider
      value={{
        isUserAuthenticated,
        lang,
        isEUDC,
        isMRX,
        actionFlow,
        user,
        excludeMenuItems,
      }}
    >
      <OnScroll
        render={(x, y) => {
          const headerColorAnon = y > 0 ? 'light' : color;
          const smHeaderColor = isUserAuthenticated
            ? headerColor
            : headerColorAnon;

          const defaultColor =
            headerColorAnon === 'transparent' || headerColorAnon === 'primary';

          const logoColor = defaultColor ? 'alt' : 'primary';
          const mobileMenuColor = defaultColor ? 'alt' : 'secondary';
          const addonLogInColor = defaultColor ? 'alt' : 'secondary';
          const navBarColor = defaultColor ? 'alt' : 'secondary';
          const addonSignUpColor = defaultColor ? 'alt' : 'upgrade';
          const addonSignUpVariant = defaultColor ? 'ghost' : 'solid';

          const classNames = `${Header} ${
            isAnonHeaderSticky && !isUserAuthenticated ? 'sticky' : ''
          } ${auth} ${smHeaderColor} ${headerVariant} ${
            lang === 'en' ? 'lang-en' : ''
          }`;

          return (
            <div className={classNames} data-testid="Header">
              <MobileDrawer
                menuColor={mobileMenuColor}
                data-testid="Header__MobileDrawer--Anon"
              >
                <MobileAnon joinTeamSlug={joinTeamSlug} />
              </MobileDrawer>

              <LogoComponent
                isUserAuthenticated={isUserAuthenticated}
                variant={variant}
                color={logoColor}
              />

              <NavBarAnon color={navBarColor} lang={lang} />
              <Addon
                addonSignUpColor={addonSignUpColor}
                addonLogInColor={addonLogInColor}
                addonSignUpVariant={addonSignUpVariant}
                ctaRedirectUrl={ctaRedirectUrl}
                isUserAuthenticated={isUserAuthenticated}
                showLoginButton={showLoginButton}
                showSignupButton={showSignupButton}
                variant={variant}
                joinTeamSlug={joinTeamSlug}
                joinTeamAmplitudeProps={joinTeamAmplitudeProps}
              />
            </div>
          );
        }}
      />
    </SMHeaderContext.Provider>
  );
};

SMHeader.propTypes = {
  actionFlow: PropTypes.string,
  color: PropTypes.oneOf(Object.values(HEADER_COLOR)),
  ctaRedirectUrl: PropTypes.string,
  excludeMenuItems: PropTypes.arrayOf(PropTypes.oneOf(ValidMenuNameList)),
  lang: PropTypes.string.isRequired,
  Logo: PropTypes.node,
  isAnonHeaderSticky: PropTypes.bool,
  isEUDC: PropTypes.bool.isRequired,
  isMRX: PropTypes.bool,
  isUserAuthenticated: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  showLoginButton: PropTypes.bool,
  showSignupButton: PropTypes.bool,
  user: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  variant: PropTypes.oneOf(Object.values(HEADER_VARIANT)),
  joinTeamSlug: PropTypes.string,
  joinTeamAmplitudeProps: PropTypes.shape({
    pageName: PropTypes.string,
    guestId: PropTypes.number,
    surveyId: PropTypes.number,
    surveyOwnerId: PropTypes.number,
  }),
};

SMHeader.defaultProps = {
  actionFlow: 'header',
  ctaRedirectUrl: '',
  color: 'primary',
  excludeMenuItems: [],
  Logo: undefined,
  isAnonHeaderSticky: true,
  isMRX: false,
  isUserAuthenticated: false,
  showLoginButton: true,
  showSignupButton: true,
  variant: 'default',
  joinTeamSlug: undefined,
  joinTeamAmplitudeProps: {},
};

export default SMHeader;
