import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';
import { IconArrowRight } from '@wds/icons';

export const SeeMoreLink = ({ title, url, openInNewWindow }) => {
  return (
    <List.Item
      key={title}
      isNavigable
      navigationTarget={url}
      {...(openInNewWindow === true && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
    >
      <Box my={4}>
        <Typography color="link" weight="medium">
          {title}
          <Box element="span" ml={1}>
            <IconArrowRight />
          </Box>
        </Typography>
      </Box>
    </List.Item>
  );
};

export default SeeMoreLink;

SeeMoreLink.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  openInNewWindow: PropTypes.bool,
};

SeeMoreLink.defaultProps = {
  openInNewWindow: false,
};
