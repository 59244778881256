// constants and logic for dealing with billing packages

export enum PackageType {
  BASIC = 1,
  PRO = 2,
  UNLIMITED = 3,
  SELECT_MONTHLY = 4,
  GOLD = 5,
  PLATINUM = 6,
  SELECT_YEARLY = 7,
  TEMP_PRO = 8,
  PRO_COMP = 9,
  ZOOM_PRO = 13,
  ZOOM_PREMIUM = 16,
  ENT_GOLD = 25,
  ENTERPRISE_PLATINUM = 26,
  ENTERPRISE = 27,
  ENTERPRISE_PRO = 28,
  ENTERPRISE_CASUAL_USER = 29,
  ENTERPRISE_ULTIMATE = 30,
  STANDARD_MONTHLY = 31,
  STANDARD_ANNUAL = 32,
  ADVANTAGE_MONTHLY = 33,
  ADVANTAGE_ANNUAL = 34,
  PREMIER_MONTHLY = 35,
  PREMIER_ANNUAL = 36,
  ANALYZE_PACKAGE = 37,
  TEAM_ADVANTAGE_ANNUAL = 134,
  TEAM_PREMIER_ANNUAL = 136,
  ENTERPRISE_CONTRIBUTOR_SEAT = 226,
  TEAM_ADVANTAGE_CONTRIBUTOR_SEAT = 234,
  TEAM_PREMIER_CONTRIBUTOR_SEAT = 236,
}

export const isAnnualPackage = (packageID: PackageType): boolean => {
  return [
    PackageType.ENTERPRISE_PLATINUM,
    PackageType.ENTERPRISE,
    PackageType.ENTERPRISE_PRO,
    PackageType.ENTERPRISE_ULTIMATE,
    PackageType.STANDARD_ANNUAL,
    PackageType.ADVANTAGE_ANNUAL,
    PackageType.PREMIER_ANNUAL,
    PackageType.TEAM_ADVANTAGE_ANNUAL,
    PackageType.TEAM_PREMIER_ANNUAL,
  ].includes(packageID);
};

export const isBasicPackage = (packageID: PackageType): boolean => {
  return packageID === PackageType.BASIC;
};

export const isEnterprisePackage = (packageID: PackageType): boolean => {
  return [
    PackageType.ENT_GOLD,
    PackageType.ENTERPRISE,
    PackageType.ENTERPRISE_CONTRIBUTOR_SEAT,
    PackageType.ENTERPRISE_PLATINUM,
    PackageType.ENTERPRISE_CASUAL_USER,
    PackageType.ENTERPRISE_PRO,
    PackageType.ENTERPRISE_ULTIMATE,
  ].includes(packageID);
};

export const isTeamPackage = (packageID: PackageType): boolean => {
  return (
    [
      PackageType.ENTERPRISE_PLATINUM,
      PackageType.ENTERPRISE_PRO,
      PackageType.ENTERPRISE_ULTIMATE,
      PackageType.ENTERPRISE,
      PackageType.TEAM_ADVANTAGE_ANNUAL,
      PackageType.TEAM_PREMIER_ANNUAL,
    ].indexOf(packageID) > -1
  );
};

export const isIndividualPackage = (packageID: PackageType): boolean => {
  return (
    [
      PackageType.STANDARD_MONTHLY,
      PackageType.STANDARD_ANNUAL,
      PackageType.ADVANTAGE_ANNUAL,
      PackageType.PREMIER_ANNUAL,
    ].includes(packageID)
  );
};
