import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

export const SolutionByNeed = ({ title, url, openInNewWindow }) => {
  return (
    <List.Item
      key={title}
      isNavigable
      navigationTarget={url}
      {...(openInNewWindow === true && {
        target: '_blank',
        rel: 'noopener noreferrer',
      })}
    >
      <Box my={4}>
        <Typography color="link">{title}</Typography>
      </Box>
    </List.Item>
  );
};

export default SolutionByNeed;

SolutionByNeed.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  openInNewWindow: PropTypes.bool,
};

SolutionByNeed.defaultProps = {
  openInNewWindow: false,
};
