import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useSubMenuStyles = createUseStyles(
  ({ breakpoints, spacing, palette, type }: Theme) => ({
    SubMenuContainer: {
      overflow: 'hidden',
      position: 'absolute',
      textAlign: 'left',
      // hides content above visual screen but allows google to crawl for SEO purposes
      top: -999,
      left: 0,
      right: 0,
      margin: [0, 'auto'],
      padding: [spacing[5], 0],
      background: `rgba(255, 255, 255, 0.97)`,
      boxShadow: `0 2px 8px 0 rgba(0,0,0,0.14)`,
      borderRadius: 4,

      '&.submenu-open': {
        top: 70,
        zIndex: 9999,
      },

      '&.products': {
        width: 921,
      },

      '&.resources': {
        padding: [spacing[5], spacing[6]],
        boxSizing: 'border-box',

        '&.lang-en': {
          width: 864,

          '&.sm-nav-submenu__bottom-container': {
            margin: [spacing[2], spacing[4], 0, spacing[4]],
          },
        },

        '&.lang-de': {
          padding: [spacing[5], 60],
          boxSizing: 'border-box',
        },

        '&.locale': {
          width: 736,
        },
      },

      '&.locale.submenu-open': {
        top: 64,
      },

      '&.solutions': {
        '&.locale': {
          width: 898,
        },
      },

      '&.products, &.resources': {
        '& .sm-nav-submenu__bottom-container': {
          borderTop: `1px solid ${palette.border.main}`,
        },
      },

      '&.products.lang-en': {
        padding: 0,
        background: palette.background.main,

        '& .sm-nav-submenu__col': {
          padding: [spacing[3], spacing[1], spacing[7]],
        },

        '& [class^="wrenchGridItem"]:first-of-type .sm-nav-submenu__col': {
          paddingLeft: spacing[6],
        },

        '& [class^="wrenchGridItem"]:last-of-type .sm-nav-submenu__col': {
          paddingRight: spacing[4],
        },

        '& [class^="wrenchGridItem"]:first-of-type': {
          background: palette.background.light,
        },

        '& .sm-nav-submenu__col [class^="wrenchListLabel"]': {
          padding: [0, spacing[4]],
        },

        '& .sm-nav-submenu__col:first-of-type [class^="wrenchListLabel"]': {
          padding: [0, spacing[4]],
        },

        '& [class^="wrenchGridItem"]:nth-child(3) .sm-nav-submenu__column-title':
          {
            padding: [0, 0, spacing[5]],
            border: 'none',
          },

        '& .sm-nav-submenu__col .sm-nav-submenu__list-item:nth-child(1)': {
          margin: 0,
        },

        '& .sm-nav-submenu__list-item': {
          margin: [0, 0, spacing[2]],
          borderBottom: 'none',
        },

        '& .sm-nav-submenu__column-title': {
          fontSize: spacing[3],
          lineHeight: '18px',
        },

        '& .sm-nav-submenu__bottom-container': {
          border: 'none',
          margin: 0,
          padding: 0,
          position: 'absolute',
          bottom: spacing[6],
          right: spacing[5],
        },

        '& .sm-nav-submenu__bottom-container .sm-nav-submenu__button a': {
          fontSize: 15,
          lineHeight: `${spacing[4]}px`,
          height: spacing[4],
        },
      },

      '&.solutions.lang-en': {
        width: 921,
        padding: 0,
        background: palette.background.main,

        '& .sm-nav-submenu__row': {
          margin: 0,
        },

        '& .sm-nav-submenu__by-need': {
          display: 'block',
          padding: 0,
          border: 0,
          fontSize: 15,
          lineHeight: `${spacing[5]}px`,
          height: spacing[5],
        },

        '& .sm-nav-submenu__by-need:hover, & .sm-nav-submenu__by-need:focus': {
          background: palette.background.light,
          textDecoration: 'underline',
        },

        '& .sm-nav-submenu__by-need-container': {
          padding: [spacing[4], 0],
          border: 'none',
        },

        '& .sm-nav-submenu__col': {
          padding: [spacing[3], spacing[1], spacing[7], spacing[1]],
        },

        '& [class^="wrenchGridItem"]:first-of-type .sm-nav-submenu__col': {
          paddingLeft: spacing[6],
        },

        '& [class^="wrenchGridItem"]:last-of-type .sm-nav-submenu__col': {
          paddingRight: spacing[4],
        },

        '& [class^="wrenchGridItem"]:first-of-type': {
          background: palette.background.light,
        },

        '& [class^="wrenchGridItem"]:first-of-type .sm-nav-submenu__column-title':
          {
            margin: [13, spacing[4], spacing[3], spacing[4]],
          },

        '& [class^="wrenchGridItem"]:nth-child(3) [class^="wrenchListLabel"]': {
          paddingRight: 0,
        },

        '& .sm-nav-submenu__col [class^="wrenchListLabel"]': {
          padding: [0, spacing[4]],
        },

        '& .sm-nav-submenu__col .sm-nav-submenu__list-item:nth-child(1)': {
          margin: 0,
        },

        '& .sm-nav-submenu__list-item': {
          margin: [0, 0, spacing[2]],
          borderBottom: 'none',
        },

        '& .sm-nav-submenu__column-title': {
          fontSize: spacing[3],
          lineHeight: '18px',
        },

        '& .sm-nav-submenu__bottom-container': {
          border: 'none',
          margin: 0,
          padding: 0,
          position: 'absolute',
          bottom: spacing[6],
          left: 34,
        },

        '& .sm-nav-submenu__bottom-container .sm-nav-submenu__button a': {
          fontSize: 15,
          lineHeight: `${spacing[4]}px`,
          height: spacing[4],
        },
      },

      '& .sm-nav-submenu__link-item': {
        display: 'block',
        marginTop: spacing[1],
      },

      '& .sm-nav-submenu__column-title': {
        borderBottom: `1px solid ${palette.border.muted}`,
      },

      '& .sm-nav-submenu__product-subtitle': {
        marginTop: spacing[1],
        color: palette.text.darkMuted,
        fontWeight: type.fontWeight.regular,

        '& .sm-nav-submenu--en &': {
          fontSize: 15,
          lineHeight: '20px',
        },
      },

      '& .sm-nav-submenu__by-need-title': {
        margin: [spacing[2], spacing[5], 0, spacing[5]],
        paddingTop: spacing[3],
        borderTop: `1px solid ${palette.border.muted}`,
      },

      '& .sm-nav-submenu__by-need-container': {
        border: 'none',
      },

      '& .sm-nav-submenu__by-need': {
        fontSize: spacing[4],
        fontWeight: type.fontWeight.regular,
      },

      '& .sm-nav-submenu__link': {
        '&:hover, &:focus': {
          textDecoration: 'none',
        },
      },

      '& .sm-nav-submenu__bottom-container': {
        display: 'block',
        margin: [spacing[2], spacing[6], 0, spacing[6]],
        paddingTop: spacing[4],

        '&::after': {
          content: '',
          display: 'block',
          clear: 'both',
        },

        '& .sm-nav-submenu__button a': {
          '&:hover, &:focus': {
            borderColor: 'transparent',
            backgroundColor: 'transparent',
          },
        },

        '& .sm-nav-submenu__button:hover a': {
          textDecoration: 'underline',
        },
      },
    },

    SubMenuTitle: {
      maxWidth: '90%',
      margin: [0, 'auto'],
      overflowWrap: 'break-word',
    },
  })
);

export default useSubMenuStyles;
