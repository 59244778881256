import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

export const Menu = ({ title, url }) => {
  return (
    <List.Item
      key={`${title}-non-nested`}
      interactive
      isNavigable
      navigationTarget={url}
    >
      <Box className="sm-mobile-menu-anon__anchor" my={4}>
        <Typography component="strong">{title}</Typography>
      </Box>
    </List.Item>
  );
};

Menu.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string,
};

Menu.defaultProps = {
  url: null,
};

export default Menu;
