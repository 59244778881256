import React, { useEffect } from 'react';
import {
  DefaultTheme,
  ThemeProvider as ReactJSSThemeProvider,
} from 'react-jss';
import { getClientEnvironmentDetails } from '@sm/utils';

type GlobalThemeProviderProps<Theme extends DefaultTheme = DefaultTheme> = {
  theme: Theme;
  children: React.ReactNode;
};

const GlobalThemeProvider = ({ theme, children }: GlobalThemeProviderProps) => {
  const { isBrowser } = getClientEnvironmentDetails();
  useEffect(() => {
    /**
     *  remove the JSS styles from the DOM after the client has loaded
     *  https://cssinjs.org/react-jss?v=v10.4.0#server-side-rendering
     */
    if (isBrowser) {
      const jss = document.getElementById('jss');
      if (jss) {
        jss.remove();
      }
    }
  }, [isBrowser]);

  return (
    <ReactJSSThemeProvider theme={theme}>{children}</ReactJSSThemeProvider>
  );
};

export default GlobalThemeProvider;
