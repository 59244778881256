import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

const useHeaderStyles = createUseStyles(
  ({ breakpoints, spacing, palette }: Theme) => ({
    Header: {
      margin: 0,

      '&.sticky': {
        position: 'fixed',
      },

      '&.primary': {
        backgroundColor: palette.primary.main,
      },

      '&.light': {
        backgroundColor: palette.primary.contrast,
      },

      '&.transparent': {
        backgroundColor: 'transparent',
      },

      '&.anon': {
        display: 'flex',
        height: 50,
        justifyContent: 'center',
        top: 0,
        left: 0,
        right: 0,
        zIndex: 300,

        [`@media (min-width: ${breakpoints.xl}px)`]: {
          height: 70,
          padding: [0, spacing[8]],

          '&.lang-en': {
            height: 56,
          },
        },

        '&.slim': {
          '& .submenu-open': {
            top: 50,
          },

          [`@media (min-width: ${breakpoints.xl}px)`]: {
            height: 50,
          },
        },

        '&.default': {
          '& .lang-en.submenu-open': {
            top: 56,
          },
        },
      },

      '&.auth': {
        display: 'flex',
        height: 50,
      },

      '& .sm-header__nav--auth': {
        order: 2,
        flexGrow: 1,

        '& .sm-header__nav-cta--alt.sm-header__text [class^="wrenchButton"]': {
          color: palette.primary.contrast,
          borderColor: 'transparent',
          backgroundColor: 'transparent',
          height: 50,
          lineHeight: 1,

          '&:focus, &:hover': {
            outline: 'transparent',
            boxShadow: 'none',
            borderBottom: `6px solid ${palette.primary.contrast}`,
          },
        },
      },

      '& .sm-header__nav--anon': {
        flexGrow: 1,
        margin: 'auto',
        textAlign: 'center',
        display: 'none',

        [`@media (min-width: ${breakpoints.xl}px)`]: {
          display: 'initial',
        },

        '& .sm-header__nav-cta--alt.sm-header__text [class^="wrenchButton"]': {
          color: palette.primary.contrast,
          borderColor: 'transparent',
          backgroundColor: 'transparent',

          '&:focus, &:hover': {
            outline: 'transparent',
            boxShadow: 'none',
          },
        },

        '& .sm-header__nav-cta--secondary.sm-header__text [class^="wrenchButton"]':
          {
            color: palette.text.dark,
            borderColor: 'transparent',
            backgroundColor: 'transparent',

            '&:focus, &:hover': {
              outline: 'transparent',
              boxShadow: 'none',
            },
          },

        '.sm-header__anon-nav-icon': {
          paddingLeft: spacing[1],
        },
      },

      '& .sm-header--open': {
        '&.sm-header__nav-icon-caret': {
          visibility: 'visible',
        },
      },

      '& .sm-header__nav-icon-caret': {
        position: 'absolute',
        right: '50%',
        fontSize: 26,
        background: 'transparent',
        color: palette.primary.contrast,
        zIndex: 1,
        visibility: 'hidden',
      },

      // @todo: This is hacky and needs better BEM practice
      /* stylelint-disable */
      '&.green': {
        '& .sm-mobile-menu__cta.sm-mobile-menu--secondary': {
          color: palette.text.light,

          '&:hover, &:focus': {
            background: palette.background.main,
          },
        },
      },
      /* stylelint-enable */
    },
  })
);

export default useHeaderStyles;
