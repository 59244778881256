import { createMemoStyles } from '@wds/styles';

const useStyles = createMemoStyles({
  trustedSeal: {
    border: 0,
    height: 50,
    width: 120,
  },
});

export default useStyles;
