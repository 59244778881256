import React from 'react';
import PropTypes from 'prop-types';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

export const ColumnTitle = ({ title }) => {
  return (
    <div className="sm-nav-submenu__list-item">
      <List.Item key={title} transparent>
        <div className="sm-nav-submenu__column-title">
          <Typography variant="caption" color="dark">
            {title}
          </Typography>
        </div>
      </List.Item>
    </div>
  );
};

export default ColumnTitle;

ColumnTitle.propTypes = {
  title: PropTypes.string.isRequired,
};
