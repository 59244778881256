import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';
import { PackageType } from '@sm/utils';

// This file helps us map a packageId to its conventional color & background color
// as shown on the billing pages.
// https://github.com/mntv-billing/BillWeb/blob/develop/billweb/static/scss/_packages.scss
// https://github.com/mntv-webplatform/smweb/blob/master/apps/contentweb/src/app/pages/Pricing/lib/package-colors.scss

type ColorScheme =
  | 'advantage'
  | 'analyze'
  | 'basic'
  | 'enterprise'
  | 'premier'
  | 'standard'
  | 'team_advantage'
  | 'team_premier';

const toColorScheme: Partial<Record<PackageType, ColorScheme>> = Object.freeze({
  [PackageType.ADVANTAGE_ANNUAL]: 'advantage',
  [PackageType.ADVANTAGE_MONTHLY]: 'advantage',
  [PackageType.ANALYZE_PACKAGE]: 'analyze',
  [PackageType.BASIC]: 'basic',
  [PackageType.ENTERPRISE_CASUAL_USER]: 'enterprise',
  [PackageType.ENTERPRISE_CONTRIBUTOR_SEAT]: 'enterprise',
  [PackageType.ENTERPRISE_PLATINUM]: 'enterprise',
  [PackageType.ENTERPRISE]: 'enterprise',
  [PackageType.PREMIER_ANNUAL]: 'premier',
  [PackageType.PREMIER_MONTHLY]: 'premier',
  [PackageType.STANDARD_ANNUAL]: 'standard',
  [PackageType.STANDARD_MONTHLY]: 'standard',
  [PackageType.TEAM_ADVANTAGE_ANNUAL]: 'team_advantage',
  [PackageType.TEAM_ADVANTAGE_CONTRIBUTOR_SEAT]: 'team_advantage',
  [PackageType.TEAM_PREMIER_ANNUAL]: 'team_premier',
  [PackageType.TEAM_PREMIER_CONTRIBUTOR_SEAT]: 'team_premier',
});

const wrenchBadgeSizeSm = 22;

// These hardcoded colors can be removed when billing pages adopt Wrench theming. At present
// they're using the SM brand colors, which don't map 1:1 to the Wrench palette
const SM_RASPBERRY = '#AC145A';
const SM_MIDNIGHT = '#05467E';
const SM_ARCTIC = '#2DCCD3';
const SM_CONCORD = '#671E75';

const useStyles = createUseStyles((theme: Theme) => {
  const { type, spacing, radius, palette } = theme;
  const { data, text, link } = palette;

  const badgeStyles = {
    display: 'inline-block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontSize: type.fontSize.bodySm,
    fontWeight: type.fontWeight.medium,
    padding: `0 ${spacing[2]}px`,
    maxWidth: '100%',
    height: `${wrenchBadgeSizeSm}px`,
    lineHeight: `${wrenchBadgeSizeSm}px`,
    borderRadius: radius.small,
  };

  const packageColors: Record<ColorScheme | 'default', [string, string]> = {
    advantage: [text.light, data.two], // sabaeus
    analyze: [text.light, SM_RASPBERRY], // raspberry
    basic: [text.dark, text.lightMuted], // flint
    enterprise: [text.light, SM_MIDNIGHT], // midnight
    premier: [text.light, SM_MIDNIGHT], // midnight
    standard: [text.dark, SM_ARCTIC], // arctic
    team_advantage: [link.contrast, link.main], // link
    team_premier: [text.light, SM_CONCORD], // concord

    // fallback
    default: [text.dark, text.lightMuted], // flint
  };

  const getPackageColors = (
    packageId: number
  ): { color: string; backgroundColor: string } => {
    const key = toColorScheme[packageId as PackageType] || 'default';
    const [color, backgroundColor] = packageColors[key];
    return { color, backgroundColor };
  };

  return {
    badge: ({ packageId }: { packageId: number }) => {
      return {
        ...badgeStyles,
        ...getPackageColors(packageId),
      };
    },
  };
});

export default useStyles;
