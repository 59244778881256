import React from 'react';
import { createTheming, createUseStyles, Styles, Theming } from 'react-jss';
import type { Classes } from 'jss';
import type { SurveyTheme } from './types';

/** Create a React context which JSS would need to wrap on */
const SurveyThemeContext = React.createContext({} as SurveyTheme);
SurveyThemeContext.displayName = 'SurveyTheme';

/** Use JSS custom hook to create the wrapper */
const theming = createTheming<SurveyTheme>(SurveyThemeContext);

/** Expose the associated hooks with aliases */
export const {
  useTheme: useSurveyTheme,
  ThemeProvider: SurveyThemeProvider,
} = theming;

/** Recreation of `react-jss`'s `CreateUseStylesOptions`, which is dynamic and does not get exported */
type CreateUseStylesOptions = Omit<
  Parameters<typeof createUseStyles>[1],
  'theme'
> & {
  theming?: Theming<SurveyTheme>; // set theme manually as we can't access it from `react-jss` and via inference
};

/**
 * For convenience, expose a custom hook which would carry the theming context wrapper along,
 * as well as injecting style sheet name-space / class name prefix as "sm" by default.
 *
 * Note: One may still utilize the stock `createUseStyles()` hook to construct styles regardless
 * relying on a survey theme or not; however if they do, please keep in mind that the recommended
 * way per JSS documentation is to pass in `theming` context wrapper every time.
 * ( https://cssinjs.org/react-jss/?v=v10.8.1#using-custom-theming-context )
 *
 */
export const createSurveyStyles = <C extends string = string, Props = unknown>(
  styles:
    | Styles<C, Props, undefined>
    | ((theme: SurveyTheme) => Styles<C, Props, undefined>),
  opts: CreateUseStylesOptions = {}
): ((data?: Props & { theme?: SurveyTheme }) => Classes<C>) =>
  createUseStyles<C, Props, SurveyTheme>(styles, {
    theming,
    name: 'sm',
    ...opts,
  });

/**
 * The context wrapper constructed by JSS `createTheming()` hook.
 * Note: `theming.context` points to the same SurveyThemeContext we just instantiated
 */
export default theming;
