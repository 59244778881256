import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { createURL } from '@sm/utils';
import { T, defineMessages } from '@sm/intl';
import { generateMetricsAttribute } from '@sm/metrics';
import { useLocation } from 'react-router-dom';
import LogInCTA from '../LogInCTA';
import SignUpCTA from '../SignUpCTA';
import SMHeaderContext from '../../SMHeaderContext';

import JoinTeamCTA from '../JoinTeamCTA';

import './addon.scss';

export const COPY = defineMessages({
  UPGRADE: {
    id: 'Addon.upgradeButton',
    defaultMessage: 'UPGRADE',
    description:
      '[Type: Label][Vis.: high] - This is the upgrade addon button on the header.',
  },
  CREATE_SURVEY: {
    id: 'Addon.createSurvey',
    defaultMessage: 'CREATE SURVEY',
    description:
      '[Type: Label][Vis.: high] - This is the create survey addon button on the header.',
  },
  CREATE_TEAM: {
    id: 'Addon.createTeam',
    defaultMessage: 'CREATE TEAM',
    description:
      '[Type: Label][Vis.: high] - This is the create team addon button on the header.',
  },
  ADD_USERS: {
    id: 'Addon.addUsers',
    defaultMessage: 'ADD USERS',
    description:
      '[Type: Label][Vis.: high] - This is the add users addon button on the header.',
  },
});

export const UpgradeButton = () => {
  const smHeaderContext = useContext(SMHeaderContext);
  return (
    <Button
      color="upgrade"
      size="sm"
      data-sm-metrics={generateMetricsAttribute({
        data: {
          actionType: 'webplat_header_upgrade.button_press',
          actionFlow: smHeaderContext.actionFlow,
        },
      })}
      href={createURL('/pricing/upgrade/', {
        ut_source: 'header_upgrade',
        ut_source3: 'webplat',
      })}
    >
      <T desc={COPY.UPGRADE} />
    </Button>
  );
};

export const CreateButton = () => {
  return (
    <Box ml={3}>
      <Button
        data-testid="Addon__CreateSurveyBtn"
        color="alt"
        size="sm"
        href={createURL('/create/', { ut_source: 'header' })}
      >
        <T desc={COPY.CREATE_SURVEY} />
      </Button>
    </Box>
  );
};

export const CreateTeam = () => {
  return (
    <Button
      data-testid="Addon__CreateTeamBtn"
      color="upgrade"
      size="sm"
      href={createURL('/billing/p2gupgrade/', {
        ut_source: 'header',
        ut_source3: 'webplat',
      })}
    >
      <T desc={COPY.CREATE_TEAM} />
    </Button>
  );
};

export const AddUsers = () => {
  return (
    <Button
      data-testid="Addon__AddUsersBtn"
      color="upgrade"
      size="sm"
      href={createURL('/team/add/', {
        ut_source: 'header',
        ut_source3: 'webplat',
      })}
    >
      <T desc={COPY.ADD_USERS} />
    </Button>
  );
};

// GROW-3835 Required query params for experimenting
// on sign-up page. This legacy header will be deprecated
// soon so this is just a temporary solution for experiment.
const getPricingPageParams = pathname => {
  const isPricingPage = pathname.includes('/pricing');
  const isPricingDetailsPage = pathname.includes(
    '/pricing/teams/details' || '/pricing/details'
  );

  if (isPricingPage && !isPricingDetailsPage) {
    return { pp_source: 'pricing-summary-megamenu' };
  }
  if (isPricingDetailsPage) {
    return { pp_source: 'pricing-details-megamenu' };
  }
  return {};
};

export const Addon = ({
  addonLogInColor,
  addonSignUpColor,
  addonSignUpVariant,
  ctaRedirectUrl,
  isUserAuthenticated,
  showLoginButton,
  showSignupButton,
  variant,
  joinTeamSlug,
  joinTeamAmplitudeProps,
}) => {
  const auth = isUserAuthenticated ? 'auth' : 'anon';
  let pathname;
  // GROW-3668 escape hatch to avoid next.js routing incompatibility
  try {
    const location = useLocation();
    pathname = location?.pathname;
  } catch (err) {
    pathname = '/';
  }

  const pricingPageParams = useMemo(
    () => getPricingPageParams(pathname),
    [pathname]
  );

  return (
    <div
      data-testid="Addon__Container"
      className={`sm-addon__${auth}--${variant}`}
    >
      {showLoginButton && (
        <LogInCTA
          variant="text"
          color={addonLogInColor}
          params={{
            ut_source: 'megamenu',
            ...(ctaRedirectUrl && { ep: ctaRedirectUrl }),
          }}
          className={`sm-addon__log-in sm-addon__log-in--${addonLogInColor}`}
        />
      )}
      {showSignupButton && (
        <SignUpCTA
          variant={addonSignUpVariant}
          color={addonSignUpColor}
          params={{
            ut_source: 'megamenu',
            ...pricingPageParams,
            ...(ctaRedirectUrl && { ep: ctaRedirectUrl }),
          }}
          className={`sm-addon__sign-up sm-addon__sign-up--${addonSignUpColor}`}
        />
      )}
      {!!joinTeamSlug?.length && (
        <div className="sm-addon__join-team-wrapper">
          <JoinTeamCTA
            joinTeamSlug={joinTeamSlug}
            className="sm-addon__join-team"
            amplitudeProps={joinTeamAmplitudeProps}
          />
        </div>
      )}
    </div>
  );
};

Addon.propTypes = {
  addonLogInColor: PropTypes.oneOf(['secondary', 'alt']),
  addonSignUpColor: PropTypes.oneOf(['upgrade', 'alt']),
  addonSignUpVariant: PropTypes.oneOf(['solid', 'ghost']),
  ctaRedirectUrl: PropTypes.string,
  isUserAuthenticated: PropTypes.bool,
  showLoginButton: PropTypes.bool,
  showSignupButton: PropTypes.bool,
  variant: PropTypes.oneOf(['default', 'limited', 'slim']),
  joinTeamSlug: PropTypes.string,
  joinTeamAmplitudeProps: PropTypes.shape({
    pageName: PropTypes.string,
    guestId: PropTypes.number,
    surveyId: PropTypes.number,
    surveyOwnerId: PropTypes.number,
  }),
};

Addon.defaultProps = {
  addonLogInColor: 'alt',
  addonSignUpColor: 'alt',
  addonSignUpVariant: 'solid',
  ctaRedirectUrl: '',
  isUserAuthenticated: false,
  showLoginButton: true,
  showSignupButton: true,
  variant: 'default',
  joinTeamSlug: undefined,
  joinTeamAmplitudeProps: {},
};
