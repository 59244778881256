import { sanitize } from 'dompurify';
import createDOMPurify from 'dompurify';
import { getClientEnvironmentDetails } from '../environment';

let stringSanitize = sanitize;

if (getClientEnvironmentDetails().isNode) {
  const { JSDOM } = require('jsdom'); // eslint-disable-line

  const { window } = new JSDOM('');
  const DOMPurify = createDOMPurify(window);

  stringSanitize = DOMPurify.sanitize;
}

export default stringSanitize;
