import React from 'react';
import { LogoWithText } from '../Logos';
import { withWebAssetsTheme } from '../../theme';
import useStyles from './useErrorPageStyles';

export type HttpErrorPageProps = {
  children?: React.ReactNode;
  /** errorCode passed from the error component, e.g. 404,400 to be displayed with the requestId */
  errorCode?: number;
  /** requestId passed on implementation of each error component, required for error troubleshooting. */
  requestId?: string;
};

export const HttpErrorPage = ({
  children,
  errorCode,
  requestId,
}: HttpErrorPageProps): React.ReactElement => {
  const { pageContainer, childrenContainer, pageLogoLink, hashMessageId } =
    useStyles();
  const logoLinkId = 'HttpErrorPage_Logo_Link';
  const hashMessage = requestId ? `${errorCode}: ${requestId}` : '';
  const hashMessageTestId = 'HttpErrorPage_Hash_Message';

  return (
    <div className={pageContainer}>
      <a href="/" data-testid={logoLinkId} className={pageLogoLink}>
        <LogoWithText />
      </a>
      <div className={childrenContainer}>{children}</div>
      <div data-testid={hashMessageTestId} className={hashMessageId}>
        {hashMessage}
      </div>
    </div>
  );
};

type PageHash = { requestId?: string };
type PageCode = { errorCode?: number };
type HocProps<T> = Omit<T, 'children'> & PageCode;

export const withHttpErrorPage =
  <T extends object>(
    Component: React.ComponentType<T>,
    { errorCode, ...defaultComponentProps }: HocProps<T>
  ) =>
  ({ requestId, ...props }: T & PageHash) => {
    const componentProps = {
      ...defaultComponentProps,
      ...props,
    } as T;
    return (
      <HttpErrorPage errorCode={errorCode} requestId={requestId}>
        <Component {...componentProps} fullscreen={false} />
      </HttpErrorPage>
    );
  };

export default withWebAssetsTheme(HttpErrorPage);
