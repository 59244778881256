import {
  getSiteSupportedLanguages,
  getTldLanguageInfo,
  getDataCenterIdFromCurrentUrl,
  getDataCenterById,
  getSubdomainFromLanguageCodeAndDataCenter,
  getDefaultSubdomainForDataCenter,
} from '@sm/locale';

interface FooterLanguageList {
  name: string;
  subdomain: string;
  tld: string;
  displayName: string;
  sortOrder: number;
}

const getFooterLanguageList = (
  url: string,
  tld: string
): Partial<FooterLanguageList>[] => {
  const languages = getSiteSupportedLanguages();
  const languageInfo = getTldLanguageInfo(tld);

  if (!url) {
    return [];
  }

  return languages.map(language => {
    const dataCenterId = getDataCenterIdFromCurrentUrl(url);

    const dataCenter = getDataCenterById(dataCenterId);
    let subdomain = getSubdomainFromLanguageCodeAndDataCenter(
      language.code,
      dataCenterId
    );
    let langTld = 'com';

    const primaryCCTldExistsForLanguage = language.tld !== 'com';
    const notOnCCTldCurrently = ['com', 'net'].includes(tld);
    const languageSupportedByCurrentCCTld = languageInfo?.supportedLanguages.includes(
      language.code
    );

    if (
      primaryCCTldExistsForLanguage &&
      dataCenter.CCTldSupport &&
      (notOnCCTldCurrently || !languageSupportedByCurrentCCTld)
    ) {
      subdomain = getDefaultSubdomainForDataCenter(dataCenterId);
      langTld = language.tld ?? '';
    } else if (languageSupportedByCurrentCCTld && dataCenter.CCTldSupport) {
      langTld = tld;
    }

    return {
      name: language.name,
      subdomain,
      tld: langTld,
      displayName: language.displayName,
      sortOrder: language.displayOrder,
    };
  });
};

export default getFooterLanguageList;
