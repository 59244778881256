import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { List } from '@wds/list';
import { Typography } from '@wds/typography';

import SMHeaderContext from '../../../SMHeaderContext';

export const SubMenuItem = ({
  subTitle,
  title,
  url,
  openInNewWindow,
  name,
}) => {
  const { excludeMenuItems } = useContext(SMHeaderContext);

  if (excludeMenuItems.includes(name)) return null;

  return (
    <div className="sm-nav-submenu__list-item">
      <List.Item
        key={title}
        isNavigable
        transparent
        interactive
        navigationTarget={url}
        {...(openInNewWindow === true && {
          target: '_blank',
          rel: 'noopener noreferrer',
        })}
      >
        <Box py={4} mt={2}>
          <Typography variant="body" weight="medium" color="link">
            {title}
          </Typography>
          <p className="sm-nav-submenu__product-subtitle">{subTitle}</p>
        </Box>
      </List.Item>
    </div>
  );
};

export default SubMenuItem;

SubMenuItem.propTypes = {
  subTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  openInNewWindow: PropTypes.bool,
  name: PropTypes.string.isRequired,
};

SubMenuItem.defaultProps = {
  openInNewWindow: false,
};
