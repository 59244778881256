import { withWebAssetsTheme } from '../../theme';
import { withClientErrorPage } from '../ClientError';

import ForbiddenError, { ForbiddenErrorProps } from '.';

export default withWebAssetsTheme(
  withClientErrorPage<ForbiddenErrorProps>(ForbiddenError, {
    titularElementTag: 'h1', // set default to H1
    errorCode: 403,
  })
);
