import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@wds/grid';
import { T } from '@sm/intl';
import { Typography } from '@wds/typography';
import NavSubmenuCopy from '../../../copy/NavSubmenuCopy';

export const ByNeedSubMenu = ({ children }) => {
  return (
    <Grid>
      <Grid.Item sm={12}>
        <div className="sm-nav-submenu__by-need-title">
          <Typography variant="body" color="dark" weight="medium">
            <T desc={NavSubmenuCopy.BY_NEED} />
          </Typography>
        </div>
      </Grid.Item>
      {children}
    </Grid>
  );
};

export default ByNeedSubMenu;

ByNeedSubMenu.propTypes = {
  children: PropTypes.node.isRequired,
};
