import React from 'react';
import PropTypes from 'prop-types';
import { Logo, LogoWithText } from '../../../Logos';

import useStyles from './useHeaderLogoStyles';

const HeaderLogo = ({ variant, color, isUserAuthenticated }) => {
  const auth = isUserAuthenticated ? 'auth' : 'anon';

  const { LogoLink, LogoAuthIcon, LogoAnonIcon, LogoText } = useStyles();

  return (
    <a
      href="/"
      alt="SurveyMonkey Logo"
      className={`${LogoLink} ${auth}--${variant}`}
      data-testid={`Logo-Link_${auth}--${variant}`}
    >
      {/* eslint-disable-next-line jsx-control-statements/jsx-use-if-tag */}
      {!isUserAuthenticated ? (
        <>
          <div
            className={LogoText}
            data-testid={`Logo-Text_${auth}--${variant}`}
          >
            <LogoWithText color={color} />
          </div>
          <Logo
            color={color}
            className={LogoAnonIcon}
            data-testid={`Logo-Anon-Icon_${auth}--${variant}`}
          />
        </>
      ) : (
        <Logo
          className={LogoAuthIcon}
          color="alt"
          data-testid={`Logo-Auth-Icon_${auth}--${variant}`}
        />
      )}
    </a>
  );
};

HeaderLogo.propTypes = {
  isUserAuthenticated: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'alt']),
  variant: PropTypes.oneOf(['default', 'limited', 'slim']),
};

HeaderLogo.defaultProps = {
  isUserAuthenticated: false,
  color: 'primary',
  variant: 'default',
};

export default HeaderLogo;
