import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  logoAlign: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default useStyles;
