import { createContext } from 'react';

type HeaderContext = {
  actionFlow?: string;
};

const SMHeaderContext = createContext<HeaderContext>({});

export { HeaderContext };
export default SMHeaderContext;
