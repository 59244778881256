/*
SM has the language data recorded in a number of places. There is DB table sm_Localization_D_Languages present in multiple schemas.
Here are some examples of the sources that support createweb languages. There are many more references to the top 17 languages supported SM-wide.
https://github.com/mntv-python-guild/smlib.locale/blob/master/smlib/locale/locale.yml#L330
https://github.com/mntv-authoring-experience/createweb/blob/develop/createweb/lib/translations.py
https://github.com/mntv-survey-platform/smsdk.surveysvc/blob/main/smsdk/surveysvc/lib/languages.yml
https://github.com/mntv-analysis/anweb/blob/develop/anweb/lib/languages.py
*/

/**
 * @constant
 * @default
 * @memberof module:@sm/locale
 */

export enum SupportLevels {
  SITE_WIDE = 1,
  SURVEY_CREATION = 2,
  MULTILINGUAL_SURVEYS = 3,
}

/**
 * The locale codes has been broken down into three lists so that we can
 * expose all the locales for a SupportLevel as an argument to a function (or React Component prop)
 */
export const SITE_WIDE_LOCALE_CODES = [
  'zh-tw',
  'pt-br',
  'da',
  'nl',
  'en',
  'fi',
  'fr',
  'de',
  'it',
  'ja',
  'ko',
  'no',
  'ru',
  'es',
  'sv',
  'tr',
] as const;

export const SURVEY_CREATION_LOCALE_CODES = [
  'sq',
  'ar',
  'hy',
  'eu',
  'bn',
  'bs',
  'bg',
  'ca',
  'zh-cn',
  'pt',
  'hr',
  'cs',
  'et',
  'tl',
  'ka',
  'el',
  'he',
  'hi',
  'hu',
  'is',
  'id',
  'ga',
  'ku',
  'lv',
  'lt',
  'mk',
  'ms',
  'ml',
  'fa',
  'pl',
  'pa',
  'ro',
  'sr',
  'sk',
  'sl',
  'sw',
  'ta',
  'te',
  'th',
  'uk',
  'vi',
  'cy',
] as const;

export const MULTILINGUAL_SURVEYS_LOCALE_CODES = [] as const;

export type SiteWideLocaleCode = typeof SITE_WIDE_LOCALE_CODES[number];
export type SurveyCreationLocaleCode =
  typeof SURVEY_CREATION_LOCALE_CODES[number];
export type MultilingualSurveysLocaleCode =
  typeof MULTILINGUAL_SURVEYS_LOCALE_CODES[number];

export type LanguageCode =
  | SiteWideLocaleCode
  | SurveyCreationLocaleCode
  | MultilingualSurveysLocaleCode;

// The LanguageDefinition now precisely says what locale code is permitted
// based on the SupportLevel.
export type LanguageDefinition = {
  /** Language codes as used in the database
   *
   * _Does not follow IETF language tag standard_
   */
  name: string;
  id: number;
  countries: readonly string[];
  /** Is Right-to-Left language */
  isRTL: boolean;
  /** `code` as IETF language tag standard https://en.wikipedia.org/wiki/IETF_language_tag */
  codeIETF?: string;
  displayName?: string;
  subdomain?: string;
  /**
   * @deprecated Please use helpCenterLangCode instead
   */
  helplink?: string;
  helpCenterLangCode?: string;
  tld?: string;
  displayOrder?: number;
} & (
  | {
      code: SiteWideLocaleCode;
      supportLevel: SupportLevels.SITE_WIDE;
    }
  | {
      code: SurveyCreationLocaleCode;
      supportLevel: SupportLevels.SURVEY_CREATION;
    }
  | {
      code: MultilingualSurveysLocaleCode;
      supportLevel: SupportLevels.MULTILINGUAL_SURVEYS;
    }
);

/**
 * An array of available languages.
 *
 * The language codes in the database do not follow IETF language tag standard https://en.wikipedia.org/wiki/IETF_language_tag
 * or confuse regional locale with language dialect. That causes issues when working with internationalization libraries like ICU.
 * The field code is the "code" as in the SM database and python packages. The "codeIETF" is the correct code. This API accepts both.
 * For the output choose "code" unless you are sure that the consumer supports IETF codes (e.g, uses this library).
 * @type {Array}
 * @memberof module:@sm/locale
 */
export const LANGUAGES: readonly LanguageDefinition[] = [
  {
    code: 'sq',
    name: 'Albanian',
    id: 24,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ar',
    name: 'Arabic',
    id: 25,
    countries: [],
    isRTL: true,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'hy',
    name: 'Armenian',
    id: 26,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'eu',
    name: 'Basque',
    id: 27,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'bn',
    name: 'Bengali',
    id: 28,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'bs',
    name: 'Bosnian',
    id: 29,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'bg',
    name: 'Bulgarian',
    id: 30,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ca',
    name: 'Catalan',
    id: 31,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'zh-cn',
    codeIETF: 'zh-Hans',
    name: 'Chinese (Simplified)',
    id: 2,
    countries: [],
    isRTL: false,
    subdomain: 'www',
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'zh-tw',
    codeIETF: 'zh-Hant',
    name: 'Chinese (Traditional)',
    displayName: '中文(繁體)',
    id: 3,
    helplink: 'zh_TW',
    helpCenterLangCode: 'zh',
    tld: 'com',
    subdomain: 'zh',
    countries: ['TW'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 14,
  },
  {
    code: 'pt-br',
    name: 'Portuguese (Brazilian)',
    id: 17,
    displayName: 'Português',
    helplink: 'pt_BR',
    helpCenterLangCode: 'pt-br',
    tld: 'com',
    subdomain: 'pt',
    countries: ['BR', 'PT', 'AO', 'CV'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 2,
  },
  {
    code: 'pt',
    name: 'Portuguese (Iberian)',
    id: 16,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'hr',
    name: 'Croatian',
    id: 32,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'cs',
    name: 'Czech',
    id: 33,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'da',
    name: 'Danish',
    displayName: 'Dansk',
    tld: 'com',
    helplink: 'en_US',
    subdomain: 'da',
    id: 4,
    countries: ['DK', 'GL'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 10,
  },
  {
    code: 'nl',
    name: 'Dutch',
    id: 5,
    displayName: 'Nederlands',
    helplink: 'nl_NL',
    helpCenterLangCode: 'nl',
    subdomain: 'nl',
    tld: 'com',
    countries: ['NL', 'BE'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 4,
  },
  {
    code: 'en',
    name: 'English',
    id: 1,
    tld: 'com',
    subdomain: 'www',
    displayName: 'English',
    helplink: 'en_US',
    helpCenterLangCode: 'en',
    countries: ['US', 'AU', 'GB', 'IE', 'CA', 'GG', 'NZ'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 5,
  },
  {
    code: 'et',
    name: 'Estonian',
    id: 34,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'tl',
    codeIETF: 'fil',
    name: 'Filipino',
    id: 35,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'fi',
    name: 'Finnish',
    id: 6,
    displayName: 'Suomi',
    helplink: 'en_US',
    tld: 'com',
    subdomain: 'fi',
    countries: ['FI'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 17,
  },
  {
    code: 'fr',
    name: 'French',
    id: 7,
    displayName: 'Français',
    helplink: 'fr',
    helpCenterLangCode: 'fr',
    tld: 'com',
    subdomain: 'fr',
    countries: [
      'FR',
      'MA',
      'DZ',
      'TN',
      'RE',
      'CI',
      'SN',
      'TG',
      'MQ',
      'GP',
      'PF',
      'CG',
      'CM',
      'BJ',
      'CD',
    ],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 7,
  },
  {
    code: 'ka',
    name: 'Georgian',
    id: 36,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'de',
    name: 'German',
    id: 8,
    displayName: 'Deutsch',
    helplink: 'de',
    helpCenterLangCode: 'de',
    tld: 'com',
    subdomain: 'de',
    countries: ['DE', 'AT', 'CH', 'LI'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 3,
  },
  {
    code: 'el',
    name: 'Greek',
    id: 9,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'he',
    name: 'Hebrew',
    id: 37,
    countries: [],
    isRTL: true,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'hi',
    name: 'Hindi',
    id: 38,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'hu',
    name: 'Hungarian',
    id: 39,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'is',
    name: 'Icelandic',
    id: 40,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'id',
    name: 'Indonesian',
    id: 41,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ga',
    name: 'Irish',
    id: 42,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'it',
    name: 'Italian',
    id: 10,
    displayName: 'Italiano',
    helplink: 'it',
    helpCenterLangCode: 'it',
    tld: 'com',
    subdomain: 'it',
    countries: ['IT'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 9,
  },
  {
    code: 'ja',
    name: 'Japanese',
    id: 11,
    displayName: '日本語',
    helplink: 'ja',
    helpCenterLangCode: 'ja',
    tld: 'com',
    subdomain: 'jp',
    countries: ['JP'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 12,
  },
  {
    code: 'ko',
    name: 'Korean',
    id: 12,
    displayName: '한국어',
    helplink: 'ko',
    helpCenterLangCode: 'ko',
    tld: 'com',
    subdomain: 'ko',
    countries: ['KP', 'KR'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 13,
  },
  {
    code: 'ku',
    name: 'Kurdish',
    id: 43,
    countries: [],
    /** Kurdish can be written in two scripts - it may or may not be RTL */
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'lv',
    name: 'Latvian',
    id: 44,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'lt',
    name: 'Lithuanian',
    id: 45,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'mk',
    name: 'Macedonian',
    id: 46,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ms',
    name: 'Malay',
    id: 13,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ml',
    name: 'Malayalam',
    id: 47,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'no',
    codeIETF: 'nb',
    name: 'Norwegian',
    id: 14,
    displayName: 'Norsk',
    helplink: 'en_US',
    tld: 'com',
    subdomain: 'no',
    countries: ['NO', 'SJ'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 16,
  },
  {
    code: 'fa',
    name: 'Persian',
    id: 48,
    countries: [],
    isRTL: true,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'pl',
    name: 'Polish',
    id: 15,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'pa',
    name: 'Punjabi',
    id: 49,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ro',
    name: 'Romanian',
    id: 50,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'ru',
    name: 'Russian',
    id: 18,
    displayName: 'Русский',
    helplink: 'ru',
    helpCenterLangCode: 'ru',
    tld: 'com',
    subdomain: 'ru',
    countries: [
      'RU',
      'BY',
      'UA',
      'KZ',
      'UZ',
      'TJ',
      'AZ',
      'TM',
      'AM',
      'MD',
      'KG',
    ],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 8,
  },
  {
    code: 'sr',
    name: 'Serbian',
    id: 51,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'sk',
    name: 'Slovak',
    id: 52,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'sl',
    name: 'Slovenian',
    id: 53,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'es',
    name: 'Spanish',
    id: 19,
    displayName: 'Español',
    helplink: 'es',
    helpCenterLangCode: 'es-la',
    tld: 'com',
    subdomain: 'es',
    countries: [
      'MX',
      'ES',
      'AR',
      'GT',
      'HN',
      'SV',
      'NI',
      'CR',
      'PA',
      'GQ',
      'CU',
      'DO',
      'PR',
      'VE',
      'CO',
      'EC',
      'PE',
      'BO',
      'PY',
      'CL',
      'UY',
    ],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 1,
  },
  {
    code: 'sw',
    name: 'Swahili',
    id: 54,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'sv',
    name: 'Swedish',
    id: 20,
    displayName: 'Svenska',
    helplink: 'en_US',
    tld: 'com',
    subdomain: 'sv',
    countries: ['SE'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 11,
  },
  {
    code: 'ta',
    name: 'Tamil',
    id: 55,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'te',
    name: 'Telugu',
    id: 56,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'th',
    name: 'Thai',
    id: 57,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'tr',
    name: 'Turkish',
    id: 21,
    displayName: 'Türkçe',
    helplink: 'tr',
    helpCenterLangCode: 'tr',
    tld: 'com',
    subdomain: 'tr',
    countries: ['TR'],
    isRTL: false,
    supportLevel: SupportLevels.SITE_WIDE,
    displayOrder: 15,
  },
  {
    code: 'uk',
    name: 'Ukrainian',
    id: 22,
    countries: ['UA'],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'vi',
    name: 'Vietnamese',
    id: 58,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
  {
    code: 'cy',
    name: 'Welsh',
    id: 59,
    countries: [],
    isRTL: false,
    supportLevel: SupportLevels.SURVEY_CREATION,
  },
] as const;

/**
 * Languages by ID
 * @memberof module:@sm/locale
 */
const LANGUAGES_BY_ID_REC: Record<string, LanguageDefinition> = {};
/**
 * Languages by code
 * @memberof module:@sm/locale
 */
const LANGUAGES_BY_CODE_REC: Record<string, LanguageDefinition> = {};
/**
 * Languages with sub-domains
 * @memberof module:@sm/locale
 */
const LANGUAGES_WITH_SUBDOMAINS_REC: LanguageDefinition[] = [];

LANGUAGES.forEach(language => {
  LANGUAGES_BY_ID_REC[language.id] = language;
  LANGUAGES_BY_CODE_REC[language.code] = language;
  if (language.codeIETF) {
    LANGUAGES_BY_CODE_REC[language.codeIETF] = language;
  }
  if (language.subdomain) {
    LANGUAGES_WITH_SUBDOMAINS_REC.push(language);
  }
});

export const LANGUAGES_BY_ID = Object.freeze(LANGUAGES_BY_ID_REC);
export const LANGUAGES_BY_CODE = Object.freeze(LANGUAGES_BY_CODE_REC);
export const LANGUAGES_WITH_SUBDOMAINS = Object.freeze(
  LANGUAGES_WITH_SUBDOMAINS_REC
);
