import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@wds/box';
import { Button } from '@wds/button';
import { createURL } from '@sm/utils';
import { T, defineMessages } from '@sm/intl';

export const COPY = defineMessages({
  LOG_IN: {
    id: 'LogInCTA.logInButton',
    defaultMessage: 'Log in',
    description:
      '[Type: Label][Vis.: high] - This is the LogIn button on the header.',
  },
});

export const LogInCTA = ({
  variant,
  size,
  color,
  className,
  stretched,
  params,
}) => {
  return (
    <Box display={stretched ? 'flex' : 'inline-flex'} className={className}>
      <Button
        data-testid="LogInCTA__Btn"
        variant={variant}
        size={size}
        color={color}
        stretched={stretched}
        href={createURL('/login', params)}
      >
        <T desc={COPY.LOG_IN} />
      </Button>
    </Box>
  );
};

LogInCTA.propTypes = {
  variant: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.string,
  stretched: PropTypes.bool,
  className: PropTypes.string,
  params: PropTypes.oneOfType([PropTypes.object]),
};

LogInCTA.defaultProps = {
  variant: 'text',
  size: 'sm',
  color: 'secondary',
  stretched: false,
  className: '',
  params: null,
};

export default LogInCTA;
