import React from 'react';
import { Box } from '@wds/box';
import { t, defineMessages } from '@sm/intl';
import { withWebAssetsTheme } from '../../theme';
import { ClientError, ClientErrorProps } from '../ClientError';
import SignupButton, { SignupButtonProps } from '../ClientError/SignupButton';
import Links, { LinksProps } from '../ClientError/Links';

export type FourHundredErrorProps = Partial<ClientErrorProps> & {
  errorMessage?: React.ReactNode;
  signupButtonProps?: SignupButtonProps;
  linksProps?: LinksProps;
  showLinks?: boolean;
};

const COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'FourHundredError.sorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  CANT_FIND_PAGE: {
    id: 'FourHundredError.cantFindPage',
    defaultMessage: "We can't find the page you're looking for.",
    description: '[Type: Label][Vis.: med] - Cant find page message',
  },
  CHECK_URL: {
    id: 'FourHundredError.checkURL',
    defaultMessage:
      "Please check the URL you entered to make sure it's spelled correctly.",
    description: '[Type: Label][Vis.: med] - check URL subtitle',
  },
  HELP_FIND_WAY: {
    id: 'FourHundredError.helpFindWay',
    defaultMessage:
      'Still not sure how to get to the page you want? Maybe we can help you find your way:',
    description: '[Type: Label][Vis.: med] - Help find your way subtitle',
  },
});

const FourHundredError = ({
  openingMessage = t(COPY.SORRY_MESSAGE),
  errorTitle = t(COPY.CANT_FIND_PAGE),
  titularElementTag = 'p',
  errorMessage = [t(COPY.CHECK_URL), t(COPY.HELP_FIND_WAY)],
  signupButtonProps,
  linksProps,
  showLinks = false,
  'data-testid': dataTestId = 'FourHundredError',
  ...props
}: FourHundredErrorProps): React.ReactElement => {
  return (
    <ClientError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      data-testid={dataTestId}
      {...props}
    >
      <Box mt={4}>
        {React.Children.map(errorMessage, (message: React.ReactNode) => (
          <p data-testid={`${dataTestId}_ErrorMessage`}>{message}</p>
        ))}
      </Box>
      <SignupButton {...signupButtonProps} mt={5} />
      {showLinks && <Links {...linksProps} mt={6} />}
    </ClientError>
  );
};

export default withWebAssetsTheme(FourHundredError);
