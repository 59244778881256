import { defineMessages } from '@sm/intl';

export default defineMessages({
  PRODUCTS: {
    id: 'NavBarCopy.products',
    defaultMessage: 'Products',
    description: '[Type: Label][Vis.: low] - Products section of nav bar',
  },
  SOLUTIONS: {
    id: 'NavBarCopy.solutions',
    defaultMessage: 'Solutions',
    description: '[Type: Label][Vis.: low] - Solutions section of nav bar',
  },
  RESOURCES: {
    id: 'NavBarCopy.resources',
    defaultMessage: 'Resources',
    description: '[Type: Label][Vis.: low] - Resources section of nav bar',
  },
  HELP_CENTER: {
    id: 'NavBarCopy.helpCenter',
    defaultMessage: 'Help Center',
    description: '[Type: Label][Vis.: low] - Help Center section of nav bar',
  },
  PLANS_AND_PRICING: {
    id: 'NavBarCopy.plansAndPricing',
    defaultMessage: 'Plans & Pricing',
    description:
      '[Type: Label][Vis.: low] - Plans & Pricing section of nav bar',
  },
  DASHBOARD: {
    id: 'NavBarCopy.dashboard',
    defaultMessage: 'Dashboard',
    description: '[Type: Label][Vis.: low] - Dashboards section of nav bar',
  },
  MY_SURVEYS: {
    id: 'NavBarCopy.mySurveys',
    defaultMessage: 'My Surveys',
    description: '[Type: Label][Vis.: low] - My surveys section of nav bar',
  },
  MY_TEAM: {
    id: 'NavBarCopy.myTeam',
    defaultMessage: 'My Team',
    description: '[Type: Label][Vis.: low] - My team section of nav bar',
  },
});
