import React, { ReactNode, useContext, useRef } from 'react';
import { PageMetricsProvider, MetricsTracker } from '@sm/metrics';

import BasePageHead from './BasePageHead';
import { StaticContext } from '../..';
import BasePageBody, { BasePageBodyProps } from './BasePageBody';
import useGTM from './hooks/useGTM';

export type BasePageProps = BasePageBodyProps & {
  legacyWeb: string;
  pageId: string;
  includeGTM?: boolean;
  pageMetricsAttributes?: object;
  children: ReactNode;
};

const BasePage = ({
  children,
  pageId,
  pageMetricsAttributes,
  legacyWeb,
  includeGTM = true,
  ...bodyProps
}: BasePageProps) => {
  const {
    user,
    environment,
    'client-config': clientConfig,
    GDPR,
  } = useContext(StaticContext);
  const metricsInitialized = useRef(false);
  const { countryCode: country } = environment;
  const {
    gtmId,
    loggingAPIPath,
    dataAnalyticsAPIPath,
    amplitudeToken,
    amplitudeForceAnonymous = false,
  } = clientConfig;

  // init global metrics (should only happen once)
  if (user && !metricsInitialized.current) {
    metricsInitialized.current = true;

    MetricsTracker.initialize({
      user,
      dataAnalyticsAPIPath,
      loggingAPIPath,
      country,
      legacyWeb,
      pageId,
      gtmId,
      amplitudeToken,
      forceAnonymous: amplitudeForceAnonymous,
    });
  }
  useGTM(includeGTM);

  const BasePageInternals = (
    <PageMetricsProvider
      pageId={pageId}
      attributes={pageMetricsAttributes}
      GDPR={GDPR}
      user={user}
      country={country}
    >
      <BasePageHead />
      <BasePageBody {...bodyProps}>{children}</BasePageBody>
    </PageMetricsProvider>
  );

  return BasePageInternals;
};

export default BasePage;
