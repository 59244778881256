import React from 'react';
import { t, defineMessages } from '@sm/intl';
import { withWebAssetsTheme } from '../../theme';
import { ClientError, ClientErrorProps } from '../ClientError';
import Links, { LinksProps } from '../ClientError/Links';
import SignupButton, { SignupButtonProps } from '../ClientError/SignupButton';

const DEFAULT_COPY = defineMessages({
  // Client Error
  OH_BANANAS: {
    id: 'FourTenError.ohBananas',
    defaultMessage: 'Oh bananas!',
    description: '[Type: Label][Vis.: med] - Oh bananas message',
  },
  TERMS_VIOLATION: {
    id: 'FourTenError.somethingWentWrong',
    defaultMessage:
      "This survey is currently closed due to a violation of SurveyMonkey's terms of use.",
    description: '[Type: Label][Vis.: med] - terms of use violation title',
  },
  // Primary Links
  HOME: {
    id: 'FourTenError.home',
    defaultMessage: 'SurveyMonkey Home',
    description: '[Type: Label][Vis.: med] - Home link in 410 Error Message',
  },
  SITEMAP: {
    id: 'FourTenError.sitemap',
    defaultMessage: 'Sitemap',
    description: '[Type: Label][Vis.: med] - Sitemap link in 410 Error Message',
  },
  LEARN_MORE: {
    id: 'FourTenError.howItWorks',
    defaultMessage: 'How It Works',
    description:
      '[Type: Label][Vis.: med] - How It Works link in 410 Error Message',
  },
  // Secondary Links
  TYPES: {
    id: 'FourTenError.types',
    defaultMessage: 'Survey Types',
    description:
      '[Type: Label][Vis.: med] - Survey Types link in 410 Error Message',
  },
  SATISFACTION_SURVEYS: {
    id: 'FourTenError.satisfactionSurveys',
    defaultMessage: 'Customer Satisfaction Surveys',
    description:
      '[Type: Label][Vis.: med] - Customer Satisfaction Surveys link in 410 Error Message',
  },
  EMPLOYEE_SURVEYS: {
    id: 'FourTenError.employeeSurveys',
    defaultMessage: 'Employee Surveys',
    description:
      '[Type: Label][Vis.: med] - Employee Surveys link in 410 Error Message',
  },
});

const defaultPrimaryLinks = {
  links: [
    {
      label: t(DEFAULT_COPY.HOME),
      href: '/',
    },
    {
      label: t(DEFAULT_COPY.SITEMAP),
      href: '/mp/sitemap/',
    },
    {
      label: t(DEFAULT_COPY.LEARN_MORE),
      href: '/mp/take-a-tour/',
    },
  ],
};

const defaultSecondaryLinks = {
  links: [
    {
      label: t(DEFAULT_COPY.TYPES),
      href: '/mp/survey-types/',
    },
    {
      label: t(DEFAULT_COPY.SATISFACTION_SURVEYS),
      href: '/mp/customer-satisfaction-surveys/',
    },
    {
      label: t(DEFAULT_COPY.EMPLOYEE_SURVEYS),
      href: '/mp/employee-surveys/',
    },
  ],
};

export type FourTenErrorProps = Partial<ClientErrorProps> & {
  /** ClientError SignupButton component props  */
  signupButtonProps?: SignupButtonProps;
  /** ClientError Links component props */
  primaryLinksProps?: LinksProps;
  secondaryLinksProps?: LinksProps;
};

const FourTenError = ({
  openingMessage = t(DEFAULT_COPY.OH_BANANAS),
  errorTitle = t(DEFAULT_COPY.TERMS_VIOLATION),
  titularElementTag = 'p',
  signupButtonProps,
  primaryLinksProps = defaultPrimaryLinks,
  secondaryLinksProps = defaultSecondaryLinks,
  'data-testid': dataTestId = 'FourTenError',
  ...props
}: FourTenErrorProps): React.ReactElement => {
  return (
    <ClientError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      data-testid={dataTestId}
      {...props}
    >
      <SignupButton {...signupButtonProps} mt={5} />
      <Links {...primaryLinksProps} mt={6} />
      <Links {...secondaryLinksProps} mt={6} />
    </ClientError>
  );
};

export default withWebAssetsTheme(FourTenError);
