import React, { useContext } from 'react';
import { t } from '@sm/intl';
import NavSubmenuCopy from '../../copy/NavSubmenuCopy';
import Menu from './components/Menu.js';
import SubMenu from './components/SubMenu';
import SubMenuBody from './components/SubMenuBody.js';
import BottomLinkWrapper from './components/BottomLinkWrapper.js';
import SubMenuItem from './components/SubMenuItem.js';
import Column from './components/Column.js';
import BottomLink from './components/BottomLink.js';

import SMHeaderContext from '../../SMHeaderContext';

import {
  exploreSurveyTemplates,
  resources,
  blog,
  helpCenter,
  resourcesMenu,
} from '../../config/linkInfo';

export const ResourcesMenu = () => {
  const { lang } = useContext(SMHeaderContext);

  const key = 'Resources';
  const subMenuTitle = t(NavSubmenuCopy.RESOURCES_TITLE);

  return (
    <Menu menuKey={key} {...resourcesMenu()}>
      <SubMenu submenuKey={key} title={subMenuTitle}>
        <SubMenuBody>
          {lang === 'en' ? (
            <>
              <Column>
                <SubMenuItem {...resources()} />
              </Column>
              <Column>
                <SubMenuItem {...blog()} />
              </Column>
              <Column>
                <SubMenuItem {...helpCenter(lang)} />
              </Column>
            </>
          ) : (
            <>
              <Column>
                <SubMenuItem {...blog()} />
              </Column>
              <Column>
                <SubMenuItem {...helpCenter(lang)} />
              </Column>
            </>
          )}
        </SubMenuBody>

        {lang === 'en' ? (
          <BottomLinkWrapper>
            <BottomLink {...exploreSurveyTemplates()} />
          </BottomLinkWrapper>
        ) : null}
      </SubMenu>
    </Menu>
  );
};

export default ResourcesMenu;
