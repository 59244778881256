import React from 'react';
import useStyles from './useStyles';

const CopyRight = () => {
  const { copyRight } = useStyles();

  const year = new Date().getFullYear();

  return (
    <span className={copyRight}>{`Copyright © 1999-${year} SurveyMonkey`}</span>
  );
};

export default CopyRight;
