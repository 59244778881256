import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  container: {
    '& a:hover, & a:focus': {
      textDecoration: 'none',
    },
  },
});

export default useStyles;
