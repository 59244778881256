import React from 'react';
import { createURL } from '@sm/utils';
import { MetricsTracker, USER_EVENTS } from '@sm/metrics';
import Menu from './components/Menu.js';
import { plansPricingMenu } from '../../config/linkInfo';

export const PlansAndPricingMenu = () => {
  const path = createURL('/pricing/', {
    ut_source: 'megamenu',
  });

  const key = 'Plans & Pricing';

  const handleMenuClick = () => {
    MetricsTracker.track({
      name: USER_EVENTS.ELEMENT_INTERACTION,
      data: {
        amplitudeEvent: 'review session landing page clicked pricingcta',
        interactionType: 'click',
      },
    });
  };

  return (
    <Menu
      {...plansPricingMenu()}
      path={path}
      menuKey={key}
      onClick={handleMenuClick}
    >
      <div />
    </Menu>
  );
};

export default PlansAndPricingMenu;
