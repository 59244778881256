import React, { useContext } from 'react';
import { Grid } from '@wds/grid';
import { Box } from '@wds/box';
import classnames from 'classnames';
import { LanguageMenuLI } from './LanguageMenu';
import { AuthedFooterLinks } from './FooterLinks';
import TrusteSeals from './TrusteSeals';
import BillingAddress from './BillingAddress';
import { SMFooterContext } from './SMFooterContext';

import useStyles from './useStyles';

const AuthedFooter = () => {
  const {
    domain = '',
    lang = '',
    country,
    variant,
    showBillingAddress,
  } = useContext(SMFooterContext);

  const { footerAuth } = useStyles();
  const footerClassNames = classnames(footerAuth, 'sm-footer');

  return (
    <div className={footerClassNames} data-testid="AuthedFooter">
      <Grid>
        <Grid.Item>
          <Box
            display="flex"
            flexAlign={variant === 'center' ? 'center' : 'flex-start'}
            flexDirection="column"
          >
            <LanguageMenuLI lang={lang} />
          </Box>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item>
          <Box
            display="flex"
            flexAlign={variant === 'center' ? 'center' : 'flex-start'}
            flexDirection="column"
            my={5}
          >
            <AuthedFooterLinks domain={domain} lang={lang} />
            {showBillingAddress && <BillingAddress country={country} />}
          </Box>
        </Grid.Item>
      </Grid>
      <Grid>
        <Grid.Item>
          <Box
            display="flex"
            flexAlign={variant === 'center' ? 'center' : 'flex-start'}
            flexDirection="column"
          >
            <TrusteSeals />
          </Box>
        </Grid.Item>
      </Grid>
    </div>
  );
};

export default AuthedFooter;
