import React, { ReactElement } from 'react';
import { Box } from '@wds/box';
import { t, defineMessages } from '@sm/intl';
import { withWebAssetsTheme } from '../../theme';
import { ClientError, ClientErrorProps } from '../ClientError';
import Links, { LinksProps } from '../ClientError/Links';

// put this into common error component strings file
const DEFAULT_COPY = defineMessages({
  SORRY_MESSAGE: {
    id: 'ForbiddenError.sorryMessage',
    defaultMessage: "We're sorry",
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  PERMISSIONS: {
    id: 'ForbiddenError.permissions',
    defaultMessage: 'You do not have permission to see this page.',
    description: '[Type: Label][Vis.: med] - We are sorry message',
  },
  CHECK_URL: {
    id: 'ForbiddenError.checkURL',
    defaultMessage:
      "Please check the URL you entered to make sure it's spelled correctly.",
    description: '[Type: Label][Vis.: med] - check URL subtitle',
  },
  HELP_FIND_WAY: {
    id: 'ForbiddenError.helpFindWay',
    defaultMessage:
      'Still not sure how to get to the page you want? Maybe we can help you find your way:',
    description: '[Type: Label][Vis.: med] - Help find your way subtitle',
  },
});

export type ForbiddenErrorProps = Partial<ClientErrorProps> & {
  /** Error content message */
  errorMessage?: React.ReactNode;
  /** ClientError Links component props */
  linksProps?: LinksProps;
  showLinks?: boolean;
};

const ForbiddenError = ({
  openingMessage = t(DEFAULT_COPY.SORRY_MESSAGE),
  errorTitle = t(DEFAULT_COPY.PERMISSIONS),
  titularElementTag = 'p',
  errorMessage = [t(DEFAULT_COPY.CHECK_URL), t(DEFAULT_COPY.HELP_FIND_WAY)],
  linksProps,
  showLinks = true,
  'data-testid': dataTestId = 'ForbiddenError',
  ...props
}: ForbiddenErrorProps): ReactElement => {
  return (
    <ClientError
      openingMessage={openingMessage}
      errorTitle={errorTitle}
      titularElementTag={titularElementTag}
      data-testid={dataTestId}
      {...props}
    >
      <Box mt={4}>
        {React.Children.map(errorMessage, (message: React.ReactNode) => {
          return <p data-testid={`${dataTestId}_ErrorMessage`}>{message}</p>;
        })}
      </Box>
      {showLinks && <Links {...linksProps} mt={6} />}
    </ClientError>
  );
};

export default withWebAssetsTheme(ForbiddenError);
