import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@wds/grid';
import { List } from '@wds/list';

export const Column = ({ children }) => {
  return (
    <Grid.Item>
      <div className="sm-nav-submenu__col">
        <List interactive transparent noRules>
          {children}
        </List>
      </div>
    </Grid.Item>
  );
};

export default Column;

Column.propTypes = {
  children: PropTypes.node.isRequired,
};
