import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import SMHeaderContext from '../../../SMHeaderContext';

export const BottomLinkWrapper = ({ children }) => {
  const { lang } = useContext(SMHeaderContext);
  return (
    <div className={`sm-nav-submenu__bottom-container sm-nav-submenu--${lang}`}>
      {children}
    </div>
  );
};

BottomLinkWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BottomLinkWrapper;
