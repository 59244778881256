import React, { useContext } from 'react';
import { Grid } from '@wds/grid';
import { Box } from '@wds/box';
import classnames from 'classnames';
import { LanguageListLO } from './LanguageMenu';
import { AnonFooterLinks } from './FooterLinks';
import TrusteSeals from './TrusteSeals';
import CopyRight from './CopyRight';
import { SMFooterContext } from './SMFooterContext';

import useStyles from './useStyles';

const AnonFooter = () => {
  const { variant } = useContext(SMFooterContext);

  const { footerAnon, fullHeight, footerBorder } = useStyles();

  const TrusteFooterSeals = () => {
    return (
      <Grid.Item md={5}>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <TrusteSeals />
        </Box>
      </Grid.Item>
    );
  };

  const footerClassNames = classnames(footerAnon, 'sm-footer');

  return (
    <div className={footerClassNames} data-testid="AnonFooter">
      {variant === 'default' ? (
        <>
          <Grid>
            <Grid.Item>
              <div className={footerBorder}>
                <AnonFooterLinks />
              </div>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item>
              <Box className={footerBorder} py={4}>
                <LanguageListLO />
              </Box>
            </Grid.Item>
          </Grid>
          <Grid>
            <Grid.Item md={7}>
              <Box display="flex" alignItems="center" className={fullHeight}>
                <CopyRight />
              </Box>
            </Grid.Item>
            {TrusteFooterSeals()}
          </Grid>
        </>
      ) : (
        <Grid>
          <Grid.Item md={7}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              className={fullHeight}
            >
              <AnonFooterLinks />
              <CopyRight />
            </Box>
          </Grid.Item>
          {TrusteFooterSeals()}
        </Grid>
      )}
    </div>
  );
};

export default AnonFooter;
