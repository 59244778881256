import { Theme } from '@wds/styles';
import { createUseStyles } from 'react-jss';

export type StyleProps = {
  height?: number; // desired height of the card in px (not including borders)
  selected: boolean;
  loading?: boolean;
};

const DEFAULT_HEIGHT = 80;

const useStyles = createUseStyles(
  ({ spacing, shadow, palette, type }: Theme) => ({
    accountCard: {
      fontFamily: type.fontFamily.base,
      fontSize: type.fontSize.body,
      '-webkit-font-smoothing': 'antialiased',
      backgroundColor: palette.background.light,
      border: `2px solid ${palette.border.muted}`,
      borderRadius: '4px',
      boxShadow: shadow.none,

      position: 'relative',

      // hover styles for interactive card
      '&:hover, &:focus': {
        borderColor: palette.border.hover,
        cursor: 'pointer',
      },
    },

    // These styles get added conditionally based on component props
    accountCardDynamic: ({ selected }: StyleProps) => ({
      ...(selected && {
        borderColor: palette.border.active,
        '&:hover, &focus': {
          borderColor: palette.border.active,
        },
      }),
    }),

    accountCardBody: ({ loading, height = DEFAULT_HEIGHT }: StyleProps) => ({
      padding: `0 ${spacing[4]}px`,
      minHeight: `${height}px`,

      display: 'flex',
      alignItems: 'center',
      ...(loading ? { justifyContent: 'center' } : {}),
    }),

    accountType: {
      position: 'absolute',
      top: 0,
    },

    spinner: {
      minHeight: '100%',
    },
  })
);

export default useStyles;
