/**
 * errorHandler is a singleton which allows users to log errors and get a list of subscribers.
 * It initializes the subscribers if they haven't already been initialized and does so only
 * once per instance.
 */
export const errorHandler = (function handlerFactory() {
  return {
    getSubscribers() {
      return errorHandler.errorSubscribers || {};
    },
  };
})();

export function initializeErrorHandler() {
  if (typeof errorHandler.errorSubscribers !== 'undefined') {
    return;
  }
  errorHandler.errorSubscribers = {};
  if (typeof window === 'undefined') {
    return;
  }
  if (
    typeof errorHandler.errorSubscribers.splunkRum === 'undefined' &&
    typeof window.SplunkRum !== 'undefined'
  ) {
    errorHandler.errorSubscribers.splunkRum = window.SplunkRum;
  }
  errorHandler.errorSubscribers.user = (err, msg, metaData) => {
    // eslint-disable-next-line no-console
    console.error(err.toString(), msg, metaData);
  };
}

function tellSplunkRum(err, msg, metaData = {}) {
  try {
    const client = errorHandler.getSubscribers().splunkRum;
    if (client) {
      // This function works like console.error, so we don't really pass in
      // objects to it. If we want to capture that info, we need to pass it in
      // as a string; thus the JSON.stringify so we don't lose granularity.
      client.error(err, msg, JSON.stringify(metaData));
    }
  } catch (doNothingWithError) {
    // eslint-disable-next-line no-console
    console.log(doNothingWithError);
  }
}

function tellUser(err, msg, metaData) {
  const client = errorHandler.getSubscribers().user;
  if (client) {
    client(err, msg, metaData);
  }
}

/**
 *
 * @param {Error} err - Error object
 * @param {string} msg - {optional extra message}
 * @param {Object} metaData - [optional metadata object]
 */
errorHandler.logError = function logError(err, ...rest) {
  if (!err) return;
  let [message, metaData] = rest;
  if (typeof rest[0] === 'object' && rest.length === 1) {
    message = null;
    [metaData] = rest;
  }

  tellSplunkRum(err, message, metaData);
  tellUser(err, message, metaData);
};
